import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { StarOutlineRounded, StarRounded } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApiAddValeterDTO } from '../../api/models/ApiPreferredValeters';
import { addPreferredValeter, deletePreferredValeter } from '../../api/preferredValeters';
import { ApiErrorLoaderWrapper } from '../../components/ApiLoaderErrorWrapper';
import { AppButton } from '../../components/AppButton';
import { AppCard } from '../../components/AppCard';
import { AppInput } from '../../components/AppForm';
import CreateMbhBookingModal from '../../components/CreateMbhBookingModal';
import { BookingStatus } from '../../config/Statuses';
import { paths, useBookingId } from '../../Routes';
import { actions, selectors } from '../../store';
import { successToast } from '../../toast';
import BookingCarsCard from './BookingCarsCard';
import BookingNotesCard from './BookingNotesCard';
import BookingOverviewCardContent from './BookingOverviewCardContent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minMax(0, 1fr))',
        gridGap: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1600px',
    },
    wideCard: {
        gridColumn: '1 / span 2',
    },
    normalCard: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '1 / span 1',
        },
    },
    valeterCard: {
        height: 'fit-content',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '2 / span 1',
        },
    },
    customerFields: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        '& > *': {
            flexGrow: 1,
        },
        gridGap: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            '& > *': {
                flexGrow: 1,
            },
            gridGap: theme.spacing(2),
        },
    },
    button: {
        alignItems: 'flex-start',
        width: 'fit-content',
    },
    emailButton: {
        marginLeft: 'auto',
        marginTop: '12px',
    },
    PVStar: {
        display: 'flex'
    },
    isPV: {
        color: '#FFCC00'
    },
    notPV: {
        color: theme.palette.grey[500]
    }
}));



const BookingDetailsPage: React.FC = () => {
    const dispatch = useDispatch();
    const [createMbhBookingModalIsOpen, setCreateMbhBookingModalIsOpen] = useState(false);
    const history = useHistory();
    const classNames = useStyles();
    const bookingId = useBookingId();
    const customerSelect = useSelector(selectors.customers.selectGetByIdState);

    const fetchBooking = useCallback(() => {
        dispatch(actions.booking.getById(bookingId));
    }, [bookingId, dispatch]);

    const {
        isLoading,
        httpError,
        booking
    } = useSelector(selectors.booking.selectApiState);

    useEffect(fetchBooking, [fetchBooking]);

    useEffect(() => {
        if (booking) {
            dispatch(actions.customers.getById(booking?.customer.id));
        }
    }, [dispatch, booking])

    const handleCloseCreateMbhBookingModal = (success: boolean) => {
        setCreateMbhBookingModalIsOpen(false);
        if (success) fetchBooking();
    }


    const mbhAppointmentTypeIds = booking ? booking.mbhAppointmentTypeIds : [];
    const nonBookableStatuses: BookingStatus[] = ['Cancelled', 'Confirmed', 'Complete'];
    const allowCreatingMbhBooking = booking && !nonBookableStatuses.includes(booking.status);
    const preferredValeter = customerSelect.customer?.preferredValeters.find(valeter => valeter.resourceId === booking?.resourceId);

    const linkToCustomer = (customerId: number) => {
        history.push(paths.customerView(customerId))
    }

    const addOrDeletePreferredValeter = async () => {
        if (booking) {
            if (preferredValeter) {
                const response = await deletePreferredValeter(preferredValeter.customerPreferredValeterId)
                if (!response.isError) {
                    successToast("Valeter was removed from your favourites");
                    dispatch(actions.customers.getById(booking?.customer.id))
                }
            }
            else {
                const dto: ApiAddValeterDTO = { resourceId: booking.resourceId, resourceName: booking.valeterName }
                const response = await addPreferredValeter(booking.customer.id, dto);
                if (!response.isError) {
                    successToast('Valeter Added to favourites.');
                    dispatch(actions.customers.getById(booking?.customer.id))
                }
            }

        }
    }

    const onEmailClick = (email: string) => {
        const mailto_link = 'mailto:' + email;
        window.open(mailto_link, 'emailWindow');
    }

    return (
        <>
            <div className={classNames.root}>
                <AppCard
                    title="Booking Overview"
                    className={classNames.wideCard}
                    buttons={allowCreatingMbhBooking ? (
                        <AppButton
                            size="small"
                            variant="outlined"
                            onClick={() => setCreateMbhBookingModalIsOpen(true)}
                            children="Find Valeter"
                        />
                    ) : (
                        undefined
                    )}>
                    <ApiErrorLoaderWrapper
                        isLoading={isLoading}
                        httpError={httpError}
                    >
                        {booking && (
                            <>
                                <BookingOverviewCardContent
                                    booking={booking}
                                    forceFetchBooking={fetchBooking}
                                />
                                <CreateMbhBookingModal
                                    bookingId={bookingId}
                                    isOpen={createMbhBookingModalIsOpen}
                                    onClose={handleCloseCreateMbhBookingModal}
                                    initialDate={booking.requestedDate ? new Date(booking.requestedDate) : new Date()}
                                    mbhAppointmentTypeIds={mbhAppointmentTypeIds}
                                />
                            </>
                        )}
                    </ApiErrorLoaderWrapper>
                </AppCard>
                <BookingNotesCard
                    cardClassName={classNames.wideCard}
                />
                <BookingCarsCard
                    cardClassName={classNames.wideCard}
                />
                <AppCard
                    title="Customer"
                    className={classNames.normalCard}
                    buttons={!booking ? undefined : (
                        <Button
                            className={classNames.button}
                            onClick={() => linkToCustomer(booking.customer.id)}
                            children="View Full Details"
                            variant="outlined"
                            size="small"
                            color="primary"
                        />
                    )}
                >
                    <ApiErrorLoaderWrapper
                        isLoading={isLoading}
                        httpError={httpError}
                    >
                        {booking && (
                            <>
                                <div className={classNames.customerFields}>
                                    <AppInput readonly label="First Name" type="text" value={booking.customer.firstName} />
                                    <AppInput readonly label="Last Name" type="text" value={booking.customer.lastName} />
                                    <AppInput readonly label="Phone" type="text" value={booking.customer.contactNumber} />
                                    <AppInput readonly label="Email" type="text" value={booking.customer.email} />
                                </div>
                                <div className={classNames.emailButton}>
                                    <AppButton
                                        size="small"
                                        variant="outlined"
                                        type="button"
                                        onClick={() => onEmailClick(booking.customer.email)}
                                        children="Email Customer"
                                    />
                                </div>
                            </>
                        )}
                    </ApiErrorLoaderWrapper>
                </AppCard>
                <AppCard title="Valeter" className={clsx(classNames.normalCard, classNames.valeterCard)}>
                    <ApiErrorLoaderWrapper
                        isLoading={isLoading}
                        httpError={httpError}
                    >
                        {booking && (
                            <div className={classNames.PVStar}>
                                <AppInput readonly label="Name" type="text" value={booking.valeterName || ''} />
                                {booking.valeterName &&
                                    <Button
                                        disabled={customerSelect.isLoading}
                                        onClick={addOrDeletePreferredValeter}
                                        className={preferredValeter?.resourceId ? classNames.isPV : classNames.notPV}
                                        style={{ backgroundColor: 'transparent' }}
                                    >
                                        <StarRounded fontSize="large" />
                                    </Button>
                                }
                            </div>
                        )}
                    </ApiErrorLoaderWrapper>
                </AppCard>
            </div>
        </>
    )
}

export default BookingDetailsPage;