import { Form, Formik, FormikProps } from 'formik';
import debounce from 'lodash.debounce';
import { Ref } from 'react';
import * as Yup from 'yup';
import { checkPostcode } from '../../api/postcodeCheck';
import CustomerAddressForm, { CustomerAddressFormValues } from './CustomerAddressForm';

export interface CustomerAddressFormContainerProps {
    className?: string,
    formikRef?: Ref<FormikProps<CustomerAddressFormValues>>,
    onSubmit: (values: CustomerAddressFormValues) => Promise<any>,
}

const validatePostcodeRegex = (postcode: string) => {
    const matches = postcode.match(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i);
    return !!matches && matches.length !== 0;
}

const validatePostcodeIsCovered = async (postcode: string|null|undefined, resolve: (result: boolean) => void) => {
    if (!postcode) {
        resolve(true);
        return;
    }
    // Validating Postcode Regex again so that if regex not valid we don't perform call to API.
    if (!validatePostcodeRegex(postcode)) {
        resolve(true);
        return;
    }

    const postcodeIsCovered = await checkPostcode(postcode, true);
    if(postcodeIsCovered.statusCode === 200) {
        resolve(true);
        return;
    }
    resolve(false);
    return;
}

const validatePostcodeCoveredDebounced = debounce(validatePostcodeIsCovered, 500);

export const customerAddressValidationFields = {
    addressLine1: Yup.string()
        .required('Required.'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    postcode: Yup.string()
        .required('Please enter your postcode.')
        .min(5, 'Missing part of your postcode')
        .max(8, 'Too many characters')
        .test(
            'validatePostcodeFormat',
            'Please check that you have entered a valid postcode',
            postcode => {
                if (!postcode) return true;
                // Postcode Regex taken from Fresh Norse site.
                return validatePostcodeRegex(postcode)
            }
        )
        .test('validatePostcodeCovered',
                'We dont have coverage in your area at the moment',
                async (postcode) => new Promise(resolve => validatePostcodeCoveredDebounced(postcode, resolve))),
    town: Yup.string()
        .required('Required.'),
}

const validationSchema: Yup.SchemaOf<CustomerAddressFormValues> = Yup.object().shape(
    customerAddressValidationFields
)

const initialValues: CustomerAddressFormValues = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postcode: '',
    town: '',
}

const CustomerAddressFormContainer: React.FC<CustomerAddressFormContainerProps> = ({
    className,
    formikRef,
    onSubmit,
}) => {
    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {formikProps => (
                <Form className={className}>
                    <CustomerAddressForm
                        {...formikProps}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default CustomerAddressFormContainer;