import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../config';
import { actions, selectors, useDispatchEffect } from '../../store';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import CustomersTable from './CustomersTable';

export interface CustomersTableContainerProps {
    onRowClick: (customerId: number) => void,
    onHeaderClick: (headerSort: string) => void
}

const CustomersTableContainer: React.FC<CustomersTableContainerProps> = ({
    onRowClick,
    onHeaderClick
}) => {
    const dispatch = useDispatch();

    const {
        isLoading,
        httpError,
        allEntities: customers,
    } = useSelector(selectors.customers.selectGetAllState);

    const pagination = useSelector(selectors.customers.selectPaginationState);

    const loadCustomers = useCallback(() => {
        return actions.customers.doSearch();
    }, []);

    useDispatchEffect(loadCustomers);
    
    return (
        <>
            <ApiErrorLoaderWrapper
                isLoading={isLoading}
                httpError={httpError}
            >
                <CustomersTable
                    customers={customers}
                    onRowClick={onRowClick}
                    onHeaderClick={onHeaderClick}
                    pagination={pagination}
                    setPageNumber={pageNumber => dispatch(actions.customers.setPageNumber(pageNumber))}
                    setPageSize={pageSize => dispatch(actions.customers.setPageSize(pageSize))}
                    pageSizeOptions={config.pageSizeOptions}
                />
            </ApiErrorLoaderWrapper>
        </>
    );
}

export default CustomersTableContainer;