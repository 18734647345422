import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { HttpClientFailureResponse } from '../api';
import { getAllCustomers, getCustomerById } from '../api/customers';
import { Customer } from '../models/Customer';
import { CustomerAddress } from '../models/CustomerAddress';
import { CustomerCar } from '../models/CustomerCar';
import { PreferredValeter } from '../models/PreferredValeter';
import { createHttpClientThunk } from './common/createHttpClientThunk';
import { applyApiPaginatedPayloadToState, initialPaginationState, PaginationState } from './common/paginationState';

export const sliceName = 'customers';

const getAllAsyncThunk = createHttpClientThunk(
    `${sliceName}/getAll`,
    getAllCustomers,
);

const getByIdAsyncThunk = createHttpClientThunk(
    `${sliceName}/getById`,
    getCustomerById,
);

const entityAdapter = createEntityAdapter<Customer>({
    selectId: c => c.id,
});

type SliceState = {
    entityState: EntityState<Customer>,
    customerById?: Customer,
    paginationState: PaginationState,
    isGettingById: boolean,
    getByIdHttpError?: HttpClientFailureResponse,
    isGettingAll: boolean,
    getAllHttpError?: HttpClientFailureResponse,
    searchTerm: string,
    sortBy: string
}

const initialState: SliceState = {
    entityState: entityAdapter.getInitialState(),
    paginationState: initialPaginationState,
    isGettingById: false,
    isGettingAll: false,
    searchTerm: '',
    sortBy: '',
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setSearchTerm: (state, { payload }: PayloadAction<string>) => {
            // reset page number otherwise we will could get invalid pagination if results have less pages than current search.
            state.paginationState.currentPage = 0;
            state.searchTerm = payload;
        },
        setSortBy: (state, { payload }: PayloadAction<string>) => {
            state.paginationState.currentPage = 0;
            state.sortBy = payload;
        },
        addAddressToLoadedCustomer: (state, { payload }: PayloadAction<CustomerAddress>) => {
            state.customerById?.addresses.push(payload);
        }, 
        addPreferredValeterToLoadedCustomer: (state, { payload }: PayloadAction<PreferredValeter>) => {
            state.customerById?.preferredValeters.push(payload);
        },
        addCarToLoadedCustomer: (state, { payload }: PayloadAction<CustomerCar>) => {
            state.customerById?.cars.push(payload);
        },
        deleteAddressFromLoadedCustomer: (state, { payload }: PayloadAction<number>) => {
            if (!state.customerById) return;

            state.customerById.addresses = state.customerById.addresses.filter(a => a.id !== payload);
        },
        deleteCarFromLoadedCustomer: (state, { payload }: PayloadAction<number>) => {
            if (!state.customerById) return;

            state.customerById.cars = state.customerById.cars.filter(c => c.id !== payload);
        },
        deletePreferredValeterFromLoadedCustomer: (state, { payload }: PayloadAction<number>) => {
            if (!state.customerById) return;

            state.customerById.preferredValeters = state.customerById.preferredValeters.filter(a => a.customerPreferredValeterId !== payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(getAllAsyncThunk.pending, state => {
            state.isGettingAll = true;
            state.getAllHttpError = undefined;
        });
        builder.addCase(getAllAsyncThunk.rejected, (state, { payload }) => {
            state.isGettingAll = false;
            state.getAllHttpError = payload;
        });
        builder.addCase(getAllAsyncThunk.fulfilled, (state, { payload }) => {
            state.isGettingAll = false;
            entityAdapter.removeAll(state.entityState);
            entityAdapter.setAll(state.entityState, payload.items);
            applyApiPaginatedPayloadToState(state.paginationState, payload);
        });

        builder.addCase(getByIdAsyncThunk.pending, state => {
            state.isGettingById = true;
            state.getByIdHttpError = undefined;
        });
        builder.addCase(getByIdAsyncThunk.rejected, (state, { payload }) => {
            state.isGettingById = false;
            state.getByIdHttpError = payload;
        });
        builder.addCase(getByIdAsyncThunk.fulfilled, (state, { payload }) => {
            state.isGettingById = false;
            state.customerById = payload;
        });
    }
});

export const { reducer } = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();


export const actions = {
    getById: (customerId: number) => getByIdAsyncThunk(customerId),
    addAddressToLoadedCustomer: (address: CustomerAddress) => slice.actions.addAddressToLoadedCustomer(address),
    addCarToLoadedCustomer: (car: CustomerCar) => slice.actions.addCarToLoadedCustomer(car),
    addPreferredValeterToLoadedCustomer: (valeter: PreferredValeter) => slice.actions.addPreferredValeterToLoadedCustomer(valeter),
    deleteAddressFromLoadedCustomer: (addressId: number) => slice.actions.deleteAddressFromLoadedCustomer(addressId),
    deleteCarFromLoadedCustomer: (carId: number) => slice.actions.deleteCarFromLoadedCustomer(carId),
    deletePreferredValeterFromLoadedCustomer: (customerPreferredValeterId: number) => slice.actions.deletePreferredValeterFromLoadedCustomer(customerPreferredValeterId),
    setPageNumber: (newPageNumber: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());

        dispatch(getAllAsyncThunk({
            pageNumber: newPageNumber,
            pageSize: state.paginationState.pageSize,
            searchTerm: state.searchTerm,
            sortBy: state.sortBy
        }));
    },
    setPageSize: (newPageSize: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const oldPageSize = state.paginationState.pageSize;
        const oldPageNumber = state.paginationState.currentPage;
        const newPageNumber = Math.floor(oldPageNumber * oldPageSize / newPageSize)

        dispatch(getAllAsyncThunk({
            pageNumber: newPageNumber,
            pageSize: newPageSize,
            searchTerm: state.searchTerm,
            sortBy: state.sortBy
        }));
    },
    setSearchTerm: (newSearchTerm: string) => slice.actions.setSearchTerm(newSearchTerm),
    doSearch: () => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const { currentPage, pageSize } = state.paginationState;
        const  sortBy  = state.sortBy;
        const { searchTerm } = state;


        dispatch(getAllAsyncThunk({
            pageNumber: currentPage,
            pageSize,
            searchTerm,
            sortBy,
        }));
    },
    setSortBy: (sortBy: string) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const { currentPage, pageSize } = state.paginationState;
        const { searchTerm } = state;

        dispatch(slice.actions.setSortBy(sortBy))
        dispatch(getAllAsyncThunk({
            pageNumber: currentPage,
            pageSize,
            searchTerm,
            sortBy,
        }));
    }
};

export const selectors = {
    selectGetAllState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isGettingAll,
            httpError: state.getAllHttpError,
            allEntities: entitySelectors.selectAll(state.entityState),
        })
    ),
    selectGetByIdState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isGettingById,
            httpError: state.getByIdHttpError,
            customer: state.customerById,
        })
    ),
    selectPaginationState: createSelector(
        selectSliceState,
        state => state.paginationState,
    ),
    selectSearchTerm: createSelector(
        selectSliceState,
        state => state.searchTerm,
    ),
    selectSortBy: createSelector(
        selectSliceState,
        state => state.sortBy,
    ),
}
