import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppIconButton from "../AppIconButtton";
import {AddCircle, RemoveCircle} from "@material-ui/icons";
import {colors} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "3rem",
        background: colors.grey["200"],
        border: "solid 0",
        borderBottomWidth: "0.1rem",
        borderColor: colors.grey["500"],
        padding: "0 0.5rem 0 1rem"
    },
    label: {
        fontSize: "1rem",
        marginTop: "5px",
        userSelect: "none"
    },
    controlsContainer: {
        display: "flex",
        alignItems: "center",
    },
    counterValue: {
        fontSize: "1rem",
        textAlign: "center",
        padding: "0 0.5rem",
        userSelect: "none"
    },
}));

interface AppCounterInputProps {
    label: string,
    value: number,
    minValue?: number,
    maxValue?: number,
    onChange: (selectedValue: number) => void,
    style?: React.CSSProperties,
}

const AppCounterInput: React.FC<AppCounterInputProps> =
    ({
         label,
         value,
         minValue,
         maxValue,
         onChange,
         style
     }) => {
        const classes = useStyles();

        const incrementValue = () => {
            let newValue: number;

            if (maxValue !== undefined) {
                newValue = Math.min(maxValue, value + 1);
            } else {
                newValue = value + 1;
            }

            onChange(newValue)
        }

        const decrementValue = () => {
            let newValue: number;

            if (minValue) {
                newValue = Math.max(minValue, value - 1);
            } else {
                newValue = value - 1;
            }

            onChange(newValue)
        }

        return (
            <div className={classes.container} style={style}>
                <span className={classes.label}>
                    {label}
                </span>

                <div className={classes.controlsContainer}>
                    <AppIconButton onClick={decrementValue} size={"small"} color="primary"
                                   disabled={minValue !== undefined && value <= minValue}>
                        <RemoveCircle/>
                    </AppIconButton>

                    <span className={classes.counterValue}>{value}</span>

                    <AppIconButton onClick={incrementValue} size={"small"} color="primary"
                                   disabled={maxValue !== undefined && value >= maxValue}>
                        <AddCircle/>
                    </AppIconButton>
                </div>
            </div>
        );
    }

export default AppCounterInput
