import React, { useEffect, useState } from 'react';

import authClient from './authClient';
import { AuthContext } from './AuthContext';

type AuthProviderProps = {
    children: React.ReactNode,
};

const AuthProvider: React.FC<AuthProviderProps> = ({
    children,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState<string|null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const sessionState = authClient.loadSession();
        if (sessionState.isLoggedIn) {
            setUserName(sessionState.userName || null);
            setIsLoggedIn(true);
        }
        setIsLoading(false);
    }, [setUserName])

    const handleLogIn = async (email: string, password: string) => {
        const authResult = await authClient.logIn(email, password);

        if (!authResult.isError) {
            setUserName(authResult.displayName);
            setIsLoggedIn(true);
        }

        return authResult;
    };

    const handleLogOut = async () => {
        const authResult = await authClient.logOut();
        if (!authResult.isError) {
            setUserName('');
            setIsLoggedIn(false);
        }
        return authResult;
    };

    const handleGetAccountInformation = () => {
        var account = authClient.getAccountInformation();

        if (!account) {
            throw new Error('AuthProvider: Expected account information to exist.');
        }

        return account;
    }

    return (
        <AuthContext.Provider value={{
            logIn: handleLogIn,
            logOut: handleLogOut,
            userName,
            isLoggedIn,
            getToken: authClient.getToken,
            getAccountInformation: handleGetAccountInformation,
            getFullAccountInformation: authClient.getFullAccountInformation,
        }}>
            {isLoading ? 'Loading...' : children}
        </AuthContext.Provider>
    );
};
 
export default AuthProvider;