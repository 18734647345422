import { IconButton, List, ListItem, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

export interface AppListItem {
    id: number,
}

export interface AppListProps<T> {
    items: T[],
    renderItemContent: (item: T) => ReactNode,
    emptyMessage: string,
    onActionClick: Function,
    isActionEnabled: boolean,
    actionIcon: ReactNode
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        '& > *:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
    },
}))

const AppList = <T extends AppListItem>({
    items,
    emptyMessage,
    renderItemContent,
    onActionClick,
    isActionEnabled,
    actionIcon
}: AppListProps<T>) => {
    const classes = useStyles();

    if (items.length === 0) {
        return <p>{emptyMessage}</p>
    }

    return (
        <List className={classes.root}>
            {items.map(item => (
                <ListItem key={item.id}>
                    {renderItemContent(item)}
                    <IconButton
                        style={{ marginLeft: 'auto' }}
                        onClick={() => onActionClick(item.id)}
                        disabled={!isActionEnabled}
                    >
                        {actionIcon}
                    </IconButton>
                </ListItem>
            ))}
        </List>
    );
}

export default AppList;
