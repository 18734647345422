import { List, ListItem, makeStyles, Icon, Button, Theme } from '@material-ui/core';
import { ApiStatusCount, ApiStatusCountName, useHttpRequest } from '../../api';
import { getBookingStatusCount } from '../../api/bookings';
import statusColour from '../../config/Statuses';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import UrgentIcon from '@material-ui/icons/ErrorRounded';
import InProgressIcon from '@material-ui/icons/Timelapse';
import CompletedIcon from '@material-ui/icons/Check';
import { useIsMobileMediaQuery } from '../../useIsMobileMediaQuery';


const useStyles = makeStyles<Theme, { isMobile: boolean }>(theme => ({
    list: {
        display: 'flex',
        flexWrap: props => props.isMobile ? 'wrap' : 'nowrap',
    },
    listItem: {
        paddingLeft: theme.spacing(1),
    },
    text: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        whiteSpace: 'nowrap',
    },
    listContainer: {
        marginRight: 'auto',
        flexBasis: props => props.isMobile ? '100%' : undefined,
    },
    icon: {
        display: 'flex',
        color: 'white',
        transform: 'scale(0.9)'
    },
    button: {
        color: 'white',
        padding: `1px 10px`,
    },
    count: {
        fontSize: '1.3em',
    }
}));

export interface StatusCountProps {
    onClick: (value: ApiStatusCountName) => void
}

const StatusCount: React.FC<StatusCountProps> = ({
    onClick: onChange
}) => {
    const isMobile = useIsMobileMediaQuery('xs');
    const classNames = useStyles({
        isMobile,
    });

    const {
        isLoading,
        httpError,
        result: countsByStatuses,
    } = useHttpRequest(getBookingStatusCount, []);

    const handleClick = (option: ApiStatusCountName) => {
        onChange(option)
    }

    const iconSelect = (groupStatus: ApiStatusCountName) => {
        switch (groupStatus) {
            case 'In Progress':
                return (<InProgressIcon />);
            case 'Urgent':
                return (<UrgentIcon />);
            case 'Processed':
                return (<CompletedIcon />);
        }
    }

    return (
        <div className={classNames.listContainer}>
            <ApiErrorLoaderWrapper
                isLoading={isLoading}
                httpError={httpError}>
                <List className={classNames.list}>
                    {countsByStatuses.map(group => {
                        return (
                            <ListItem key={group.groupName} className={classNames.listItem}>
                                <Button
                                    className={classNames.button}
                                    style={{ backgroundColor: statusColour(group.groupName) }}
                                    onClick={() => handleClick(group.groupName)}
                                    variant="contained"
                                >
                                    <span
                                        className={classNames.count}
                                        style={{ backgroundColor: statusColour(group.groupName) }}>
                                        {group.statuses[0].count}
                                    </span>
                                    <span className={classNames.text}>
                                        {group.groupName}
                                    </span>
                                    <Icon className={classNames.icon}>
                                        {iconSelect(group.groupName)}
                                    </Icon>
                                </Button>
                            </ListItem>
                        )
                    })}
                </List>


            </ApiErrorLoaderWrapper>
        </div>


    )

}

export default StatusCount