import {Box, makeStyles, styled} from '@material-ui/core';
import React from "react";

const useStyles = makeStyles(theme => ({
    appFormButtonsContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
    }
}));

interface AppFormButtonsProps {
    horizontalPosition?: "start" | "center" | "end"
}

const AppFormButtons: React.FC<AppFormButtonsProps> =
    ({
         horizontalPosition = "end",
         children
     }) => {

        const classes = useStyles();

        let horizontalPositionCss;

        switch (horizontalPosition){
            case "start":
                horizontalPositionCss = "flex-start";
                break;
            case "center":
                horizontalPositionCss = "flex-center";
                break;
            case "end":
                horizontalPositionCss = "flex-end";
                break;
        }

        return (
            <div className={classes.appFormButtonsContainer} style={{justifyContent: horizontalPositionCss}}>
                {children}
            </div>
        )
    }

export default AppFormButtons;
