import {makeStyles} from '@material-ui/core';
import {AppCard} from '../AppCard';
import {AppList} from '../AppList';
import {CustomerCarClubPackage} from "../../models/CustomerCarClubPackage";
import {HttpClientFailureResponse} from "../../api";
import React, {useState} from "react";
import EditIcon from "@material-ui/icons/Edit";
import {CustomerCar} from "../../models/CustomerCar";
import {CarClubCleanIcon} from "../SVG/CarClubCleanIcon";
import {EditCustomerCarClubPackageModal} from "../EditCustomerCarClubPackageModal";

export interface CustomerCarClubPackagesViewProps {
    packages: CustomerCarClubPackage[],
    cars: CustomerCar[],
    className: string,
    httpError?: HttpClientFailureResponse,
}

const useStyles = makeStyles(theme => ({
    rowContainer: {
        whiteSpace: 'pre-wrap',
        margin: 0,
        padding: "0.7rem 0",
        display: "flex",
        alignItems: "center",
        justifyItems: "start",
        width: "100%"
    },
    statusContainer: {
        padding: "0rem 0.5rem",
        borderRadius: "5rem",
        width: "20%",
        minWidth: "6rem",
        display: "flex"
    },
    statusBackground: {
        padding: "0rem 0.5rem",
        borderRadius: "5rem",
        width: "auto",
        display: "flex",
        alignItems: "center"
    },
    statusContainerActive: {
        background: "#514ECB"
    },
    statusContainerPaused: {
        background: "#4F6367"
    },
    statusIcon:{
        width: '0.9rem',
        height: '0.9rem',
        marginRight: "0.3rem",
        imageRendering: 'auto'
    },
    statusText: {
        color: "white",
        textAlign: "center",
        width: "100%",
        fontSize: "0.8rem",
        textTransform: "capitalize"
    },
    detailsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyItems: "center",
        width: "40%",
        minWidth: "8rem",
    },
    detailsTextBase: {
        padding: "0",
        margin: "0"
    },
    detailsTextTitle: {
        fontWeight: "bold"
    },
    cleansContainer: {
        display: "flex",
        alignItems: "center",
        justifyItems: "start",
    },
    cleansText: {
        fontWeight: "bold",
        marginLeft: "0.5rem"
    },
}));

const CustomerCarClubPackagesView: React.FC<CustomerCarClubPackagesViewProps> =
    ({
         packages,
         cars,
         className
     }) => {
        const classes = useStyles();
        const [selectedCarClubPackage, setSelectedCarClubPackage] = useState<CustomerCarClubPackage | undefined>();
        const [addModalIsOpen, setAddModalIsOpen] = useState(false);

        const editCarClubPackage = (id: number) => {
            setAddModalIsOpen(true);
            setSelectedCarClubPackage(packages.find(item => item.id === id))
        }

        return (
            <>
                <EditCustomerCarClubPackageModal
                isOpen={addModalIsOpen}
                onClose={() => setAddModalIsOpen(false)}
                carClubPackageId={selectedCarClubPackage?.id ?? 0}
                carClubStatus={selectedCarClubPackage?.status ?? "active"}
                carClubCleans={selectedCarClubPackage?.cleansRemaining ?? 0}
                />
                <AppCard
                    title="Car Club Subscriptions"
                    className={className}
                >
                    <AppList
                        items={packages}
                        emptyMessage={"No subscriptions found"}
                        renderItemContent={item => {
                            const car = cars.find(car => car.id === item.carId);
                            const registrationNumberFirstPart = car?.registrationNumber.slice(undefined, car?.registrationNumber.length - 3);
                            const registrationNumberSecondPart = car?.registrationNumber.slice(car?.registrationNumber.length - 3, undefined);
                            return (
                                <div className={classes.rowContainer}>
                                    <div className={classes.statusContainer}>
                                        <div className={`${classes.statusBackground} ${item.status === "active" ? classes.statusContainerActive : classes.statusContainerPaused}`}>
                                            <img
                                                src="/assets/icons/star-icon-white.png"
                                                alt="Star Icon"
                                                className={classes.statusIcon}
                                            />
                                            <span className={classes.statusText}>
                                                {item.status}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={classes.detailsContainer}>
                                        <p className={`${classes.detailsTextBase} ${classes.detailsTextTitle}`}>
                                            {item.groupName}
                                        </p>
                                        <p className={classes.detailsTextBase}>
                                            {registrationNumberFirstPart} {registrationNumberSecondPart}, {car?.makeAndModel}
                                        </p>
                                    </div>
                                    <div className={classes.cleansContainer}>
                                        <CarClubCleanIcon/>
                                        <span className={classes.cleansText}>
                                            Car Club Cleans: {item.cleansRemaining}
                                        </span>
                                    </div>
                                </div>
                            )
                        }}
                        onActionClick={editCarClubPackage}
                        isActionEnabled={true}
                        actionIcon={<EditIcon />}
                    />
                </AppCard>
            </>
        );
    }

export default CustomerCarClubPackagesView;
