import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { Provider as StoreProvider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Routes, { paths } from './Routes';
import DefaultThemeProvider from './ThemeProvider';
import { MainLayout } from './components/MainLayout';
import { AuthProvider, RequireAuthenticatedUser } from './auth';
import LogInPage from './pages/LogInPage';
import store from './store';


function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StoreProvider store={store}>
        <AuthProvider>
          <CssBaseline />
          <ToastContainer />
          <DefaultThemeProvider>
            <Router>
              <Switch>
                <Route exact path={paths.logIn}><LogInPage /></Route>
                <RequireAuthenticatedUser>
                  <Route path="*">
                    <MainLayout>
                      <Routes />
                    </MainLayout>
                  </Route>
                </RequireAuthenticatedUser>
              </Switch>
            </Router>
          </DefaultThemeProvider>
        </AuthProvider>
      </StoreProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
