import { makeStyles } from '@material-ui/core';
import { FormikProps } from 'formik';
import { createRef } from 'react';
import { useDispatch } from 'react-redux';
import { addCustomerCar } from '../../api/cars';

import { useCustomerId } from '../../Routes';
import { actions } from '../../store';
import { successToast } from '../../toast';
import { AppButton } from '../AppButton';
import { AppFormButtons } from '../AppForm';
import { AppModal } from '../AppModal';
import { CustomerCarCreateFormValues } from './CustomerCarCreateForm';

import CustomerCarCreateForm from './CustomerCarCreateFormContainer';

export interface CustomerCarCreateModalProps {
    isOpen: boolean,
    onClose: () => void,
}

const useStyles = makeStyles(theme => ({
    addressForm: {
        maxWidth: '600px',
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2),
        }
    },
}))
 
const CustomerCarCreateModal: React.FC<CustomerCarCreateModalProps> = ({
    isOpen,
    onClose,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customerId = useCustomerId();
    const formRef = createRef<FormikProps<CustomerCarCreateFormValues>>();

    const handleSubmitClick = () => {
        formRef.current?.submitForm();
    }
    const handleSubmit = async (values: CustomerCarCreateFormValues) => {
        var response = await addCustomerCar(customerId, values);

        if (!response.isError) {
            dispatch(actions.customers.addCarToLoadedCustomer(response.content))
            successToast('Car created.');
            onClose();
        }
    };

    return (
        <AppModal
            isOpen={isOpen}
            onClose={onClose}
            title="Add Car"
        >
            <CustomerCarCreateForm
                className={classes.addressForm}
                formikRef={formRef}
                onSubmit={handleSubmit}
            />
            <AppFormButtons>
            <AppButton style={{marginRight: '10px'}} onClick={onClose}>Cancel</AppButton>
                <AppButton onClick={handleSubmitClick}>Create</AppButton>
            </AppFormButtons>
        </AppModal>
    );
}
 
export default CustomerCarCreateModal;