import { makeStyles } from '@material-ui/core';
import { FormikProps } from 'formik';
import { createRef } from 'react';
import { useDispatch } from 'react-redux';
import { createCustomerAddress } from '../../api/customerAddresses';
import { useCustomerId } from '../../Routes';
import { actions } from '../../store';
import { successToast } from '../../toast';
import { AppButton } from '../AppButton';
import { AppFormButtons } from '../AppForm';
import { AppModal } from '../AppModal';
import { CustomerAddressForm, CustomerAddressFormValues } from '../CustomerAddressForm';

export interface AddAddressModalProps {
    isOpen: boolean,
    onClose: () => void,
}

const useStyles = makeStyles(theme => ({
    addressForm: {
        maxWidth: '600px',
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2),
        }
    },
}))
 
const AddCustomerAddressModal: React.FC<AddAddressModalProps> = ({
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const customerId = useCustomerId();
    const formRef = createRef<FormikProps<CustomerAddressFormValues>>();

    const handleSubmitClick = () => {
        formRef.current?.submitForm();
    }

    const handleSubmit = async (values: CustomerAddressFormValues) => {
        var response = await createCustomerAddress(customerId, values);

        if (!response.isError) {
            dispatch(actions.customers.addAddressToLoadedCustomer(response.content))
            successToast('Address created.');
            onClose();
        }
    };

    return (
        <AppModal
            isOpen={isOpen}
            onClose={onClose}
            title="Add Address"
        >
            <CustomerAddressForm
                className={classes.addressForm}
                formikRef={formRef}
                onSubmit={handleSubmit}
            />
            <AppFormButtons>
                <AppButton style={{marginRight: '10px'}} onClick={onClose}>Cancel</AppButton>
                <AppButton onClick={handleSubmitClick}>Create</AppButton>
            </AppFormButtons>
        </AppModal>
    );
}
 
export default AddCustomerAddressModal;