import { useField, useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import React, { useState } from 'react';

export interface AppFormikDateTimePickerProps {
    label: string,
    formikName: string,
    disableFuture?: boolean,
    clearable?: boolean,
    openTo?: 'year' | 'month' | 'date',
    fullWidth?: boolean,
    readonly?: boolean,
    disabled?: boolean,
}

const useStyles = makeStyles(theme => ({
    inputReadonly: {
        backgroundColor: 'white !important',
        color: `${theme.palette.text.primary} !important`,
    }
}))

const AppFormikDateTimePicker: React.FC<AppFormikDateTimePickerProps> = ({
    label,
    formikName,
    disableFuture,
    clearable,
    openTo,
    fullWidth = true,
    readonly,
    disabled,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { isSubmitting } = useFormikContext();
    const [inputProps, inputMeta, { setValue }] = useField<Date|null>(formikName);
    const [hasBeenOpen, setHasBeenOpen] = useState(false);
    const showError = hasBeenOpen && inputMeta.touched && !!inputMeta.error;
    const helperText = inputMeta.touched ? inputMeta.error : undefined;

    const { value: stringValue, ...restOfInputProps } = inputProps;

    const handleChange = (date: MaterialUiPickersDate) => {
        setValue(date as Date);
    }

    return (
        <DateTimePicker
            {...restOfInputProps}
            ampm={false}
            value={stringValue ? new Date(stringValue) : null}
            onChange={handleChange}
            disableFuture={disableFuture}
            clearable={clearable}
            openTo={openTo}
            onClose={() => setHasBeenOpen(true)}
            format='dd/MM/yyyy HH:mm'
            disabled={isSubmitting || disabled || readonly}
            label={label}
            fullWidth={fullWidth}
            helperText={showError ? helperText : undefined}
            inputVariant="filled"
            error={showError}

            InputProps={{
                className: clsx({
                    [classes.inputReadonly]: readonly,
                }),
                style: label ? undefined : {
                    padding: theme.spacing(1),
                }
            }}
        />
    );
}

export default AppFormikDateTimePicker;