import {HttpClientFailureResponse} from "../../api";
import React, {useState} from "react";
import {AppCard} from "../AppCard";
import {ApiErrorLoaderWrapper} from "../ApiLoaderErrorWrapper";
import CustomerCarClubPackagesView from "./CustomerCarClubPackagesView";
import {CustomerCarClubPackage} from "../../models/CustomerCarClubPackage";
import {CustomerCar} from "../../models/CustomerCar";

export interface CustomerSubscriptionsViewProps {
    packages: CustomerCarClubPackage[],
    cars: CustomerCar[],
    isCarClub: boolean,
    className: string,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
}

const CustomerCarClubPackagesViewContainer: React.FC<CustomerSubscriptionsViewProps> =
    ({
         packages,
         cars,
         isCarClub,
         className,
         isLoading,
         httpError,
     }) => {
    if (!isCarClub){
        return null;
    }

    if (isLoading || httpError) {
        return (
            <AppCard
                title="Car Club Subscriptions"
                className={className}
            >
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    return (
        <CustomerCarClubPackagesView
            className={className}
            packages={packages}
            cars={cars}
        />
    );
}

export default CustomerCarClubPackagesViewContainer;
