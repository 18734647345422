import React from 'react';
import { findCarsByMakeAndModel } from '../../api/cars';
import { ApiCarMakeModelSearchResult } from '../../api/models/CarMakeModelSearch';
import { AppComboBox } from '../AppForm';
import { ComboBoxQueryResult } from '../AppForm/AppComboBox';

export interface SearchByMakeAndModelProps {
    onSelect: (selection: ApiCarMakeModelSearchResult | null) => void,
}

const SearchByMakeAndModel: React.FC<SearchByMakeAndModelProps> = ({
    onSelect,
}) => {
    const handleSearch = async (query: string): Promise<ComboBoxQueryResult<ApiCarMakeModelSearchResult>[]> => {
        if (!query) return [];
        var response = await findCarsByMakeAndModel(query);

        if (response.isError) return[]; // Toast will inform user.

        return response.content.map(result => ({
            label: result.carMakeModel,
            value: result.carMakeModel,
            item: result,
        }));
    }

    return (
        <>
            <AppComboBox
                doSearch={handleSearch}
                onSelect={onSelect}
                placeholder="Search by make or model..."
            />
        </>
    );
}

export default SearchByMakeAndModel;