import { Form } from 'formik';

const AppForm: React.FC = ({
    children,
}) => {
    return (
        <Form>
            {children}
        </Form>
    )
}

export default AppForm;