import { useHistory } from "react-router-dom";
import { paths } from "../Routes";
import { useAuth } from "./AuthContext";

const RequireAuthenticatedUser: React.FC = ({
    children,
}) => {
    const auth = useAuth();
    const history = useHistory();

    if (!auth.isLoggedIn) {
        history.push(paths.logIn);
    }
    
    return (
        <>
            {children}
        </>
    )
}
 
export default RequireAuthenticatedUser;