import { useField, useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';

export interface AppFormikDatePickerProps {
    label: string,
    formikName: string,
    disableFuture?: boolean,
    clearable?: boolean,
    openTo?: 'year' | 'month' | 'date',
    fullWidth?: boolean,
    readonly?: boolean,
    disabled?: boolean,
}

const useStyles = makeStyles(theme => ({
    inputReadonly: {
        backgroundColor: 'white !important',
        color: `${theme.palette.text.primary} !important`,
    }
}))

const AppFormikDatePicker: React.FC<AppFormikDatePickerProps> = ({
    label,
    formikName,
    disableFuture,
    clearable,
    openTo,
    fullWidth = true,
    readonly,
    disabled,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { isSubmitting } = useFormikContext();
    const [inputProps, inputMeta, { setValue }] = useField<string | undefined>(formikName);
    const showError = inputMeta.touched && !!inputMeta.error;
    const helperText = inputMeta.touched ? inputMeta.error : undefined;

    const { value: stringValue, ...restOfInputProps } = inputProps;

    const handleChange = (date: MaterialUiPickersDate) => {
        setValue(date ? date.toISOString() : undefined);
    }

    return (
        <DatePicker
            {...restOfInputProps}
            value={stringValue ? new Date(stringValue) : null}
            onChange={handleChange}
            disableFuture={disableFuture}
            clearable={clearable}
            openTo={openTo}
            format='dd/MM/yyyy'
            disabled={isSubmitting || disabled || readonly}
            label={label}
            fullWidth={fullWidth}
            helperText={helperText}
            inputVariant="filled"
            error={showError}
            InputProps={{
                className: clsx({
                    [classes.inputReadonly]: readonly,
                }),
                style: label ? undefined : {
                    padding: theme.spacing(1),
                }
            }}
        />
    );
}

export default AppFormikDatePicker;