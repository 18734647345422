import { ApiCustomerCar } from "../api/models/ApiCustomerCar";

export type CarCategory = 'CAT_A'|'CAT_B'|'CAT_C';

export interface CustomerCarEditableFields {
    registrationNumber: string,
    makeAndModel: string,
    category: CarCategory,
}

export interface CustomerCar extends CustomerCarEditableFields {
    id: number,
}

export const parseApiCustomerCar = ({
    customerCarId,
    registrationNumber,
    makeAndModel,
    category,
}: ApiCustomerCar): CustomerCar => ({
    id: customerCarId,
    registrationNumber,
    makeAndModel,
    category,
});