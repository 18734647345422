import { AppCard } from "../AppCard";
import { AppList } from "../AppList";
import { AppButton } from "../AppButton";
import { StarRounded } from "@material-ui/icons";
import { preferredValeterCustomerListItem } from "../../models/PreferredValeter";
import { formatDateStringDateTime } from "../../dateFormatters";


export interface CustomerPreferredValetersViewProps {
    className: string,
    onAdd: () => void,
    valeters: preferredValeterCustomerListItem[],
    onDelete: (id: number) => void,
    isDeleting: boolean,
}

const CustomerPreferredValetersView: React.FC<CustomerPreferredValetersViewProps> = ({
    className,
    valeters,
    onAdd,
    onDelete,
    isDeleting,
}) => {

    const appListValeters = valeters.map(valeter => ({
        id: valeter.customerPreferredValeterId,
        resourceName: valeter.resourceName,
        date: valeter.lastDate
    }));

    return (
        <>
            <AppCard
                title="Preferred Valeters"
                className={className}
                buttons={
                    <AppButton
                        size="small"
                        variant="outlined"
                        onClick={onAdd}
                        children="Add Valeter"
                    />
                }
            >
                <AppList
                    items={appListValeters}
                    emptyMessage="No preferred valeters found"
                    renderItemContent={valeter => (
                        <div>
                            <p><b>{valeter.resourceName}</b></p>
                            {valeter.date ?
                                < p > Last Visit: {formatDateStringDateTime(valeter.date)}</p>
                                :
                                < p >-</p>
                            }
                        </div>
                    )}
                    onActionClick={onDelete}
                    isActionEnabled={!isDeleting}
                    actionIcon={<StarRounded style={{ color: '#FFCC00' }} />}
                />
            </AppCard>
        </>
    );
}

export default CustomerPreferredValetersView;