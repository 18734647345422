import { useField, useFormikContext } from 'formik';
import AppInput from './AppInput';

export interface AppFormikInputProps {
    label: string,
    formikName: string,
    type: 'text' | 'password' | 'email',
    fullWidth?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    startAdornment?: string,
    rows?: number,
}


const AppFormikInput: React.FC<AppFormikInputProps> = ({
    label,
    formikName,
    type,
    fullWidth = true,
    readonly,
    disabled,
    startAdornment,
    rows,
}) => {
    const { isSubmitting } = useFormikContext();
    const [inputProps, inputMeta] = useField(formikName);
    const showError = inputMeta.touched && !!inputMeta.error;
    const helperText = inputMeta.touched ? inputMeta.error : undefined;

    return (
        <AppInput
            {...inputProps}
            disabled={isSubmitting || disabled}
            readonly={readonly}
            label={label}
            fullWidth={fullWidth}
            showError={showError}
            helperText={helperText}
            type={type}
            startAdornment={startAdornment}
            rows={rows}
        />
    );
}

export default AppFormikInput;