import { FormikProps } from 'formik';

import { AppFormikInput } from '../AppForm';

export interface CustomerAddressFormValues {
    addressLine1: string,
    addressLine2?: string,
    addressLine3?: string,
    postcode: string,
    town: string,
}

interface OtherProps {
    
}

const CustomerAddressForm: React.FC<OtherProps & FormikProps<CustomerAddressFormValues>> = () => {
    return (
        <>
            <AppFormikInput type="text" formikName="addressLine1" label="Address Line 1" />
            <AppFormikInput type="text" formikName="addressLine2" label="Address Line 2" />
            <AppFormikInput type="text" formikName="addressLine3" label="Address Line 3" />
            <AppFormikInput type="text" formikName="postcode" label="Postcode" />
            <AppFormikInput type="text" formikName="town" label="Town / City" />
        </>
    );
}

export default CustomerAddressForm;