import { Button, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, useDispatchEffect } from '../../store';
import { AppButton } from '../AppButton';
import { AppSelect } from '../AppForm';
import { AppSelectOption } from '../AppForm/AppSelect';
import CarPackagesEditTable from './CarPackagesEditTable';

export interface EditCarPackagesContainerProps {
    onClose: () => void,
}

const useStyles = makeStyles(theme => ({
    controls: {
        '& > *': {
            marginTop: theme.spacing(4),
        },
        '& > *:first-child': {
            margin: theme.spacing(2),
            width: `calc(100% - ${theme.spacing(2) * 2}px)`
        }
    },
    discountContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > *:nth-child(2)': {
            margin: theme.spacing(1),
        },
        marginTop: theme.spacing(2),
    },
    discountButtons: {
        '& > *': {
            width: '100%',
        },
        '& > *:first-child': {
            marginBottom: 0,
        },
        '& > *:last-child': {
            marginTop: 0,
        }
    },
    totalPrice: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
        paddingTop: theme.spacing(1),
    },
    submitButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
}));

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            prefix="£"
        />
    );
}

const EditCarPackagesContainer: React.FC<EditCarPackagesContainerProps> = ({
    onClose,
}) => {
    const classNames = useStyles();
    const dispatch = useDispatch();
    const [openEditorCarId, setOpenEditorCarId] = useState<number | null>(null);
    const [discountPercentage, setDiscountPercentage] = useState(0);

    const {
        booking
    } = useSelector(selectors.booking.selectApiState);

    const country = booking?.address.country;

    useEffect(() => {
        const postcode = country === 1 ? 'ireland' : (booking?.address.postcode ?? '');
        dispatch((actions.packageInfo.fetch(postcode)));
    }, [booking, dispatch, country]);

    const unbookedCars = useSelector(selectors.booking.selectUnbookedCars);
    const calculatedTotalPrice = useSelector(selectors.booking.selectTotalPrice);
    const overridePrice = useSelector(selectors.booking.selectOverridePrice) as number;
    const isSubmittingUpdate = useSelector(selectors.booking.selectIsSubmittingUpdate);
    const showValidationErrors = useSelector(selectors.booking.selectShowValidationErrors);

    const effectivePrice = overridePrice > 0 ? overridePrice.toFixed(2) : calculatedTotalPrice.toFixed(2);

    const unbookedCarOptions: AppSelectOption[] = unbookedCars
        .map(car => ({
            value: car.id,
            label: `${car.registrationNumber} - ${car.makeAndModel}`,
        }));

    if (!booking) return null;

    const addCarToBooking = (carId: number) => {
        dispatch(actions.booking.addCarToBooking(carId));
        setOpenEditorCarId(carId);
    }

    const removeCarFromBooking = (carId: number) => {
        if (openEditorCarId === carId) setOpenEditorCarId(null);

        dispatch(actions.booking.removeCarFromBooking(carId));
    }

    const handleEditRowClicked = (carId: number) => {
        setOpenEditorCarId(openEditorCarId === carId ? null : carId);
    }

    const handleSetOverridePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.booking.setOverridePrice(parseFloat(event.target.value)));
    }

    const handleSetDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountPercentage(Math.min(99, event.target.valueAsNumber));
    }

    const handleApplyDiscount = () => {
        dispatch(actions.booking.setOverridePrice(calculatedTotalPrice * (1 - (discountPercentage / 100))))
    }

    const handleClearDiscount = () => {
        dispatch(actions.booking.setOverridePrice(0));
    }

    const handleSubmit = () => {
        setOpenEditorCarId(null);
        if (booking.carPackages.some(carPackage => carPackage.packageGroupId === -1) || booking.carPackages.length === 0) {
            dispatch(actions.booking.setShowValidationErrors(true));            
            return;
        };
        dispatch(actions.booking.submitUpdate(onClose))
    }

    return (
        <div>
            <CarPackagesEditTable
                bookingCars={booking.carPackages}
                onRemoveCar={removeCarFromBooking}
                onClickRowEdit={handleEditRowClicked}
                openRowCarId={openEditorCarId}
            />
            <div className={classNames.controls}>
                <AppSelect
                    label="Add Car to booking"
                    options={unbookedCarOptions}
                    onChange={addCarToBooking}
                    value={-1}
                    disabled={unbookedCarOptions.length === 0}
                    pleaseSelectMessage={unbookedCarOptions.length === 0 ? 'All customer\'s cars added to booking.' : undefined}
                />
                <Typography
                    variant="h6"
                    align="right"
                    children={`Calculated Total: £${isNaN(calculatedTotalPrice) ? '-.--' : calculatedTotalPrice.toFixed(2)}`}
                />
                <div className={classNames.discountContainer}>
                    <TextField
                        type="number"
                        label="Discount"
                        fullWidth={false}
                        value={discountPercentage}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            style: {
                                fontSize: '1.5em',
                            }
                        }}
                        onChange={handleSetDiscount}
                        style={{
                            width: '70px',
                        }}
                        disabled={isSubmittingUpdate}
                    />
                    <div className={classNames.discountButtons}>
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            disabled={isSubmittingUpdate || !discountPercentage || (effectivePrice === (calculatedTotalPrice * (1 - (discountPercentage / 100))).toFixed(2))}
                            onClick={handleApplyDiscount}
                            style={{ display: 'block' }}
                        >
                            Apply &gt;
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            disabled={isSubmittingUpdate || !overridePrice}
                            onClick={handleClearDiscount}
                            style={{ display: 'block' }}
                        >
                            Clear
                        </Button>
                    </div>
                    <TextField
                        label="Override price"
                        value={overridePrice}
                        onChange={handleSetOverridePrice}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            style: {
                                fontSize: '1.5em',
                                textAlign: 'right'
                            }
                        }}
                        style={{
                            width: '105px',
                        }}
                        disabled={isSubmittingUpdate}
                    />
                </div>
                <Typography
                    variant="h6"
                    align="right"
                    className={classNames.totalPrice}
                    children={`Effective Price: £${isNaN(Number(effectivePrice)) ? '-.--' : effectivePrice}`}
                />
                <div className={classNames.submitButtonContainer}>
                    <AppButton
                        onClick={handleSubmit}
                        disabled={isSubmittingUpdate}
                        children="Update Booking"
                        showSpinner={isSubmittingUpdate}
                    />
                </div>
                {showValidationErrors && booking.carPackages.length === 0 && (
                    <p style={{ textAlign: 'right', color: 'red' }}>You must add at least one car to the booking.</p>
                )}
            </div>
        </div>
    );
}

export default EditCarPackagesContainer;