import { makeStyles, Typography } from '@material-ui/core';
import { createRef, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHttpRequest } from '../../api';
import { ApiAddValeterDTO, ApiValeter } from "../../api/models/ApiPreferredValeters";
import { addPreferredValeter } from "../../api/preferredValeters";
import { getAllValeters } from '../../api/valeters';
import { PreferredValeter } from '../../models/PreferredValeter';
import { useCustomerId } from "../../Routes";
import { successToast } from "../../toast";
import { useIsMobileMediaQuery } from "../../useIsMobileMediaQuery";
import { AppButton } from "../AppButton";
import { AppComboBox, AppFormButtons } from "../AppForm";
import { ComboBoxQueryResult } from '../AppForm/AppComboBox';
import { AppModal } from "../AppModal";

export interface CustomerPreferredValeterCreateModalProps {
    valeters: PreferredValeter[],
    isOpen: boolean,
    onClose: () => void,
    refetch: () => void
}

const useStyles = makeStyles(theme => ({
    searchBox: {
        width: '100%',
        maxWidth: '600px',
        [theme.breakpoints.up("sm")]: {
            width: '600px'
        }
    },
    error: {
        marginTop: theme.spacing(2)
    }
}))

const CustomerPreferredValeterCreateModal: React.FC<CustomerPreferredValeterCreateModalProps> = ({
    valeters,
    isOpen,
    onClose,
    refetch
}) => {
    const classes = useStyles();
    const customerId = useCustomerId();
    const getAllValetersList = useCallback(() => getAllValeters(), []);
    const [selectedValeter, setSelectedValeter] = useState<ApiValeter>();

    const { result: allValeters } = useHttpRequest(getAllValetersList, []);

    const handleSubmitSearch = async (query: string): Promise<ComboBoxQueryResult<ApiValeter>[]> => {
        const filteredValters = allValeters.filter(valeter => valeter.name.toLowerCase().includes(query.toLowerCase()));

        if(filteredValters.length > 0) {
            return filteredValters.map(valeter => ({
                label: valeter.name,
                value: valeter.id.toString(),
                item: valeter
            }));
        } else {
            return [];
        }
    }

    const handleSelect = (selected?: ApiValeter | null) => {
        if(selected) {
            setSelectedValeter(selected);
        }

    };

    const handleSubmit = async () => {
        if(selectedValeter) {
            const dto: ApiAddValeterDTO = { resourceId: selectedValeter.id, resourceName: selectedValeter.name }
            const response = await addPreferredValeter(customerId, dto);
            if (!response.isError) {
                refetch();
                successToast('Valeter Added to favourites.');
            }
            onClose();
        }
    };

    const handleClose = () => {
        setSelectedValeter(undefined);
        onClose();
    };

    const isAlreadyPreferredValeter = selectedValeter && valeters.map(valeter => valeter.resourceId).includes(selectedValeter?.id)
    const addButtonIsDisabled = isAlreadyPreferredValeter || !selectedValeter;

    return (
        <AppModal
            isOpen={isOpen}
            onClose={handleClose}
            title="Add Preferred Valeter"
        >
            <div className={classes.searchBox}>
                <AppComboBox
                    doSearch={handleSubmitSearch}
                    onSelect={handleSelect}
                    placeholder="Search for valeter"
                />
            </div>
            {isAlreadyPreferredValeter &&
                <Typography className={classes.error} align="left" color="error">
                    {selectedValeter?.name} is already a preferred valeter.
                </Typography>
            }
            <AppFormButtons>
                <AppButton style={{ marginRight: '10px' }} onClick={handleClose}>Cancel</AppButton>
                <AppButton onClick={handleSubmit} disabled={addButtonIsDisabled}>Add</AppButton>
            </AppFormButtons>
        </AppModal>
    );
}

export default CustomerPreferredValeterCreateModal;
