import {createMuiTheme, CssBaseline, ThemeProvider as MuiThemeProvider} from '@material-ui/core';
import React from "react";

const primaryColor = '#3c99a0';

const defaultTheme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    '& .toastSuccess': {
                        backgroundColor: primaryColor,
                    }
                },
            },
        },
    },
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: 'rgb(82, 207, 210)',
        },
        error: {
            main: '#A03C3C',
        },
    },
});

const errorTheme = createMuiTheme(
    {
        ...defaultTheme,
        palette: {
            primary: defaultTheme.palette.error
        }
    })

interface ThemeSwitchProviderProps {
    theme?: "default" | "error"
}

export const ThemeSwitchProvider: React.FC<ThemeSwitchProviderProps> =
    ({
         theme = "default",
         children
     }) => {

        let selectedTheme;
        switch (theme) {
            case "default":
                selectedTheme = defaultTheme;
                break;
            case "error":
                selectedTheme = errorTheme
                break;
        }

        return (
            <MuiThemeProvider theme={selectedTheme}>
                {children}
            </MuiThemeProvider>
        )
    }

const DefaultThemeProvider: React.FC =
    ({
         children,
     }) => {
        return (
            <ThemeSwitchProvider theme={"default"}>
                <CssBaseline/>
                {children}
            </ThemeSwitchProvider>
        );
    }

export default DefaultThemeProvider;
