import { Collapse, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useSelector } from 'react-redux';
import { DetailedBookingCustomerCar } from '../../models/Booking';
import { selectors } from '../../store';
import CarPackagesEditTableRowEditor from './CarPackagesEditTableRowEditor';

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export type CarPackagesEditTableRowProps = {
    bookingCar: DetailedBookingCustomerCar,
    onDelete: (rowId: number) => void,
    editorIsOpen: boolean,
    onClickEdit: () => void,
}

const CarPackagesEditTableRow: React.FC<CarPackagesEditTableRowProps> = ({
    bookingCar,
    onDelete,
    editorIsOpen,
    onClickEdit,
}) => {
    const classes = useStyles();
    const totalPriceForCar = useSelector(selectors.booking.makeSelectTotalPriceForCar(bookingCar.customerCarId))
    const showValidationErrors = useSelector(selectors.booking.selectShowValidationErrors);

    const optionalExtrasString = bookingCar.optionalExtras.length > 0 ?
        bookingCar.optionalExtras.map(oe => oe.packageItemName).join('\n') :
        'None selected';

    return (
        <>
            <TableRow
                className={classes.root}
            >
                <TableCell onClick={onClickEdit} style={{ cursor: 'pointer' }}>
                    {bookingCar.registrationNumber}: {bookingCar.makeAndModel}
                </TableCell>
                <TableCell onClick={onClickEdit} style={{ cursor: 'pointer' }}>{bookingCar.packageGroupName || (showValidationErrors ? <span style={{ color: 'red' }}>Please select a package</span> : 'None selected')}</TableCell>
                <TableCell onClick={onClickEdit} style={{ cursor: 'pointer', whiteSpace: 'pre' }}>{optionalExtrasString}</TableCell>
                <TableCell onClick={onClickEdit} style={{ cursor: 'pointer', whiteSpace: 'pre' }}>£{ isNaN(totalPriceForCar) ? '-.--' : totalPriceForCar.toFixed(2)}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={onClickEdit}>
                        {<EditIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => onDelete(bookingCar.customerCarId)}>
                        {<DeleteIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={editorIsOpen} timeout="auto" unmountOnExit>
                        <CarPackagesEditTableRowEditor
                            bookingCar={bookingCar}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CarPackagesEditTableRow;