import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApiErrorLoaderWrapper } from '../../components/ApiLoaderErrorWrapper'
import { AppButton } from '../../components/AppButton'
import { AppCard } from '../../components/AppCard'
import EditCarPackagesModal from '../../components/EditCarPackages'
import { actions, selectors } from '../../store'
import BookingCarsTable from './BookingCarsTable'

export type BookingCarsCardProps = {
    cardClassName?: string,
}

const BookingCarsCard: React.FC<BookingCarsCardProps> = ({
    cardClassName,
}) => {
    const dispatch = useDispatch();
    const [editCarPackagesModalIsOpen, setEditCarPackagesModalIsOpen] = useState(false);

    const handleCloseEditCarPackagesModal = () => {
        setEditCarPackagesModalIsOpen(false);
    }

    const {
        isLoading,
        httpError,
        booking,
    } = useSelector(selectors.booking.selectApiState);

    const handleOpenEditCarPackagesModal = () => {
        setEditCarPackagesModalIsOpen(true)
        dispatch(actions.booking.setShowValidationErrors(false))
    }

    const allowEditPackages = booking?.status !== 'Confirmed' && booking?.status !== 'Cancelled' && booking?.status !== 'Complete';

    return (
        <AppCard
            title="Car Packages"
            className={cardClassName}
            buttons={allowEditPackages ? <AppButton variant="outlined" size="small" onClick={handleOpenEditCarPackagesModal}>Edit</AppButton> : undefined}
        >
            <ApiErrorLoaderWrapper
                isLoading={isLoading}
                httpError={httpError}
            >
                {booking && (
                    <>
                        <BookingCarsTable
                            bookingCars={booking.carPackages}
                        />
                        <EditCarPackagesModal
                            isOpen={editCarPackagesModalIsOpen}
                            onClose={handleCloseEditCarPackagesModal}
                        />
                    </>
                )}
            </ApiErrorLoaderWrapper>
        </AppCard>
    )
}

export default BookingCarsCard;
