import { HttpClientResponse } from "../common/httpClient"

export interface ApiStandardResponse<T> {
    message: string,
    payload: T,
    statusCode: number,
}

export function unpackStandardResponse<TApiModel, TResponseModel>(
    response: HttpClientResponse<ApiStandardResponse<TApiModel>>,
    modelBuilder: (apiModel: TApiModel) => TResponseModel,
): HttpClientResponse<TResponseModel> {
    if (response.isError) {
        return response;
    }

    return {
        isError: false,
        statusCode: response.statusCode,
        content: modelBuilder(response.content.payload),
    }
}

export function unpackStandardListResponse<TApiModel, TResponseModel>(
    response: HttpClientResponse<ApiStandardResponse<TApiModel[]>>,
    modelBuilder: (apiModel: TApiModel) => TResponseModel): HttpClientResponse<TResponseModel[]> {
    
    return unpackStandardResponse(
        response,
        apiResponse => apiResponse.map(item => modelBuilder(item)),
    );
}
