import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export type AppSelectOption = {
    value: number,
    label: string,
    disabled?: boolean
}

export type AppSelectProps = {
    label: string,
    options: AppSelectOption[],
    value: number,
    onChange: (selectedValue: number) => void,
    fullWidth?: boolean,
    pleaseSelectMessage?: string,
    disabled?: boolean,
}

export const AppSelect: React.FC<AppSelectProps> = ({
    label,
    options,
    value,
    onChange,
    fullWidth = true,
    pleaseSelectMessage = '-- Please Select --',
    disabled,
}) => {

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as number);
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                disabled={disabled}
            >
                <MenuItem disabled value={-1}>{pleaseSelectMessage}</MenuItem>
                {options.map(option => (
                    <MenuItem key={option.value} disabled={option.disabled} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default AppSelect;
