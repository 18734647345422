import { ApiValeterAppointment, ApiValeterDiary } from '../api/models';

export interface ValeterDiary {
    id: number,
    name: string,
    color: string,
    calculatedAppointmentDuration: number,
    appointments: ValeterAppointment[],
}

export interface ValeterAppointment {
    id: string,
    postcode: string,
    isAvailable: boolean,
    startTime: Date,
    endTime: Date,
}

export const parseApiValeterAppointment = ({
    id,
    postcode,
    startTime,
    endTime,
    isAvailable,
}: ApiValeterAppointment): ValeterAppointment => ({
    id,
    postcode,
    startTime: new Date(startTime),
    endTime: new Date(endTime),
    isAvailable,
});

export const parseApiValeter = ({
    id,
    name,
    color,
    appointments,
    calculatedAppointmentDuration,
}: ApiValeterDiary): ValeterDiary => ({
    id,
    name,
    color,
    appointments: appointments.map(parseApiValeterAppointment),
    calculatedAppointmentDuration,
});