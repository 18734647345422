import { useCallback, useState } from 'react';
import { useHttpRequest } from '../../api';
import { getAllValeterDiariesForDate } from '../../api/valeterDiaries';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import CreateMbhBookingForm from './CreateMbhBookingForm';

export type CreateMbhBookingFormContainerProps = {
    initialDate: Date,
    mbhAppointmentTypeIds: number[],
    onSubmitMbhBooking: (selectedDate: Date, startTimeInMinutes: number, endTimeInMinutes: number, resourceId: number) => void,
    isSubmitting: boolean,
}

const CreateMbhBookingFormContainer: React.FC<CreateMbhBookingFormContainerProps> = ({
    initialDate,
    mbhAppointmentTypeIds,
    onSubmitMbhBooking,
    isSubmitting,
}) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const getAllValeterDiaries = useCallback(() => getAllValeterDiariesForDate(selectedDate, mbhAppointmentTypeIds), [selectedDate, mbhAppointmentTypeIds]);

    const {
        isLoading: isLoadingValeters,
        result: valeters,
    } = useHttpRequest(getAllValeterDiaries, []);

    const handleSubmit = (startTimeMinutes: number, endTimeMinutes: number, valeterId: number) => {
        onSubmitMbhBooking(selectedDate, startTimeMinutes, endTimeMinutes, valeterId);
    }

    return (
        <ApiErrorLoaderWrapper
            isLoading={isSubmitting}
        >
            <CreateMbhBookingForm
                isLoadingValeters={isLoadingValeters}
                valeters={valeters}
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
                onSubmit={handleSubmit}
            />
        </ApiErrorLoaderWrapper>
    )
}

export default CreateMbhBookingFormContainer;