import { ApiCustomer, ApiCustomerDetail, ApiUpdateCustomerDetailsDto, ApiCustomerDetailBooking, ApiCustomerAddress } from '../api';
import { CustomerAddress, parseApiCustomerAddress } from './CustomerAddress';
import { CustomerCar, parseApiCustomerCar } from './CustomerCar';
import { PreferredValeter } from './PreferredValeter';

export interface CustomerCore {
    title: string,
    firstName: string;
    lastName: string;
    contactNumber: string;
    email: string;
    dateOfBirth: string | null,
}

export interface Customer extends CustomerCore {
    id: number;
    addresses: CustomerAddress[],
    cars: CustomerCar[],
    bookings?: CustomerDetailBooking[],
    preferredValeters: PreferredValeter[],
    isCarClub: boolean
}

export interface UpdateCustomerDetailsDto extends CustomerCore {
    id: number,
}

export interface CustomerDetailBooking {
    id: number,
    address: ApiCustomerAddress,
    bookingDate: string,
    numberOfCars: number,
    resourceId: number
}

export const convertToApiUpdateCustomerDetailsDto = ({
    id,
    title,
    firstName,
    lastName,
    contactNumber,
    email,
    dateOfBirth,

}: UpdateCustomerDetailsDto): ApiUpdateCustomerDetailsDto => ({
    customerId: id,
    title,
    firstName,
    lastName,
    contactNumber,
    email,
    dateOfBirth,

});

export const parseApiCustomerBooking = ({
    bookingId,
    bookingDate,
    address,
    numberOfCars,
    resourceId
}: ApiCustomerDetailBooking): CustomerDetailBooking => ({
    id: bookingId,
    bookingDate,
    address,
    numberOfCars,
    resourceId
});

export const parseApiCustomer = ({
    customerId,
    title,
    firstName,
    lastName,
    contactNumber,
    email,
    dateOfBirth,
    addresses,
    cars,
    preferredValeters,
    isCarClub
}: ApiCustomer): Customer => ({
    id: customerId,
    title: title || '',
    firstName,
    lastName,
    contactNumber,
    email,
    dateOfBirth,
    addresses: addresses.map(parseApiCustomerAddress),
    cars: cars.map(parseApiCustomerCar),
    preferredValeters,
    isCarClub
});

export const parseApiCustomerDetail = ({
    customer: {
        customerId,
        title,
        firstName,
        lastName,
        contactNumber,
        email,
        dateOfBirth,
        addresses,
        cars,
        preferredValeters,
        isCarClub
    },
    previousBookings,
    upcomingBookings
}: ApiCustomerDetail): Customer => ({
    id: customerId,
    title: title || '',
    firstName,
    lastName,
    contactNumber,
    email,
    dateOfBirth,
    addresses: addresses.map(parseApiCustomerAddress),
    cars: cars.map(parseApiCustomerCar),
    // We could do more with this in future e.g. keep upcoming and previous separated and mark as upcoming / previous, but just concat for now.
    bookings: [...upcomingBookings, ...previousBookings].map(parseApiCustomerBooking),
    preferredValeters,
    isCarClub
});
