import { List, ListItem, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

export interface AppLinkListItem {
    id: number,
}

export interface AppLinkListProps<T> {
    items: T[],
    renderItemContent: (item: T) => ReactNode,
    emptyMessage: string,
    linkPath: (id: number) => string
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        '& > *:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '& a': {
            width: '100%',
            textDecoration: 'none',
            color: `${theme.palette.text.primary}`
        }
    },
    item: {
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    }
}))

const AppLinkList = <T extends AppLinkListItem>({
    items,
    emptyMessage,
    renderItemContent,
    linkPath
}: AppLinkListProps<T>) => {
    const classes = useStyles();

    if (items.length === 0) {
        return <p>{emptyMessage}</p>
    }

    return (
        <List className={classes.root}>
            {items.map(item => (
                <ListItem className={classes.item} key={item.id}>
                    <Link to={linkPath(item.id)}>
                        {renderItemContent(item)}
                    </Link>
                </ListItem>
            ))}
        </List>
    );
}

export default AppLinkList;