import { makeStyles, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { AppButton } from '../AppButton';
import { AppForm, AppFormButtons, AppFormFields, AppFormikInput } from '../AppForm';

export interface LogInFormValues {
    email: string,
    password: string,
}

interface OtherProps {
    errorMessage?: string,
}

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        marginTop: theme.spacing(1),
    }
}));
 
const LogInForm: React.FC<OtherProps & FormikProps<LogInFormValues>> = ({
    isSubmitting,
    errorMessage,
}) => {
    const classes = useStyles();

    return (
        <AppForm>
            <AppFormFields>
                <AppFormikInput formikName="email" label="Email" type="email" />
                <AppFormikInput formikName="password" label="Password" type="password" />
            </AppFormFields>
            <AppFormButtons>
                <AppButton
                    children="Log In"
                    disabled={isSubmitting}
                    showSpinner={isSubmitting}
                    type="submit"
                />
            </AppFormButtons>
            {errorMessage && (
                <Typography className={classes.errorMessage} align="center" color="error">{errorMessage}</Typography>
            )}
        </AppForm>
    );
}
 
export default LogInForm;