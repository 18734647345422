import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DetailedBookingCustomerCar } from '../../models/Booking';
import { actions, selectors } from '../../store';
import { AppSelect } from '../AppForm';
import { AppSelectOption } from '../AppForm/AppSelect';

export interface CarPackagesEditTableRowEditorProps {
    bookingCar: DetailedBookingCustomerCar,
}

const useStyles = makeStyles(theme => ({
    form: {
        '& > *': {
            marginBottom: theme.spacing(2),
        }
    },
    optionalExtraChips: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

const CarPackagesEditTableRowEditor: React.FC<CarPackagesEditTableRowEditorProps> = ({
    bookingCar,
}) => {
    const classNames = useStyles();
    const dispatch = useDispatch();
    const allPackageGroups = useSelector(selectors.packageInfo.selectAllPackages);
    const allOptionalExtras = useSelector(selectors.packageInfo.selectAllOptionalExtras);

    const packageGroupOptions: AppSelectOption[] = allPackageGroups
        .map(pg => ({
            value: pg.id,
            label: pg.name,
        }));

    let optionalExtraOptions: AppSelectOption[] = [];

    if (bookingCar.packageGroupId) {
        const selectedPackageGroup = allPackageGroups.find(packageGroup => packageGroup.id === bookingCar.packageGroupId);

        if (selectedPackageGroup) {
            const selectedOptionalExtraIds = bookingCar.optionalExtras.map(optionalExtra => optionalExtra.packageItemId);
            optionalExtraOptions = selectedPackageGroup.optionalExtraIds
                .filter(optionalExtraId => !selectedOptionalExtraIds.includes(optionalExtraId))
                .map(optionalExtraId => {
                    const optionalExtra = allOptionalExtras.find(optionalExtra => optionalExtra.id === optionalExtraId);
                    return {
                        value: optionalExtra?.id || -2,
                        label: optionalExtra?.name || '',
                    }
                });
        }
    }

    const handleSetCarPackage = (packageId: number) => {
        dispatch(actions.booking.setCarMainPackage(bookingCar.customerCarId, packageId));
    }

    const makeHandleRemoveOptionalExtra = (packageItemId: number) => () => {
        dispatch(actions.booking.removeOptionalExtra(bookingCar.customerCarId, packageItemId));
    }

    const handleAddOptionalExtra = (packageItemId: number) => {
        dispatch(actions.booking.addOptionalExtra(bookingCar.customerCarId, packageItemId));
    }

    return (
        <Box margin={1}>
            <div className={classNames.form}>
                <Typography variant="h6" gutterBottom component="div">
                    Edit car package
            </Typography>
                <AppSelect
                    options={packageGroupOptions}
                    label="Main Package"
                    onChange={handleSetCarPackage}
                    value={bookingCar.packageGroupId}
                />
                {bookingCar.packageGroupId > -1 && (
                    <AppSelect
                        options={optionalExtraOptions}
                        label="Add Optional Extra"
                        onChange={handleAddOptionalExtra}
                        value={-1}
                        disabled={optionalExtraOptions.length === 0}
                        pleaseSelectMessage={optionalExtraOptions.length === 0 ? 'All available optional extras added.' : undefined}
                    />
                )}
                <div className={classNames.optionalExtraChips}>
                    {bookingCar.optionalExtras.map(optionalExtra => (
                        <Chip
                            key={optionalExtra.packageItemId}
                            label={optionalExtra.packageItemName}
                            onDelete={makeHandleRemoveOptionalExtra(optionalExtra.packageItemId)}
                        />
                    ))}
                </div>
            </div>
        </Box>
    );
}

export default CarPackagesEditTableRowEditor;