import {Button, CircularProgress, makeStyles} from '@material-ui/core';
import {ThemeSwitchProvider} from "../../ThemeProvider";

export interface AppButtonProps {
    disabled?: boolean,
    showSpinner?: boolean,
    type?: 'button' | 'reset' | 'submit',
    size?: 'small' | 'medium' | 'large',
    variant?: 'contained' | 'text' | 'outlined',
    theme?: "default" | "error",
    onClick?: () => void,
    style?: React.CSSProperties,
}

const useStyles = makeStyles({
    root: {
        position: 'relative',
        whiteSpace: 'nowrap',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    }
});

export const AppButton: React.FC<AppButtonProps> =
    ({
         children,
         disabled,
         showSpinner,
         type,
         size,
         variant = 'contained',
         theme= "default",
         onClick,
         style,
     }) => {
        const classes = useStyles();

        return (
            <span className={classes.root}>
                <ThemeSwitchProvider theme={theme}>
                    <Button
                        children={children}
                        disabled={disabled || showSpinner}
                        type={type}
                        color="primary"
                        variant={variant}
                        size={size}
                        onClick={onClick}
                        style={style}
                    />
                </ThemeSwitchProvider>
                {showSpinner && (
                    <CircularProgress size={24} className={classes.progress}/>
                )}
        </span>
        );
    }
