import { useState } from 'react';
import { createManualMbhBooking } from '../../api/bookings';
import { successToast } from '../../toast';
import { AppModal } from '../AppModal'
import CreateMbhBookingFormContainer from './CreateMbhBookingFormContainer'

export interface CreateMbhBookingModalProps {
    bookingId: number,
    isOpen: boolean,
    onClose: (success: boolean) => void,
    initialDate: Date,
    mbhAppointmentTypeIds: number[],
};

const CreateMbhBookingModal: React.FC<CreateMbhBookingModalProps> = ({
    bookingId,
    isOpen,
    onClose,
    initialDate,
    mbhAppointmentTypeIds,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmitMbhBooking = async (selectedDate: Date, startTimeInMinutes: number, endTimeInMinutes: number, resourceId: number) => {
        const bareDate = new Date(selectedDate);
        bareDate.setHours(0);
        bareDate.setMinutes(0);
        bareDate.setSeconds(0);
        bareDate.setMinutes(0);
        bareDate.setMilliseconds(0);
        const startDateTime = new Date(bareDate);
        startDateTime.setMinutes(startTimeInMinutes);

        const endDateTime = new Date(bareDate);
        endDateTime.setMinutes(endTimeInMinutes);

        setIsSubmitting(true);

        const response = await createManualMbhBooking({
            bookingId,
            startDateTime: startDateTime.toISOString(),
            endDateTime: endDateTime.toISOString(),
            resourceId,
        });

        setIsSubmitting(false);

        if (!response.isError) {
            successToast("Mbh booking successfully created.")
            onClose(true);
        }
    }

    return (
        <AppModal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            title="Create MBH Booking"
        >
            <CreateMbhBookingFormContainer
                initialDate={initialDate}
                mbhAppointmentTypeIds={mbhAppointmentTypeIds}
                onSubmitMbhBooking={handleSubmitMbhBooking}
                isSubmitting={isSubmitting}
            />
        </AppModal>
    )
}

export default CreateMbhBookingModal