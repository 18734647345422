import {ApiPaginatedPayload } from '../../api';
import config from '../../config';

export interface PaginationState {
    currentPage: number,
    from: number,
    pageSize: number,
    to: number,
    totalCount: number,
    totalPages: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean,
}

export const initialPaginationState: PaginationState = {
    currentPage: 0,
    from: 1,
    pageSize: config.pageSizeOptions[0],
    to: 1,
    totalCount: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
}

export const applyApiPaginatedPayloadToState = (state: PaginationState, apiPayload: ApiPaginatedPayload<any>) => {
    state.currentPage = apiPayload.currentPage;
    state.from = apiPayload.from;
    state.pageSize = apiPayload.pageSize;
    state.to = apiPayload.to;
    state.totalCount = apiPayload.totalCount;
    state.totalPages = apiPayload.totalPages;
    state.hasPreviousPage = apiPayload.hasPreviousPage;
    state.hasNextPage = apiPayload.hasNextPage;
}