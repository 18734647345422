export const formatDurationStringFromMinutes = (durationMinutes: number) => {
    const hours = Math.floor(durationMinutes / 60);
    const minutes = Math.round(durationMinutes - (hours * 60));

    const hoursText = hours > 0 ?
        `${hours} hour${hours > 1 ? 's' : ''}` :
        '';

    const minutesText = `${minutes} minute${minutes > 1 ? 's' : ''}`;

    const components = [];
    hours > 0 && components.push(hoursText);
    minutes > 0 && components.push(minutesText);

    return components.join(' ');
}