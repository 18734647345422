import React, { useState } from 'react';

import { CustomerAddress } from '../../models/CustomerAddress';
import { AppCard } from '../AppCard';
import { HttpClientFailureResponse } from '../../api';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import { useCustomerId } from '../../Routes';
import { deleteCustomerAddress } from '../../api/customerAddresses';
import { actions } from '../../store';
import { useDispatch } from 'react-redux';
import AddAddressModal from '../AddCustomerAddressModal';
import CustomerAddressesView from './CustomerAddressesView';
import { successToast } from '../../toast';

export interface CustomerAddressesViewProps {
    addresses: CustomerAddress[],
    className: string,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
}

const CustomerAddressesViewContainer: React.FC<CustomerAddressesViewProps> = ({
    addresses,
    className,
    isLoading,
    httpError,
}) => {
    const dispatch = useDispatch();
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const customerId = useCustomerId();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCustomerAddress = async (addressId: number) => {
        setIsDeleting(true);
        var response = await deleteCustomerAddress(customerId, addressId);

        if (!response.isError) {
            dispatch(actions.customers.deleteAddressFromLoadedCustomer(addressId));
            successToast('Address deleted.')
        }

        setIsDeleting(false);
    }

    const handleDeleteClick = (addressId: number) => {
        const confirmed = window.confirm('This action is irreversable, are you sure you want to delete this address?');
        
        if (confirmed) {
            handleDeleteCustomerAddress(addressId);
        }
    }


    if (isLoading || httpError) {
        return (
            <AppCard
                title="Addresses" 
                className={className}
            >
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    return (
        <>
            <AddAddressModal
                isOpen={addModalIsOpen}
                onClose={() => setAddModalIsOpen(false)}
            />
            <CustomerAddressesView
                onAddClick={() => setAddModalIsOpen(true)}
                className={className}
                addresses={addresses}
                onDeleteClick={handleDeleteClick}
                isDeleting={isDeleting}
            />
        </>
    );
}

export default CustomerAddressesViewContainer;