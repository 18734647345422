import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { formatTimeOnly } from '../../dateFormatters';
import { ValeterAppointment, ValeterDiary } from '../../models/ValeterDiary';

export type ValeterDiaryViewProps = {
    valeterDiary: ValeterDiary,
    onAppointmentSelect: (appointment: ValeterAppointment) => void,
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(4),
        padding: 0,
        '& > li:not(:last-child)': {
            marginBottom: theme.spacing(1),
        }
    },
    timeslot: {
        borderRadius: theme.shape.borderRadius,
        listStyle: 'none',
        margin: 0,
        padding: theme.spacing(1),
        '& > p': {
            margin: 0,
        },
    },
    availableSlot: {
        backgroundColor: 'lightGreen',
        cursor: 'pointer',
        transition: 'opacity 0.2s ease-in-out',
        '& > p': {
            margin: 0,
        },
        '&:hover': {
            opacity: 0.8,
        }
    },
    bookedSlot: {
        backgroundColor: 'lightGrey',
    }
}))

const ValeterDiaryView: React.FC<ValeterDiaryViewProps> = ({
    valeterDiary,
    onAppointmentSelect,
}) => {
    const classNames = useStyles();

    return (
        <ul className={classNames.root}>
            {valeterDiary.appointments.map(appointment => 
                <li
                    key={formatTimeOnly(appointment.startTime)}
                    className={clsx({
                        [classNames.timeslot]: true,
                        [classNames.availableSlot]: appointment.isAvailable,
                        [classNames.bookedSlot]: !appointment.isAvailable
                    })}
                    onClick={() => appointment.isAvailable ? onAppointmentSelect(appointment) : undefined}
                >
                    <p>{formatTimeOnly(appointment.startTime)} to {formatTimeOnly(appointment.endTime)}</p>
                    {appointment.postcode && <p>Postcode: {appointment.postcode}</p>}
                </li>
            )}
        </ul>
    )
}

export default ValeterDiaryView;
