import React, { Ref } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import CustomerCarCreateForm, { CustomerCarCreateFormValues } from './CustomerCarCreateForm';
import { CarCategory } from '../../models/CustomerCar';

export interface CustomerCarCreateFormContainerProps {
    className?: string,
    formikRef?: Ref<FormikProps<CustomerCarCreateFormValues>>,
    onSubmit: (values: CustomerCarCreateFormValues) => Promise<any>,
}

export const customerAddressValidationFields = {
    registrationNumber: Yup.string()
        .required('Required.'),
    makeAndModel: Yup.string()
        .required('Required.'),
    category: Yup.mixed()
        .oneOf<CarCategory>(['CAT_A', 'CAT_B', 'CAT_C'])
        .required('Required.'),
}

const validationSchema: Yup.SchemaOf<CustomerCarCreateFormValues> = Yup.object().shape(
    customerAddressValidationFields
)

const initialValues: CustomerCarCreateFormValues = {
    registrationNumber: '',
    makeAndModel: '',
    category: 'CAT_B',
}

const CustomerCarCreateFormContainer: React.FC<CustomerCarCreateFormContainerProps> = ({
    className,
    formikRef,
    onSubmit,
}) => {
    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {formikProps => (
                <Form className={className}>
                    <CustomerCarCreateForm
                        {...formikProps}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default CustomerCarCreateFormContainer;