import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { HttpClientFailureResponse } from '../api';
import {getAllPackageInfo, getPackageInfoByPostcode} from '../api/packageInfo';
import { Package, PackageCategory, PackageInfo, PackageItem, PackagePossiblePrices } from '../models/PackageInfo';

export const sliceName = 'packageInfo';

const fetchAsyncThunk = createAsyncThunk<
    PackageInfo,
    string,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetch`,
    async (postcode, { rejectWithValue }) => {
        const response = await (
            postcode.length > 0 ? getPackageInfoByPostcode(postcode === 'ireland' ? '' : postcode)
                : getAllPackageInfo()
        );

        if (response.isError) {
            return rejectWithValue(response);
        }
        return response.content;
    }
)

const packagesEntityAdapter = createEntityAdapter<Package>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const packageCategoriesEntityAdapter = createEntityAdapter<PackageCategory>({
    selectId: p => p.id,
});

const optionalExtrasEntityAdapter = createEntityAdapter<PackageItem>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type SliceState = {
    packageCategoryEntities: EntityState<PackageCategory>,
    packageEntities: EntityState<Package>,
    optionalExtraEntities: EntityState<PackageItem>,
    isLoading: boolean,
    apiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    packageCategoryEntities: packageCategoriesEntityAdapter.getInitialState(),
    packageEntities: packagesEntityAdapter.getInitialState(),
    optionalExtraEntities: optionalExtrasEntityAdapter.getInitialState(),
    isLoading: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(fetchAsyncThunk.pending, state => {
            state.isLoading = true;
            state.apiError = undefined;
        });
        builder.addCase(fetchAsyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(fetchAsyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;

            packageCategoriesEntityAdapter.removeAll(state.packageCategoryEntities);
            packagesEntityAdapter.removeAll(state.packageEntities);
            optionalExtrasEntityAdapter.removeAll(state.optionalExtraEntities);

            packageCategoriesEntityAdapter.setAll(state.packageCategoryEntities, payload.categories);
            packagesEntityAdapter.setAll(state.packageEntities, payload.packageGroups);

            const optionalExtras = payload.packageItems.filter(packageItem => !!packageItem.optionalExtraCategoryId);
            optionalExtrasEntityAdapter.setAll(state.optionalExtraEntities, optionalExtras);
        });
    },
});

export const {
    reducer,
} = slice;

export const actions = {
    fetch: (postcode: string = '') => fetchAsyncThunk(postcode),
};

const selectSliceState = (state: any) => state[sliceName] as SliceState;
const packageCategoriesEntitySelectors = packageCategoriesEntityAdapter.getSelectors();
const packagesEntitySelectors = packagesEntityAdapter.getSelectors();
const optionalExtrasEntitySelectors = optionalExtrasEntityAdapter.getSelectors();

const selectAllPackages = createSelector(
    selectSliceState,
    state => packagesEntitySelectors.selectAll(state.packageEntities)
);

const selectAllPackageCategories = createSelector(
    selectSliceState,
    state => packageCategoriesEntitySelectors.selectAll(state.packageCategoryEntities)
);

const selectAllOptionalExtras = createSelector(
    selectSliceState,
    state => optionalExtrasEntitySelectors.selectAll(state.optionalExtraEntities)
);

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isLoading,
            apiError: state.apiError,
        }),
    ),
    selectAllPackages,
    selectPackageById: createSelector(
        selectSliceState,
        (_: any, packageId: number) => packageId,
        (state, packageId) => packagesEntitySelectors.selectById(state.packageEntities, packageId),
    ),
    selectAllPackageCategories,
    selectAllOptionalExtras,
    selectOptionalExtraById: createSelector(
        selectSliceState,
        (_: any, packageItemId: number) => packageItemId,
        (state, packageItemId) => optionalExtrasEntitySelectors.selectById(state.optionalExtraEntities, packageItemId),
    ),
    selectPackageGroupPrices: createSelector(
        selectAllPackages,
        allPackages => {
            const result: {
                [key: number]: PackagePossiblePrices,
            } = {};
            allPackages.forEach(packageGroup => {
                result[packageGroup.id] = packageGroup.possiblePrices
            })
            return result;
        }
    ),
    selectOptionalExtraPrices: createSelector(
        selectAllOptionalExtras,
        allOptionalExtras => {
            const result: {
                [key: number]: number
            } = {};
            allOptionalExtras.forEach(optionalExtra => {
                result[optionalExtra.id] = optionalExtra.optionalExtraPrice;
            });
            return result;
        }
    )
};