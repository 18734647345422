import { makeStyles } from '@material-ui/core';
import { CustomerAddress } from '../../models/CustomerAddress';
import { AppButton } from '../AppButton';
import { AppCard } from '../AppCard';
import { AppList } from '../AppList';
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

export interface CustomerAddressesViewProps {
    addresses: CustomerAddress[],
    className: string,
    onAddClick: () => void,
    onDeleteClick: (addressId: number) => void,
    isDeleting: boolean,
}

const getAddressComponents = (address: CustomerAddress) => {
    return [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.postcode,
        address.town
    ]
        .filter(c => !!c);
}

const useStyles = makeStyles(theme => ({
    addressContent: {
        whiteSpace: 'pre-wrap',
        margin: 0,
    }
}));

const CustomerAddressesView: React.FC<CustomerAddressesViewProps> = ({
    addresses,
    className,
    onAddClick,
    onDeleteClick,
    isDeleting,
}) => {
    const classes = useStyles();

    return (
        <>
            <AppCard
                title="Addresses"
                className={className}
                buttons={
                    <AppButton
                        size="small"
                        variant="outlined"
                        onClick={onAddClick}
                        children="Add Address"
                    />
                }
            >
                <AppList
                    items={addresses}
                    renderItemContent={address => (
                        <p className={classes.addressContent}>
                            {getAddressComponents(address).join("\n")}
                        </p>
                    )}
                    emptyMessage="No addresses found"
                    onActionClick={onDeleteClick}
                    isActionEnabled={!isDeleting}
                    actionIcon={<DeleteIcon />}
                />
            </AppCard>
        </>
    );
}

export default CustomerAddressesView;
