import { configureStore } from '@reduxjs/toolkit';

import * as customers from './customers';
import * as bookings from './bookings';
import * as booking from './booking';
import * as packageInfo from './packageInfo';
import * as carClubPackages from './carClubPackages';

const store = configureStore({
    reducer: {
        [customers.sliceName]: customers.reducer,
        [bookings.sliceName]: bookings.reducer,
        [booking.sliceName]: booking.reducer,
        [packageInfo.sliceName]: packageInfo.reducer,
        [carClubPackages.sliceName]: carClubPackages.reducer,
    }
});

export const actions = {
    customers: customers.actions,
    bookings: bookings.actions,
    booking: booking.actions,
    packageInfo: packageInfo.actions,
    carClubPackages: carClubPackages.actions
};

export const selectors = {
    customers: customers.selectors,
    bookings: bookings.selectors,
    booking: booking.selectors,
    packageInfo: packageInfo.selectors,
    carClubPackages: carClubPackages.selectors
}

export * from './utils/useDispatchEffect';
export type { PaginationState } from './common/paginationState';

export default store;
