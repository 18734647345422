import { useState } from 'react';
import { findCarByRegistrationNumber } from '../../api/cars';
import { ApiCustomerCarSearchResult } from '../../api';
import { AppSearchBox } from '../AppSearchBox';

export interface SearchByRegistrationProps {
    onSuccess: (car: ApiCustomerCarSearchResult) => void,
}

const SearchByRegistration: React.FC<SearchByRegistrationProps> = ({
    onSuccess,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [notFound, setNotFound] = useState(false);

    const handleSearch = async () => {
        if (!searchTerm) return;

        var response = await findCarByRegistrationNumber(searchTerm);

        if (response.isError || response.statusCode === 404) {
            setNotFound(true);
            return;
        }

        setNotFound(false);
        onSuccess(response.content);
    }

    return (
        <div>
            <AppSearchBox
                placeholder="Search for a registration"
                value={searchTerm}
                onSubmit={handleSearch}
                onChange={newValue => setSearchTerm(newValue)}
                contractedWidth="auto"
                highlightOnFocus={false}
            />
            {notFound && (
                <p>A car with this registration could not be found.  Try another search or try searching by make and model.</p>
            )}
        </div>
    );
}

export default SearchByRegistration;