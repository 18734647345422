import { Route, Switch, useParams } from 'react-router-dom';

import HomePage from './pages/HomePage';
import BookingsListPage from './pages/BookingsListPage';
import CustomersListPage from './pages/CustomersListPage';
import CustomerCreatePage from './pages/CustomerCreatePage';
import CustomerViewPage from './pages/CustomerViewPage';
import BookingDetailsPage from './pages/BookingDetailsPage';

export const paths = {
    home: '/',
    logIn: '/log-in',
    bookingsList: '/bookings',
    bookingDetails: (bookingId: string|number) => `/bookings/${bookingId}`,
    customersList: '/customers',
    customerCreate: '/customers/create',
    customerView: (customerId: string|number) => `/customers/${customerId}`,
}

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route exact path={paths.home}><HomePage /></Route>
            <Route exact path={paths.bookingsList}><BookingsListPage /></Route>
            <Route exact path={paths.bookingDetails(':bookingId')}><BookingDetailsPage /></Route>
            <Route exact path={paths.customersList}><CustomersListPage /></Route>
            <Route exact path={paths.customerCreate}><CustomerCreatePage /></Route>
            <Route exact path={paths.customerView(':customerId')}><CustomerViewPage /></Route>
        </Switch>
    )
}

export const useCustomerId = () => {
    const { customerId } = useParams<{ customerId: string}>();
    return parseInt(customerId);
}

export const useBookingId = () => {
    const { bookingId } = useParams<{ bookingId: string}>();
    return parseInt(bookingId);
}

export default Routes;
