import { makeStyles } from '@material-ui/core';
import { AppCard } from '../AppCard';
import { AppButton } from '../AppButton';
import React from 'react';
import { CustomerDetailBooking } from '../../models/Customer';
import { formatDateStringDateTime } from '../../dateFormatters';
import { AppLinkList } from '../AppLinkList';

export interface CustomerCarsViewProps {
    className: string,
    bookings: CustomerDetailBooking[],
    onCreateBooking: () => void
}

const useStyles = makeStyles(() => ({
    spacer: {
        marginRight: '1rem'
    },
    flex: {
        display: 'flex'
    },
    alignRight: {
        marginLeft: 'auto'
    }
}))

const CustomerBookingsView: React.FC<CustomerCarsViewProps> = ({
    className,
    bookings,
    onCreateBooking
}) => {
    const classes = useStyles();
    return (
        <>
            <AppCard
                title="Bookings"
                className={className}
                buttons={
                    <AppButton
                        size="small"
                        variant="outlined"
                        onClick={onCreateBooking}
                        children="Create booking for customer"
                    />
                }
            >
                <AppLinkList
                    items={bookings}
                    renderItemContent={booking => (
                        <div>
                            <p className={classes.flex}>
                                <span className={classes.spacer}><b>{formatDateStringDateTime(booking.bookingDate)}</b></span>
                                <b className={classes.alignRight}>{booking.numberOfCars} {booking.numberOfCars > 1 ? 'Cars' : 'Car'}</b>
                            </p>
                            <p>{booking.address.addressLine1}, {booking.address.town}, {booking.address.postcode}</p>
                        </div>
                    )}
                    linkPath={(id: number) => `/bookings/${id}`}
                    emptyMessage="No bookings found"
                />
            </AppCard>
        </>
    );
}

export default CustomerBookingsView;