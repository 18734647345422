import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { DetailedBookingCustomerCar } from '../../models/Booking';

export type BookingCarsTableProps = {
    bookingCars: DetailedBookingCustomerCar[],
}

const BookingCarsTable: React.FC<BookingCarsTableProps> = ({
    bookingCars,
}) => {
    return (
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Car Registration</TableCell>
                    <TableCell>Car Make and Model</TableCell>
                    <TableCell>Main Package Name</TableCell>
                    <TableCell>Optional Extras</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {bookingCars.map(carPackage => (
                    <TableRow
                        key={carPackage.registrationNumber}
                    >
                        <TableCell>{carPackage.registrationNumber}</TableCell>
                        <TableCell>{carPackage.makeAndModel}</TableCell>
                        <TableCell>{carPackage.packageGroupName}</TableCell>
                        <TableCell>{
                            carPackage.optionalExtras.length > 0 ?
                                carPackage.optionalExtras.map(oe => oe.packageItemName).join(', ') :
                                'None'
                        }</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>  
    )    
}

export default BookingCarsTable;