import { styled } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import { AppButton } from "../AppButton";

const InnerAppLink = styled(Link)({
    textDecoration: 'inherit',
    color: 'inherit',
})
 
export interface AppLinkProps extends LinkProps {
    styleAsButton?: boolean,
}
 
const AppLink: React.FC<AppLinkProps> = (props) => {
    const { styleAsButton, children, ...linkProps } = props;

    if (styleAsButton) {
        return (
            <InnerAppLink {...linkProps}>
                <AppButton>
                    {children}
                </AppButton>
            </InnerAppLink>
        )
    }

    return (
        <InnerAppLink {...linkProps}>
            {children}
        </InnerAppLink>
    )
}
 
export default AppLink;