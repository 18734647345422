import { Container, Typography } from '@material-ui/core';

export interface HomePageProps {
    
}
 
const HomePage: React.FC<HomePageProps> = () => {
    return (
        <Container>
            <Typography align="center" variant="h2" component="h1">Fresh CRM</Typography>
        </Container>
    );
}
 
export default HomePage;