import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../auth';
import InnerLoginForm, { LogInFormValues } from './LoginForm';

export interface LogInFormProps {
    onSuccess: () => void,
}

const validationSchema: Yup.SchemaOf<LogInFormValues> = Yup.object().shape({
    email: Yup.string().required('Required.'),
    password: Yup.string().required('Required.'),
});

const LoginForm: React.FC<LogInFormProps> = ({
    onSuccess
}) => {
    const auth = useAuth();
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={async ({ email, password }) => {
                const authResult = await auth.logIn(email, password);
                if (authResult.isError) {
                    setErrorMessage('email / password incorrect');
                    return;
                }
                onSuccess();
            }}
            validationSchema={validationSchema}
        >
            {formikProps => (
                <InnerLoginForm
                    {...formikProps}
                    errorMessage={errorMessage}
                />
            )}
        </Formik>
    )
}

export default LoginForm;