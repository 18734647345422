import { buildUrl } from "./common/buildUrl";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import { ApiAddValeterDTO, ApiDeleteValeterDTO, ApiPreferredValeterDTO } from "./models/ApiPreferredValeters";
import * as httpClient from './common/httpClient';
import { ApiStandardResponse, unpackStandardResponse } from "./models/ApiStandardResponse";


export const getAllPreferredValeters = async (customerId: number) => {
    const url = buildUrl(`/api/admin/customerpreferredvaleter/${customerId}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPreferredValeterDTO[]>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}

export const deletePreferredValeter = async (customerPreferredValeterId: number) => {
    const url = buildUrl(`/api/admin/customerpreferredvaleter/${customerPreferredValeterId}`);
    var SoftDeleteEntityDTO: ApiDeleteValeterDTO = {
        isDeleted: true
    };
    const response = await httpClient.patchRequest<any>(url, SoftDeleteEntityDTO);
    doErrorToastIfRequired(response);
    return response;
}

export const addPreferredValeter = async (customerPreferredValeterId: number, dto: ApiAddValeterDTO) => {
    const url = buildUrl(`/api/admin/customerpreferredvaleter/${customerPreferredValeterId}`);
    const response = await httpClient.postRequest<ApiStandardResponse<ApiAddValeterDTO>>(url, dto);
    doErrorToastIfRequired(response);
    return response;
}