import { useState } from "react";
import { useDispatch } from "react-redux";
import { HttpClientFailureResponse } from "../../api";
import { deletePreferredValeter } from "../../api/preferredValeters";
import { CustomerDetailBooking } from "../../models/Customer";
import { PreferredValeter, preferredValeterCustomerListItem } from "../../models/PreferredValeter";
import { useCustomerId } from "../../Routes";
import { actions } from "../../store";
import { successToast } from "../../toast";
import { ApiErrorLoaderWrapper } from "../ApiLoaderErrorWrapper";
import { AppCard } from "../AppCard";
import CustomerPreferredValeterCreateModal from "../CustomerPreferredValeterCreateModal/CustomerPreferredValeterCreateModal";
import CustomerPreferredValetersView from "./CustomerPreferredValeterView";

export interface CustomerPreferredValetersViewContainerProps {
    valeters: PreferredValeter[],
    bookings: CustomerDetailBooking[],
    className: string,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
}

const CustomerPreferredValetersViewContainer: React.FC<CustomerPreferredValetersViewContainerProps> = ({
    valeters,
    bookings,
    className,
    isLoading,
    httpError,
}) => {
    const dispatch = useDispatch();
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const customerId = useCustomerId();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCustomerPV = async (customerPreferredValeterId: number) => {
        setIsDeleting(true);
        var response = await deletePreferredValeter(customerPreferredValeterId);

        if (!response.isError) {
            dispatch(actions.customers.deletePreferredValeterFromLoadedCustomer(customerPreferredValeterId));
            successToast('Preferred Valeter Removed')
        }

        setIsDeleting(false);
    }

    const handleDeleteClick = (customerPreferredValeterId: number) => {
        const confirmed = window.confirm('This action is irreversible, are you sure you want to remove this valeter?');

        if (confirmed) {
            handleDeleteCustomerPV(customerPreferredValeterId);
        }
    }

    const valeterList = () => {
        const valetersWithDate: preferredValeterCustomerListItem[] = valeters.map((valeter: PreferredValeter) => ({
            customerPreferredValeterId: valeter.customerPreferredValeterId,
            resourceId: valeter.resourceId,
            resourceName: valeter.resourceName,
            lastDate:
                bookings.find(({ resourceId }) => resourceId === valeter.resourceId)?.bookingDate
        }))
        return valetersWithDate
    };

    if (isLoading || httpError) {
        return (
            <AppCard
                title="Cars"
                className={className}
            >
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    return (
        <>
        <CustomerPreferredValeterCreateModal
                valeters={valeters}
                isOpen={addModalIsOpen}
                onClose={() => setAddModalIsOpen(false)}
                refetch={() => dispatch(actions.customers.getById(customerId))}
            />
            <CustomerPreferredValetersView
                onAdd={() => setAddModalIsOpen(true)}
                className={className}
                valeters={valeterList()}
                onDelete={handleDeleteClick}
                isDeleting={isDeleting}
            />
        </>
    );
}

export default CustomerPreferredValetersViewContainer;