import { parseApiCustomerAddress } from '../models/CustomerAddress';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiCreateCustomerAddressDto, ApiCustomerAddress } from './models/ApiCustomerAddress';
import { ApiStandardResponse, unpackStandardResponse } from './models/ApiStandardResponse';

export const createCustomerAddress = async (customerId: number, dto: ApiCreateCustomerAddressDto) => {
    const url = buildUrl(`api/admin/customers/${customerId}/addresses`);
    const response = await httpClient.postRequest<ApiStandardResponse<ApiCustomerAddress>>(url, dto);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, parseApiCustomerAddress);
}

export const deleteCustomerAddress = async (customerId: number, addressId: number) => {
    const url = buildUrl(`api/admin/customers/${customerId}/addresses/${addressId}`);
    const response = await httpClient.patchRequest<any>(url, { isDeleted: true });
    doErrorToastIfRequired(response);
    return response;
}