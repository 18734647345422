import * as httpClient from '../api';
import { ApiStandardResponse, unpackStandardResponse } from '../api/models/ApiStandardResponse';
import { doErrorToastIfRequired } from '../api/common/doErrorToastIfRequired';
import config from '../config';
import { FullAccountInformation } from './models/FullAccountInformation';
import { LogInResponse } from './models/LogInResponse';
import sessionStorageClient from './sessionStorageClient';

interface AuthFailureResult {
    isError: true,
    error: string,
}

interface AuthSuccessResult {
    isError: false,
    displayName: string,
}

export type AuthResult = AuthFailureResult | AuthSuccessResult;

interface CreateAuthClientOptions {
    authApiBaseUrl: string,
}

const createAuthClient = ({
    authApiBaseUrl,
}: CreateAuthClientOptions) => {
    const logIn = async (email: string, password: string): Promise<AuthResult> => {

        const response = await httpClient.postRequest<LogInResponse>(`${authApiBaseUrl}/api/account/login`, {
            email,
            password,
        });

        if (response.isError) {
            return {
                isError: true,
                error: response.message,
            };
        }

        sessionStorageClient.setAccountInformation(response.content.payload);
        sessionStorageClient.setToken(response.content.payload.token);

        return {
            isError: false,
            displayName: response.content.payload.displayName,
        };
    };

    const logOut = async (): Promise<AuthResult> => {
        const response = await httpClient.postRequest<void>(`${authApiBaseUrl}/api/account/logout`);

        if (response.isError) {
            return {
                isError: true,
                error: response.message,
            }
        }

        sessionStorageClient.clearAccountInformation();
        sessionStorageClient.clearToken();

        return {
            isError: false,
            displayName: '',
        }
    }

    const getFullAccountInformation = async () => {
        var response = await httpClient.getRequest<ApiStandardResponse<FullAccountInformation>>(`${authApiBaseUrl}/api/account/accountInformation`);

        doErrorToastIfRequired(response);

        return unpackStandardResponse(response, m => m);
    }

    const getToken = () => {
        return sessionStorageClient.getToken();
    }

    const loadSession = () => {
        const accountInformation = sessionStorageClient.getAccountInformation();
        const userName = accountInformation?.displayName;

        return {
            isLoggedIn: !!userName,
            userName,
        };
    };

    const getAccountInformation = () => {
        return sessionStorageClient.getAccountInformation();
    }

    return {
        logIn,
        logOut,
        loadSession,
        getToken,
        getAccountInformation,
        getFullAccountInformation,
    }
};

const authClient = createAuthClient({
    authApiBaseUrl: config.authApiBaseUrl,
});

export default authClient;
