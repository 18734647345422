export interface StatusGroup {
    groupName: StatusGroupName,
    statuses: BookingStatus[]
}

export type StatusGroupName = 'In Progress' | 'Urgent' | 'Processed';

export type BookingStatus = 'Enquiry' | 'Awaiting Response' | 'Response Received' | 'Not Responding' | 'Urgent Action Required' | 'Cancelled' | 'Confirmed' | 'Complete';

export const BookingStatuses: BookingStatus[] = [
    'Enquiry',
    'Awaiting Response',
    'Response Received',
    'Not Responding',
    'Urgent Action Required',
    'Cancelled',
    'Confirmed',
    'Complete'
];

export const defaultSelectedBookingStatuses: BookingStatus[] = [
    'Enquiry',
    'Awaiting Response',
    'Response Received',
    'Urgent Action Required',
    'Not Responding'
]

const statusColour = (status: BookingStatus | StatusGroupName) => {
    switch (status) {
        case 'Enquiry':
            return '#E46905';
        case 'Awaiting Response':
            return '#E46905';
        case 'Response Received':
            return '#E46905';
        case 'Urgent Action Required':
            return '#AB0D0D';
        case 'Not Responding':
            return '#AB0D0D';
        case 'Confirmed':
            return '#2B8F95';
        case 'Complete':
            return '#2B8F95';
        case 'Cancelled':
            return '#2B8F95';
        case 'In Progress':
            return '#E46905';
        case 'Urgent':
            return '#AB0D0D';
        case 'Processed':
            return '#2B8F95';
        default:
            return 'lightGrey';
    }
}

export default statusColour;
