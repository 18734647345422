import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Customer } from "../../models/Customer";
import { selectors } from "../../store";
import { PaginationState } from "../../store/common/paginationState";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  carClubStatus: {
    borderRadius: "999REM",
    padding: "0 0.4REM",
    display: "flex",
    alignItems: "center",
    marginTop: "0.5REM",
  },
  carClubStatusOn: {
    background: "#514ECB",
  },
  carClubStatusOff: {
    background: "#3c99a0",
  },
  starIcon: {
    width: "0.9REM",
    height: "0.9REM",
    imageRendering: "auto",
    marginRight: "0.2REM",
  },
  carClubStatusText: {
    color: "white",
    textAlign: "center",
    fontSize: "0.8REM",
    width: "100%",
  },
  carClubStatusTitle: {
    fontSize: "0.7REM",
    color: "grey",
    marginTop: "0.5REM",
  },
  carClubStatusPosition: {
    marginLeft: "0.7REM",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "start",
  },
}));

export interface CustomersTableProps {
  customers: Customer[];
  onRowClick: (customerId: number) => void;
  pagination: PaginationState;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSizeOptions: number[];
  onHeaderClick: (headerSort: string) => void;
}

const CustomersTable: React.FC<CustomersTableProps> = ({
  customers,
  onRowClick,
  pagination,
  setPageNumber,
  setPageSize,
  pageSizeOptions,
  onHeaderClick,
}) => {
  const activeSortBy = useSelector(selectors.customers.selectSortBy);

  const handleChangePageNumber = (_: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleChangePageSize = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    var pageSize = parseInt(event.target.value);
    setPageSize(pageSize);
  };

  const classes = useStyles();

  return (
    <Paper>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Reference</TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={() => onHeaderClick("firstName")}
                  direction="asc"
                  active={activeSortBy === "firstName"}
                >
                  First Name
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  onClick={() => onHeaderClick("lastName")}
                  direction="asc"
                  active={activeSortBy === "lastName"}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={() => onHeaderClick("email")}
                  direction="asc"
                  active={activeSortBy === "email"}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>Phone
              </TableCell>
              <TableCell>Car Club Subscriber
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow
                key={customer.id}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => onRowClick(customer.id)}
              >
                <TableCell>{customer.id}</TableCell>
                <TableCell>{customer.firstName}</TableCell>
                <TableCell>{customer.lastName}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.contactNumber}</TableCell>
                <TableCell>
                  {customer.isCarClub}
                  <div className={classes.carClubStatusPosition}>
                    <div
                        className={`${classes.carClubStatus} ${
                            customer.isCarClub
                                ? classes.carClubStatusOn
                                : classes.carClubStatusOff
                        }`}
                    >
                      {customer.isCarClub ? (
                          <img
                              alt="Star Icon"
                              src="/assets/icons/star-icon-white.png"
                              className={classes.starIcon}
                          />
                      ) : null}
                      <span className={classes.carClubStatusText}>
                        {customer.isCarClub ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={pageSizeOptions}
        count={pagination.totalCount}
        rowsPerPage={pagination.pageSize}
        page={pagination.currentPage}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
        }}
        onChangePage={handleChangePageNumber}
        onChangeRowsPerPage={handleChangePageSize}
      />
    </Paper>
  );
};

export default CustomersTable;
