import { ApiPackage, ApiPackageCategory, ApiPackageInfo, ApiPackageItem, ApiPossiblePrice } from '../api';
import { CarCategory } from './CustomerCar';

export type PackagePossiblePrices = {
    [key in CarCategory]: number;
}

export interface PackageItem {
    id: number,
    name: string,
    description: string,
    categoryName: string,
    optionalExtraPrice: number,
    optionalExtraCategoryId: number,
}

export interface Package {
    id: number,
    name: string,
    price: number,
    durationDescription: string,
    description: string,
    possiblePrices: PackagePossiblePrices,
    packageItemIds: number[],
    optionalExtraIds: number[],
}

export interface PackageCategory {
    id: number,
    title: string,
    description: string,
    imageUrl: string,
}

export interface PackageInfo {
    categories: PackageCategory[],
    packageGroups: Package[],
    packageItems: PackageItem[],
}

const parseApiPackageItem = (apiModel: ApiPackageItem): PackageItem => ({
    ...apiModel,
    id: apiModel.packageItemId,
});

const parseApiPackagePossiblePrices = (apiModel: ApiPossiblePrice[]): PackagePossiblePrices => {
    const catAPrice = apiModel.find(pp => pp.carCategory === 'CAT_A');
    const catBPrice = apiModel.find(pp => pp.carCategory === 'CAT_B');
    const catCPrice = apiModel.find(pp => pp.carCategory === 'CAT_C');

    return {
        'CAT_A': catAPrice ? catAPrice.price : 0,
        'CAT_B': catBPrice ? catBPrice.price : 0,
        'CAT_C': catCPrice ? catCPrice.price : 0,
    }
}

const parseApiPackage = (apiModel: ApiPackage): Package => ({
    ...apiModel,
    id: apiModel.packageGroupId,
    possiblePrices: parseApiPackagePossiblePrices(apiModel.possiblePrices),
});

const parseApiPackageCategory = (apiModel: ApiPackageCategory): PackageCategory => ({
    ...apiModel,
    id: apiModel.packageGroupCategoryId,
})

export const parseApiPackageInfo = ({
    packageItems,
    packageGroups,
    categories
}: ApiPackageInfo): PackageInfo => ({
    packageItems: packageItems.map(parseApiPackageItem),
    packageGroups: packageGroups.map(parseApiPackage),
    categories: categories.map(parseApiPackageCategory),
})