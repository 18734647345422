import {createHttpClientThunk} from "./common/createHttpClientThunk";
import {getCarClubPackagesByCustomerId} from "../api/carClubPackages";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {HttpClientFailureResponse} from "../api";
import {CustomerCarClubPackage} from "../models/CustomerCarClubPackage";

export const sliceName = 'carClubPackages';

const getByCustomerIdAsyncThunk = createHttpClientThunk(
    `${sliceName}/getByCustomerId`,
    getCarClubPackagesByCustomerId,
);

type SliceState = {
    carClubPackagesByCustomerId?: CustomerCarClubPackage[],
    isGettingByCustomerId: boolean,
    getByCustomerIdHttpError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    isGettingByCustomerId: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getByCustomerIdAsyncThunk.pending, state => {
            state.isGettingByCustomerId = true;
            state.getByCustomerIdHttpError = undefined;
        });
        builder.addCase(getByCustomerIdAsyncThunk.rejected, (state, {payload}) => {
            state.isGettingByCustomerId = false;
            state.getByCustomerIdHttpError = payload;
        });
        builder.addCase(getByCustomerIdAsyncThunk.fulfilled, (state, payload) => {
            state.isGettingByCustomerId = false;
            state.carClubPackagesByCustomerId = payload.payload;
        });
    }
});

export const { reducer } = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];

export const actions = {
    getByCustomerId: (customerId: number) => getByCustomerIdAsyncThunk(customerId),
};
export const selectors = {
    selectGetByCustomerIdState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isGettingByCustomerId,
            httpError: state.getByCustomerIdHttpError,
            items: state.carClubPackagesByCustomerId,
        })
    ),
};
