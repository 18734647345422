import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { HttpClientFailureResponse } from '../api';
import { getAllBookings } from '../api/bookings';
import { defaultSelectedBookingStatuses } from '../config/Statuses';
import { Booking } from '../models/Booking';
import { createHttpClientThunk } from './common/createHttpClientThunk';
import { applyApiPaginatedPayloadToState, initialPaginationState, PaginationState } from './common/paginationState';

export const sliceName = 'bookings';

const getAllAsyncThunk = createHttpClientThunk(
    `${sliceName}/getAll`,
    getAllBookings,
);

const entityAdapter = createEntityAdapter<Booking>({
    selectId: c => c.id,
});

type SliceState = {
    entityState: EntityState<Booking>,
    paginationState: PaginationState,
    isGettingAll: boolean,
    getAllHttpError?: HttpClientFailureResponse,
    selectedStatusFilterOptions: string[]
}

const initialState: SliceState = {
    entityState: entityAdapter.getInitialState(),
    paginationState: initialPaginationState,
    isGettingAll: false,
    selectedStatusFilterOptions: defaultSelectedBookingStatuses,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        toggleStatusFilterOption: (state, { payload }: PayloadAction<string>) => {
            state.paginationState.currentPage = 0;

            if (state.selectedStatusFilterOptions.includes(payload)) {
                state.selectedStatusFilterOptions = state.selectedStatusFilterOptions.filter(status => status !== payload)
            } else {
                state.selectedStatusFilterOptions.push(payload)
            }
        },
        setStatusesExclusively: (state, { payload }: PayloadAction<string[]>) => {
            state.paginationState.currentPage = 0;
            state.selectedStatusFilterOptions = payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllAsyncThunk.pending, state => {
            state.isGettingAll = true;
            state.getAllHttpError = undefined;
        });
        builder.addCase(getAllAsyncThunk.rejected, (state, { payload }) => {
            state.isGettingAll = false;
            state.getAllHttpError = payload;
        });
        builder.addCase(getAllAsyncThunk.fulfilled, (state, { payload }) => {
            state.isGettingAll = false;
            entityAdapter.removeAll(state.entityState);
            entityAdapter.setAll(state.entityState, payload.items);
            applyApiPaginatedPayloadToState(state.paginationState, payload);
        });
    }
});

export const { reducer } = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();


export const actions = {
    doSearch: () => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const { currentPage, pageSize } = state.paginationState;


        dispatch(getAllAsyncThunk({
            pageNumber: currentPage,
            pageSize,
            selectedStatusFilterOptions: state.selectedStatusFilterOptions
        }));
    },
    setPageNumber: (newPageNumber: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());

        dispatch(getAllAsyncThunk({
            pageNumber: newPageNumber,
            pageSize: state.paginationState.pageSize,
            selectedStatusFilterOptions: state.selectedStatusFilterOptions,
        }));
    },
    setPageSize: (newPageSize: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const oldPageSize = state.paginationState.pageSize;
        const oldPageNumber = state.paginationState.currentPage;
        const newPageNumber = Math.floor(oldPageNumber * oldPageSize / newPageSize)

        dispatch(getAllAsyncThunk({
            pageNumber: newPageNumber,
            pageSize: newPageSize,
            selectedStatusFilterOptions: state.selectedStatusFilterOptions,
        }));
    },
    toggleStatusFilterOption: (selectedStatusFilterOption: string) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const { pageSize } = state.paginationState;

        dispatch(slice.actions.toggleStatusFilterOption(selectedStatusFilterOption))
        const updatedState = selectSliceState(getState());

        dispatch(getAllAsyncThunk({
            pageNumber: 0,
            pageSize,
            selectedStatusFilterOptions: updatedState.selectedStatusFilterOptions
        }));
    },
    setGroupFilterOptions: (statusFilterGroupOptions: string[]) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const state = selectSliceState(getState());
        const { pageSize } = state.paginationState;

        dispatch(slice.actions.setStatusesExclusively(statusFilterGroupOptions))
        const updatedState = selectSliceState(getState());

        dispatch(getAllAsyncThunk({
            pageNumber: 0,
            pageSize,
            selectedStatusFilterOptions: updatedState.selectedStatusFilterOptions
        }));
    }
};

export const selectors = {
    selectGetAllState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isGettingAll,
            httpError: state.getAllHttpError,
            allEntities: entitySelectors.selectAll(state.entityState),
        })
    ),
    selectPaginationState: createSelector(
        selectSliceState,
        state => state.paginationState,
    ),
    selectSelectedStatusFilterOptions: createSelector(
        selectSliceState,
        state => state.selectedStatusFilterOptions,
    )
}