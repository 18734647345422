import { createStyles, fade, InputBase, makeStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

export interface AppSearchBoxProps {
    className?: string,
    placeholder: string,
    contractedWidth: string,
    onSubmit: () => void,
    onChange: (value: string) => void,
    value: string,
    highlightOnFocus?: boolean,
}

interface StyleProps {
    expandedWidth: number,
    contractedWidth: string,
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,

            marginLeft: 0,
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            fontSize: theme.typography.fontSize * 1.3
        },
        inputInput: {
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
        },
        inputInputHighlightOnFocus: {
            '&:hover, &:focus': {
                backgroundColor: fade(theme.palette.primary.main, 0.15),
            },
        }
    }),
);

const AppSearchBox: React.FC<AppSearchBoxProps> = ({
    className,
    placeholder,
    contractedWidth,
    onSubmit,
    onChange,
    value,
    highlightOnFocus = true,
}) => {
    const classes = useStyles({ expandedWidth: placeholder.length, contractedWidth });

    return (
        <div className={clsx(classes.search, className)}>
            <div className={classes.searchIcon}>
                <SearchIcon color="primary" />
            </div>
            <InputBase
                value={value}
                onChange={event => onChange(event.target.value)}
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: clsx({
                        [classes.inputInput]: true,
                        [classes.inputInputHighlightOnFocus]: highlightOnFocus,
                    }),
                }}
                inputProps={{ 'aria-label': 'search' }}
                fullWidth
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        onSubmit();
                    }
                }}
            />
        </div>
    );
}

export default AppSearchBox;