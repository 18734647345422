import React from 'react';
import { BookingStatus } from '../../config/Statuses';
import { AppModal } from '../AppModal';
import SetBookingStatusFormContainer from './SetBookingStatusFormContainer';

export type SetBookingStatusModalProps = {
    isOpen: boolean,
    onClose: () => void,
    currentStatus: BookingStatus,
    onSubmit: (newStatus: BookingStatus, confirmedDate: string | null) => Promise<any>,
    initialConfirmedDate?: Date,
}

const SetBookingStatusModal: React.FC<SetBookingStatusModalProps> = ({
    isOpen,
    onClose,
    currentStatus,
    onSubmit,
    initialConfirmedDate = new Date(), 
}) => {
    return (
        <AppModal
            isOpen={isOpen}
            onClose={onClose}
            title="Set Booking Status"
        >
            <SetBookingStatusFormContainer
                currentStatus={currentStatus}
                onSubmit={onSubmit}
                initialConfirmedDate={initialConfirmedDate}
            />
        </AppModal>
    )
}

export default SetBookingStatusModal;
