import { makeStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import { AppButton } from "../AppButton";
import { AppFormikDatePicker, AppFormikInput } from "../AppForm";
import { AppCard } from "../AppCard";

export interface CustomerDetailsFormValues {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  dateOfBirth: string | null;
}

interface OtherProps {
  className?: string;
  readonly?: boolean;
  onEditClick?: () => void;
  onCancelClick?: () => void;
  hideButtons?: boolean;
  allowEditEmail: boolean;
  onEmailClick: () => void;
}

interface CustomerStaticDetails {
  isCarClub: boolean;
}

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "grid",
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    "& > *": {
      flexGrow: 1,
    },
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      "& > *": {
        flexGrow: 1,
      },
      gridGap: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      "& > *": {
        flexGrow: 1,
      },
      gridGap: theme.spacing(3),
    },
  },
  emailButton: {
    marginLeft: "auto",
    marginTop: "12px",
    [theme.breakpoints.up("lg")]: {
      marginTop: "-30px",
    },
  },
  carClubStatus: {
    borderRadius: "999REM",
    padding: "0 0.4REM",
    display: "flex",
    alignItems: "center",
    marginTop:"0.5REM",
  },
  carClubStatusOn: {
    background: "#514ECB",
  },
  carClubStatusOff: {
    background: "#3c99a0",
  },
  starIcon: {
    width: "0.9REM",
    height: "0.9REM",
    imageRendering: "auto",
    marginRight: "0.2REM",
  },
  carClubStatusText: {
    color: "white",
    textAlign: "center",
    fontSize: "0.8REM",
    width: "100%",
  },
  carClubStatusTitle:{
    fontSize: "0.7REM",
    color: "grey",
    marginTop:"0.5REM",
  },
  carClubStatusPosition: {
    marginLeft: "0.7REM",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "start"
  }
}));

const CustomerDetailsForm: React.FC<
  OtherProps & CustomerStaticDetails & FormikProps<CustomerDetailsFormValues>
> = ({
  className,
  readonly,
  onEditClick,
  onCancelClick,
  submitForm,
  hideButtons,
  isSubmitting,
  allowEditEmail,
  onEmailClick,
                                  isCarClub,
}) => {
  const classes = useStyles();

  return (
    <AppCard
      title="Customer"
      className={className}
      buttons={
        hideButtons ? undefined : readonly ? (
          <AppButton
            size="small"
            variant="outlined"
            type="button"
            onClick={onEditClick}
            children="Edit Details"
          />
        ) : (
          <>
            <AppButton
              variant="contained"
              type="button"
              onClick={submitForm}
              showSpinner={isSubmitting}
              children="Save"
            />
            <AppButton
              variant="text"
              type="button"
              onClick={onCancelClick}
              children="Cancel"
            />
          </>
        )
      }
    >
      <div className={classes.fields}>
        <AppFormikInput
          readonly={readonly}
          type="text"
          formikName="title"
          label="Title"
        />
        <AppFormikInput
          readonly={readonly}
          type="text"
          formikName="firstName"
          label="First Name"
        />
        <AppFormikInput
          readonly={readonly}
          type="text"
          formikName="lastName"
          label="Last Name"
        />
        <AppFormikDatePicker
          readonly={readonly}
          disableFuture
          openTo="year"
          formikName="dateOfBirth"
          label="Date of Birth"
        />
        <AppFormikInput
          readonly={readonly}
          type="text"
          formikName="contactNumber"
          label="Phone"
        />
        <AppFormikInput
          readonly={readonly}
          disabled={!allowEditEmail}
          type="email"
          formikName="email"
          label="Email"
        />

        <div className={classes.carClubStatusPosition}>
        <span className={classes.carClubStatusTitle}>{"Car Club Member"}</span>
        <div
            className={`${classes.carClubStatus} ${
                isCarClub ? classes.carClubStatusOn : classes.carClubStatusOff
            }`}
        >
          {isCarClub ? (
              <img
                  alt="Star Icon"
                  src="/assets/icons/star-icon-white.png"
                  className={classes.starIcon}
              />
          ) : null}
          <span className={classes.carClubStatusText}>
            {isCarClub ? "Yes" : "No"}
          </span>
        </div>
        </div>
      </div>


      <div className={classes.emailButton}>
        <AppButton
          size="small"
          variant="outlined"
          type="button"
          onClick={onEmailClick}
          children="Email Customer"
        />
      </div>
    </AppCard>
  );
};

export default CustomerDetailsForm;
