import { CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

export interface AppCardProps {
    title: string,
    buttons?: JSX.Element,
    className?: string,
    isLoading?: boolean,
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        padding: theme.spacing(2),
    },
    title: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        '& > *:first-child': {
            flexGrow: 1,
        },
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        }
    },
    loader: {
        display: 'block',
        margin: 'auto',
    }
}))
 
const AppCard: React.FC<AppCardProps> = ({
    title,
    children,
    buttons,
    className,
    isLoading,
}) => {
    const classes = useStyles();
    return (
        <Paper className={clsx(classes.root, className)}>
            <div className={classes.title}>
                <Typography variant="h5" component="h2" color="primary">{title}</Typography>
                {buttons}
            </div>
            {isLoading  ? <CircularProgress className={classes.loader} /> : children}
        </Paper>
    );
}
 
export default AppCard;