import { Button, Menu, MenuItem } from '@material-ui/core';
import { useState } from 'react';

import { useAuth } from '../../auth';

const AccountMenu: React.FC = () => {
    const { userName, logOut } = useAuth();
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        logOut();
    }

    return (
        <div>
            <Button
                color="inherit"
                onClick={handleClick}
                children={userName}
            />
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
        </div>
    )
}

export default AccountMenu;
