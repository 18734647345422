import { InputAdornment, makeStyles, TextField, TextFieldProps, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { Ref } from 'react';

export interface AppInputProps {
    className?: string,
    name?: string,
    style?: React.CSSProperties,
    label?: string,
    type: 'text' | 'password' | 'email' | 'number',
    fullWidth?: boolean,
    readonly?: boolean,
    showError?: boolean,
    helperText?: string,
    disabled?: boolean,
    rows?: number,
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onClick?: () => void,
    value?: string | number,
    startAdornment?: string,
    endAdornment?: string,
    variant?: 'filled' | 'standard',
    inputRef?: Ref<TextFieldProps>,
    autoFocus?: boolean,
    italic?: boolean,
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '180px',
    },
    inputReadonly: {
        backgroundColor: 'white !important',
        color: `${theme.palette.text.primary} !important`,
    }
}));

const AppInput: React.FC<AppInputProps> = ({
    className,
    name,
    style,
    label,
    type,
    fullWidth = true,
    readonly,
    helperText,
    showError: isError,
    disabled,
    rows,
    onFocus,
    onChange,
    onBlur,
    onClick,
    value,
    startAdornment,
    endAdornment,
    variant = 'filled',
    inputRef,
    autoFocus,
    italic,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <TextField
            inputRef={inputRef}
            className={clsx(classes.root, className)}
            name={name}
            style={style}
            InputProps={{
                className: clsx({
                    [classes.inputReadonly]: readonly,
                }),
                style: {
                    padding: label ? undefined : theme.spacing(1),
                    fontStyle: italic ? 'italic' : undefined,
                },
                startAdornment: startAdornment ?
                    <InputAdornment position="start">{startAdornment}</InputAdornment> :
                    undefined,
                endAdornment: endAdornment ?
                    <InputAdornment position="end">{endAdornment}</InputAdornment> :
                    undefined,
            }}
            disabled={disabled || readonly}
            label={label}
            fullWidth={fullWidth}
            error={isError}
            helperText={helperText}
            variant={variant}
            type={type}
            rows={rows}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            value={value}
            multiline={!!rows}
            autoFocus={autoFocus}
        />
    );
}

export default AppInput;