import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiValeterDiary } from './models';
import { ApiStandardResponse, unpackStandardListResponse } from './models/ApiStandardResponse';
import { parseApiValeter } from '../models/ValeterDiary';
import { toUTCISOString } from '../dateFormatters';

export const getAllValeterDiariesForDate = async (date: Date, appointmentTypeIds: number[]) => {
    const startDate = new Date(date);

    const url = buildUrl(`api/admin/valeters?startDate=${toUTCISOString(startDate)}&appointmentTypeIds=${appointmentTypeIds.join(',')}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiValeterDiary[]>>(url);
    doErrorToastIfRequired(response);

    return unpackStandardListResponse(response, parseApiValeter);
}