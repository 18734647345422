import { Slider, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';
import { ValeterAppointment, ValeterDiary } from '../../models/ValeterDiary';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import { AppButton } from '../AppButton';
import AppSelect, { AppSelectOption } from '../AppForm/AppSelect';
import ValeterDiaryView from './ValeterDiaryView';

export type CreateMbhBookingFormProps = {
    valeters: ValeterDiary[],
    isLoadingValeters: boolean,
    selectedDate: Date,
    onDateChange: (newDate: Date) => void,
    onSubmit: (startTimeMinutes: number, endTimeMinutes: number, valeterId: number) => void,
}

const CreateMbhBookingForm: React.FC<CreateMbhBookingFormProps> = ({
    valeters,
    isLoadingValeters,
    selectedDate,
    onDateChange,
    onSubmit,
}) => {
    const [selectedValeterId, setSelectedValeterId] = useState(-1);
    const [selectedAppointment, setSelectedAppointment] = useState<ValeterAppointment | null>(null);
    const [selectedTimeSpan, setSelectedTimeSpan] = useState<number[]>([]);

    const selectedValeter = valeters.find(v => v.id === selectedValeterId);
    const selectedTimeSpanDuration = selectedTimeSpan[1] - selectedTimeSpan[0];
    const appointmentShortfall = !!selectedValeter ? selectedTimeSpanDuration - selectedValeter.calculatedAppointmentDuration : 0;
    const selectedTimeSpanColor = selectedTimeSpanDuration >= (!!selectedValeter ? selectedValeter.calculatedAppointmentDuration : 0) ? 'green' : 'red';

    const handleValeterChange = (valeterId: number) => {
        setSelectedAppointment(null);
        setSelectedTimeSpan([]);
        setSelectedValeterId(valeterId);
    };

    const handleDateChange = (date: MaterialUiPickersDate) => {
        setSelectedAppointment(null);
        setSelectedTimeSpan([]);
        if (date) onDateChange(date);
    }

    const handleAppointmentSelect = (appointment: ValeterAppointment) => {
        setSelectedTimeSpan([
            appointment.startTime.getHours() * 60 + appointment.startTime.getMinutes(),
            appointment.endTime.getHours() * 60 + appointment.endTime.getMinutes()
        ]);
        setSelectedAppointment(appointment);
    }

    const handleTimeSpanChange = (_: any, newValue: number | number[]) => {
        setSelectedTimeSpan(newValue as number[]);
    }

    const handleSubmit = () => {
        onSubmit(selectedTimeSpan[0], selectedTimeSpan[1], selectedValeterId);
    }

    const valeterOptions: AppSelectOption[] = valeters.map(valeter => ({
        value: valeter.id,
        label: valeter.name,
    }))

    return (
        <>
            <ApiErrorLoaderWrapper isLoading={isLoadingValeters}>
                <DatePicker style={{ marginBottom: '1rem' }}
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="dd/MM/yyyy"
                    label={"Select a date"}
                    fullWidth
                    inputVariant="filled"
                />
                <AppSelect
                    label="Valeter"
                    options={valeterOptions}
                    value={selectedValeterId}
                    onChange={handleValeterChange}
                />
                {selectedValeter && (
                    <Typography
                        align="center"
                        style={{ marginTop: '1.5em' }}
                        variant="body1"
                        component="p"
                        children={`Required duration is ${selectedValeter.calculatedAppointmentDuration}mins`}
                    />
                )}
                {selectedValeter && (
                    <>
                        <ValeterDiaryView
                            valeterDiary={selectedValeter}
                            onAppointmentSelect={handleAppointmentSelect}
                        />
                        {selectedAppointment && (
                            <>
                                <Typography
                                    align="center"
                                    style={{ marginTop: '1em', marginBottom: '3rem', color: selectedTimeSpanColor }}
                                    variant="body1"
                                    component="p"
                                    children={`${selectedTimeSpanDuration}mins${appointmentShortfall < 0 ? ` (${appointmentShortfall}mins)` : ''}`}
                                />
                                <Slider
                                    defaultValue={(selectedAppointment.startTime.getHours() * 60) + selectedAppointment.startTime.getMinutes()}
                                    step={15}
                                    marks
                                    min={(selectedAppointment.startTime.getHours() * 60) + selectedAppointment.startTime.getMinutes()}
                                    max={(selectedAppointment.endTime.getHours() * 60) + selectedAppointment.endTime.getMinutes()}
                                    valueLabelFormat={formatMinutesAsTimeOfDay}
                                    value={selectedTimeSpan}
                                    onChange={handleTimeSpanChange}
                                    valueLabelDisplay="on"
                                />
                                <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                                    <AppButton onClick={handleSubmit}>Create Mbh Booking</AppButton>
                                </div>
                            </>
                        )}
                    </>
                )}
            </ApiErrorLoaderWrapper>
        </>
    )
}

const formatMinutesAsTimeOfDay = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes - (hours * 60);

    return `${hours}:${mins < 10 ? `0${mins}` : mins}`;
}

export default CreateMbhBookingForm;