import { makeStyles, Tab } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { ApiCustomerCarSearchResult } from '../../api';
import { ApiCarMakeModelSearchResult } from '../../api/models/CarMakeModelSearch';
import { CarCategory } from '../../models/CustomerCar';
import { AppFormikInput } from '../AppForm';
import SearchByMakeAndModel from './SearchByMakeAndModel';
import SearchByRegistration from './SearchByRegistration';

export interface CustomerCarCreateFormValues {
    registrationNumber: string,
    makeAndModel: string,
    category: CarCategory,
}

interface OtherProps {

}

const useStyles = makeStyles(theme => ({
    searchBox: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(2),
    },
    tabs: {
        marginBottom: theme.spacing(2),
    }
}))

const CustomerCarCreateForm: React.FC<OtherProps & FormikProps<CustomerCarCreateFormValues>> = ({
    setFieldValue,
    validateForm,
}) => {
    const classes = useStyles();
    const [currentSearchTab, setCurrentSearchTab] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentSearchTab(newValue);
    };

    const handleRegistrationSearchSuccess = (result: ApiCustomerCarSearchResult) => {
        setFieldValue('registrationNumber', result.registrationNumber, false);
        setFieldValue('makeAndModel', result.makeAndModel, false);
        setFieldValue('category', result.category, false);
        validateForm();
    }

    const handleMakeModelSelect = (selection: ApiCarMakeModelSearchResult|null) => {
        if (selection === null) return;
        
        setFieldValue('makeAndModel', selection.carMakeModel, false);
        setFieldValue('category', selection.carCategory, false);
        validateForm();
    }

    return (
        <>
            <div className={classes.searchBox}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={currentSearchTab}
                    onChange={handleTabChange}
                    className={classes.tabs}
                >
                    <Tab label="Registration" value={0} />
                    <Tab label="Make and Model" value={1} />
                </Tabs>
                <div hidden={currentSearchTab !== 0}>
                    <SearchByRegistration
                        onSuccess={handleRegistrationSearchSuccess}
                    />
                </div>
                <div hidden={currentSearchTab !== 1}>
                    <SearchByMakeAndModel
                        onSelect={handleMakeModelSelect}
                    />
                </div>
            </div>

            <AppFormikInput type="text" formikName="registrationNumber" label="Registration Number" />
            <AppFormikInput type="text" formikName="makeAndModel" label="Make and Model" />
            <AppFormikInput type="text" formikName="category" label="Category" />
        </>
    );
}

export default CustomerCarCreateForm;