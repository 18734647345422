import { buildUrl } from "./common/buildUrl";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import * as httpClient from './common/httpClient';
import { ApiValeter } from "./models/ApiPreferredValeters";
import { ApiStandardResponse, unpackStandardListResponse } from "./models/ApiStandardResponse";

export const getAllValeters = async () => {
    const url = buildUrl('api/admin/valeters/get-all-valeters');
    const response = await httpClient.getRequest<ApiStandardResponse<ApiValeter[]>>(url);

    doErrorToastIfRequired(response);
    return unpackStandardListResponse(response, o => o);
}