import {buildUrl} from "./common/buildUrl";
import * as httpClient from "./common/httpClient";
import {ApiStandardResponse, unpackStandardListResponse} from "./models/ApiStandardResponse";
import {doErrorToastIfRequired} from "./common/doErrorToastIfRequired";
import { ApiCustomerCarClubPackage } from "./models/ApiCustomerCarClubPackage";
import { parseApiCustomerCarClubPackage, PatchCleansRemainingDTO } from "../models/CustomerCarClubPackage";

export const getCarClubPackagesByCustomerId = async (customerId: number) => {
    const url = buildUrl(`api/admin/customercarclub/${customerId}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiCustomerCarClubPackage[]>>(url, [404]);

    doErrorToastIfRequired(response);

    return unpackStandardListResponse(response, parseApiCustomerCarClubPackage);
}

export const pauseCarClubPackage = async (carClubPackageId: number) => {
    const url = buildUrl(`api/admin/customercarclub/pause/${carClubPackageId}`);
    const response = await httpClient.patchRequest<ApiStandardResponse<void>>(url, [404]);

    doErrorToastIfRequired(response);

    return response;
}

export const resumeCarClubPackage = async (carClubPackageId: number) => {
    const url = buildUrl(`api/admin/customercarclub/resume/${carClubPackageId}`);
    const response = await httpClient.patchRequest<ApiStandardResponse<void>>(url, [404]);

    doErrorToastIfRequired(response);

    return response;
}

export const cancelCarClubPackage = async (carClubPackageId: number) => {
    const url = buildUrl(`api/admin/customercarclub/cancel/${carClubPackageId}`);
    const response = await httpClient.patchRequest<ApiStandardResponse<void>>(url, [404]);

    doErrorToastIfRequired(response);

    return response;
}

export const modifyCarClubPackageCleans = async (carClubPackageId: number, cleansRemainingDTO: PatchCleansRemainingDTO) => {
    const url = buildUrl(`api/admin/customercarclub/cleans/${carClubPackageId}`);
    const response = await httpClient.patchRequest<ApiStandardResponse<void>>(url,cleansRemainingDTO);

    doErrorToastIfRequired(response);

    return response;
}
