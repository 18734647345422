import { createContext, useContext } from 'react';
import { HttpClientResponse } from '../api';
import { AuthResult } from './authClient';
import { FullAccountInformation } from './models/FullAccountInformation';
import { AccountInformation } from './sessionStorageClient';

export interface IAuthContext {
    logIn: (email:string, password: string) => Promise<AuthResult>,
    logOut: () => Promise<AuthResult>,
    userName: string | null,
    isLoggedIn: boolean,
    getToken: () => string | null,
    getAccountInformation: () => AccountInformation|undefined,
    getFullAccountInformation: () => Promise<HttpClientResponse<FullAccountInformation>>,
}

const placeholderFunc = () => { throw new Error('Not implemented.') }

export const AuthContext = createContext<IAuthContext>({
    logIn: placeholderFunc,
    logOut: placeholderFunc,
    userName: null,
    isLoggedIn: false,
    getToken: placeholderFunc,
    getAccountInformation: placeholderFunc,
    getFullAccountInformation: placeholderFunc,
});

export const useAuth = () => useContext(AuthContext);