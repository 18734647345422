import React from "react";

interface CarClubCleanIconProps {
    classes?: string
}

export const CarClubCleanIcon: React.FC<CarClubCleanIconProps> = ({ classes }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
            style={{width: "1.5rem", height: "1.5rem", color: "#52cfd2"}}
            viewBox="0 0 360 360"
            fill="currentColor"
        >
            <circle cx="180" cy="180" r="175" fill="#fff" stroke="currentColor" strokeMiterlimit="10" strokeWidth="4"/>
            <path d="M267.72,206.12c0,45-39.29,81.53-87.72,81.53s-87.79-36.48-87.79-81.53c0-51.81,48.93-137.37,87.79-137.37S267.72,156.9,267.72,206.12Z" fill="currentColor"/>
            <path d="M123.23,204.9c-2.31,31.23,16.26,55.55,59.58,55.55h2.44C142.08,247.71,136,234,123.23,204.9Z" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.25"/>
        </svg>
    );
};
