import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { drawerWidth } from './constants';
import AccountMenu from './AccountMenu';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        // hacky fix for now.
        '& > *:last-child': {
            marginLeft: 'auto',
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3),
        },
    },
    menuButtonHidden: {
        display: 'none',
    },
    title:  {
        flexGrow: 1,
        '& p': {
            display: 'inline',
        }
    },
    appBarSpacer: theme.mixins.toolbar,
}));

export interface NavBarProps {
    isOpen: boolean,
    toggleDrawer: () => void,
}

const NavBar: React.FC<NavBarProps> = ({
    isOpen,
    toggleDrawer
}) => {
    const classes = useStyles();



    return (
        <AppBar
            position="absolute"
            className={clsx(classes.appBar, isOpen && classes.appBarShift)}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    className={clsx(
                        classes.menuButton,
                        isOpen && classes.menuButtonHidden,
                    )}
                >
                    <MenuIcon />
                </IconButton>
                <AccountMenu />
            </Toolbar>

        </AppBar>
    );
}

export default NavBar;