import { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NavBar from './NavBar';
import Drawer from './Drawer';
import { colorConstants } from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mainContent: {
        backgroundColor: colorConstants.background,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(2),
        paddingTop: `calc(56px + ${theme.spacing(2)}px)`,
        [theme.breakpoints.up('sm')]: {
            paddingTop: `calc(64px + ${theme.spacing(2)}px)`,
        }
    },
}));

const MainLayout: React.FC = ({
    children,
}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes.root}>
            <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
            <NavBar isOpen={isOpen} toggleDrawer={toggleDrawer} />
            <main className={classes.mainContent}>
                {children}
            </main>
        </div>
    );
}

export default MainLayout;