import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { AppLink } from '../AppLink';

export interface DrawerItemProps {
    label: string,
    showTooltip: boolean,
    icon: React.ReactNode,
    linkPath: string,
}

const DrawerItem: React.FC<DrawerItemProps> = ({
    label,
    showTooltip,
    icon,
    linkPath,
}) => {
    return (
        <AppLink to={linkPath}>
            <ListItem button>
                <Tooltip title={showTooltip ? '' : label}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary={label} />
            </ListItem>
        </AppLink>
    );
}

export default DrawerItem;