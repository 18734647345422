import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BookIcon from '@material-ui/icons/Book';
import ShoppingCartIcon from '@material-ui/icons/Person';

import DrawerItem from './DrawerItem';
import { drawerWidth } from './constants';
import { paths } from '../../Routes';
import { useIsMobileMediaQuery } from '../../useIsMobileMediaQuery';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    linkPos: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    }
}));

export interface DrawerProps {
    isOpen: boolean,
    toggleDrawer: () => void,
}

const Drawer: React.FC<DrawerProps> = ({
    isOpen,
    toggleDrawer,
}) => {
    const isMobile = useIsMobileMediaQuery();
    const classes = useStyles();
    const location = useLocation();

    const activePath = location.pathname.includes('bookings') ? 'bookings' : 'customers';

    return (
        <MuiDrawer
            classes={{
                paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
            }}
            open={isOpen}
            variant={isMobile ? "temporary" : "permanent"}
        >
            <div className={classes.toolbarIcon}>
                <img
                    src="/assets/logos/logo.png"
                    alt="Fresh logo"
                    style={{ width: '80px', marginRight: '30px' }}
                />
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <DrawerItem
                    label="Bookings"
                    showTooltip={isOpen}
                    icon={<BookIcon
                        color={activePath === 'bookings' ? 'primary' : undefined}
                        htmlColor={activePath === 'bookings' ? undefined : 'grey'}
                    />}
                    linkPath={paths.bookingsList}
                />
                <DrawerItem
                    label="Customers"
                    showTooltip={isOpen}
                    icon={<ShoppingCartIcon
                        color={activePath === 'customers' ? 'primary' : undefined}
                        htmlColor={activePath === 'customers' ? undefined : 'grey'}
                    />}
                    linkPath={paths.customersList}
                />
            </List>
        </MuiDrawer>
    );
}

export default Drawer;