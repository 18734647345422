import {ApiCustomerCarClubPackage} from "../api/models/ApiCustomerCarClubPackage";

export interface CustomerCarClubPackageCore {
    groupName: string,
    frequency: number,
    cleansRemaining: number,
    resourceName: string,
    resourceId: number,
    status: string
}

export interface CustomerCarClubPackage extends CustomerCarClubPackageCore {
    id: number,
    carId: number,
    groupId: number
}

export interface PatchCleansRemainingDTO{
    cleansRemaining: number,
}

export const parseApiCustomerCarClubPackage =
    ({
         customerCarClubPackageId,
         customerCarId,
         packageGroupId,
         packageGroupName,
         frequency,
         cleansRemaining,
         resourceName,
         resourceId,
         subscriptionsStatus
     }: ApiCustomerCarClubPackage): CustomerCarClubPackage => ({
        id: customerCarClubPackageId,
        carId: customerCarId,
        groupId: packageGroupId,
        groupName: packageGroupName,
        frequency,
        cleansRemaining,
        resourceName,
        resourceId,
        status: subscriptionsStatus
    });
