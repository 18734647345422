import { Box, Grid, styled, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

import { LoginForm } from '../components/LoginForm'
import { colorConstants } from '../constants';
import { paths } from '../Routes';

const RootGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: colorConstants.background,
    minHeight: '100%',
    padding: theme.spacing(1),
}))

const LogoContainer = styled(Box)(({ theme }) => ({
    '& > img': {
        display: 'block',
        maxWidth: '275px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(6),
    }
}))

const FormContainer = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    padding: theme.spacing(2),
    '&> *:first-child': {
        marginBottom: theme.spacing(2),
    }
}));

const LogInPage: React.FC = () => {
    const history = useHistory();

    const handleSuccess = () => {
        history.push(paths.bookingsList);
    }

    return (
        <RootGrid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item xs={12}>
                <LogoContainer>
                    <img
                        src="/assets/logos/logo.png"
                        alt="Fresh logo"
                    />
                </LogoContainer>
                <FormContainer>
                    <Typography variant="h6" component="h1" gutterBottom color="primary">Login</Typography>
                    <LoginForm onSuccess={handleSuccess} />
                </FormContainer>
            </Grid>
        </RootGrid>
    );
}

export default LogInPage;