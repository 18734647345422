import { convertToApiUpdateCustomerDetailsDto, parseApiCustomer, parseApiCustomerDetail, UpdateCustomerDetailsDto } from '../models/Customer';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiCustomer, ApiCustomerDetail, ApiLogInAsCustomer } from './models/ApiCustomer';
import { ApiStandardResponse, unpackStandardResponse } from './models/ApiStandardResponse';
import { convertToApiCreateCustomerDto, CreateCustomerDto } from '../models/CreateCustomerDto';
import { ApiCreateCustomerResponseDto } from './models/ApiCreateCustomerResponseDto';
import { ApiPaginatedResponse, unpackPaginatedResponse } from './models/ApiPaginatedResponse';

export type GetAllCustomersOptions = {
    pageNumber: number,
    pageSize: number,
    searchTerm: string,
    sortBy: string
}

export const getAllCustomers = async ({ pageNumber, pageSize, searchTerm, sortBy }: GetAllCustomersOptions) => {
    const url = buildUrl(`api/admin/customers?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortBy=${sortBy}`);
    const response = await httpClient.getRequest<ApiPaginatedResponse<ApiCustomer>>(url);
    doErrorToastIfRequired(response);
    return unpackPaginatedResponse(response, parseApiCustomer);
}

export const getCustomerById = async (customerId: number) => {
    const url = buildUrl(`api/admin/customers/${customerId}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiCustomerDetail>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, parseApiCustomerDetail);
}

export const createCustomer = async (createCustomerDto: CreateCustomerDto) => {
    const apiCreateCustomerDto = convertToApiCreateCustomerDto(createCustomerDto);
    const url = buildUrl('api/admin/customers');
    const response = await httpClient.postRequest<ApiStandardResponse<ApiCreateCustomerResponseDto>>(url, apiCreateCustomerDto);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, o => o);
}

export const updateCustomerDetails = async (updateCustomerDetailsDto: UpdateCustomerDetailsDto) => {
    const apiDto = convertToApiUpdateCustomerDetailsDto(updateCustomerDetailsDto);
    const url = buildUrl(`api/admin/customers/${updateCustomerDetailsDto.id}`);
    const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, apiDto);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, o => o);
}

export const logInAsCustomer = async (customerId: number) => {
    const url = buildUrl(`api/admin/customers/login/${customerId}`);
    const response = await httpClient.postRequest<ApiStandardResponse<ApiLogInAsCustomer>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, o => o);
}