import { parseApiCustomerCar } from '../models/CustomerCar';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiCreateCustomerCarDTO, ApiCustomerCar } from './models/ApiCustomerCar';
import { ApiStandardResponse, unpackStandardResponse } from './models/ApiStandardResponse';
import { ApiCarMakeModelSearchResult } from './models/CarMakeModelSearch';

export const findCarByRegistrationNumber = async (registrationNumber: string) => {
    const url = buildUrl(`api/carLookup/registration-lookup/${registrationNumber}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiCustomerCar>>(url, [404]);

    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, parseApiCustomerCar);
}

export const findCarsByMakeAndModel = async (searchTerm: string) => {
    const url = buildUrl(`api/carLookup/make-model-filter/${searchTerm}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiCarMakeModelSearchResult[]>>(url);

    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, x => x);
}

export const addCustomerCar = async (customerId: number, dto: ApiCreateCustomerCarDTO) => {
    const url = buildUrl(`api/admin/customers/${customerId}/cars`);
    const response = await httpClient.postRequest<ApiStandardResponse<ApiCustomerCar>>(url, dto);

    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, parseApiCustomerCar);
}

export const deleteCustomerCar = async (customerId: number, carId: number) => {
    const url = buildUrl(`api/admin/customers/${customerId}/cars/${carId}`);
    const response = await httpClient.patchRequest<any>(url, { isDeleted: true });
    doErrorToastIfRequired(response);
    return response;
}