import { makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { FilterChip } from '../components/FilterChip';
import { BookingsTable } from '../components/BookingsTable';
import { paths } from '../Routes';
import { actions, selectors } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusCount } from '../components/StatusCount';
import { BookingStatus, BookingStatuses } from '../config/Statuses';
import { useIsMobileMediaQuery } from '../useIsMobileMediaQuery';
import { ApiStatusCountName } from '../api';

const useStyles = makeStyles<Theme, { isMobile: boolean }>(theme => ({
    header: {
        display: 'flex',
        flexWrap: props => props.isMobile ? 'wrap' : 'nowrap',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        '& > h1': {
            flexGrow: 1,
            lineHeight: 1,
        },
        '& > a': {
            marginRight: theme.spacing(1),
        },
    },

}));


export interface BookingsListPageProps {

}

const BookingsListPage: React.FC<BookingsListPageProps> = () => {
    const isMobile = useIsMobileMediaQuery();
    const classes = useStyles({ isMobile });
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedStatusFilterOptions = useSelector(selectors.bookings.selectSelectedStatusFilterOptions);

    const handleFilterParameters = (value: string) => {
        dispatch(actions.bookings.toggleStatusFilterOption(value));
    }

    const apiStatusCountFilterStatuses: {
        [key in ApiStatusCountName]: BookingStatus[]
    } = {
        'In Progress': ['Enquiry', 'Awaiting Response', 'Response Received'],
        'Urgent': ['Not Responding', 'Urgent Action Required'],
        'Processed': ['Confirmed', 'Cancelled', 'Complete'],
    };

    const handleFilterByGroup = (apiStatusCountName: ApiStatusCountName) => {
        dispatch(actions.bookings.setGroupFilterOptions(apiStatusCountFilterStatuses[apiStatusCountName]));
    }

    return (

        <>
            <div className={classes.header}>
                <StatusCount onClick={handleFilterByGroup} />
                <FilterChip
                    options={BookingStatuses}
                    selectedOptions={selectedStatusFilterOptions}
                    onChange={handleFilterParameters} />
            

            </div>
            <BookingsTable onRowClick={bookingId => history.push(paths.bookingDetails(bookingId))} />
        </>
    );
}

export default BookingsListPage;