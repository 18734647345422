import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';


export interface filterProps {
  selectedOptions: string[],
  options: string[],
  onChange: (value: string) => void
}

const FilterChip: React.FC<filterProps> = ({
  selectedOptions,
  options,
  onChange
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    onChange(option)
    handleClose();

  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        variant="contained"
      >
        Filter by Status ({selectedOptions.length}/{options.length})
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option}>
            <FormControlLabel
              label={option}
              control={
                <Checkbox onClick={() => handleMenuItemClick(option)}
                  checked={selectedOptions.includes(option)}
                />
              }
            />


          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default FilterChip;