import {useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../../config';
import { actions, selectors, useDispatchEffect } from '../../store';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import BookingsTable from './BookingsTable';

export interface BookingsTableContainerProps {
    onRowClick: (customerId: number) => void,
}

const BookingsTableContainer: React.FC<BookingsTableContainerProps> = ({
    onRowClick,
}) => {
    const dispatch = useDispatch();
    
    const {
       isLoading,
       httpError,
       allEntities: bookings,
    } = useSelector(selectors.bookings.selectGetAllState);

    const pagination = useSelector(selectors.bookings.selectPaginationState);

    const loadBookings = useCallback(() => {
        return actions.bookings.doSearch();
    }, []);

    useEffect(() => {
        dispatch(actions.packageInfo.fetch());
    }, []);
    
    useDispatchEffect(loadBookings);

    return (
        <>
            <ApiErrorLoaderWrapper
                isLoading={isLoading}
                httpError={httpError}
            >
                <BookingsTable
                    bookings={bookings}
                    onRowClick={onRowClick}
                    pagination={pagination}
                    setPageNumber={pageNumber => dispatch(actions.bookings.setPageNumber(pageNumber))}
                    setPageSize={pageSize => dispatch(actions.bookings.setPageSize(pageSize))}
                    pageSizeOptions={config.pageSizeOptions}
                />
            </ApiErrorLoaderWrapper>
        </>
    );
}

export default BookingsTableContainer;