import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiPaginatedResponse, unpackPaginatedResponse } from './models/ApiPaginatedResponse';
import { ApiBookingDetails, ApiBookingSummary, ApiCreateManualBookingDto, ApiRefundBookingDto, ApiStatusCount, ApiUpdateBookingCustomerCarsDto, ApiUpdateBookingStatusDto } from './models';
import { ApiStandardResponse, unpackStandardResponse } from './models/ApiStandardResponse';
import { DetailedBookingCustomerCar, parseApiBookingDetails, parseApiBookingSummary, parseApiStatusCountGroups } from '../models/Booking';
import { BookingStatuses, BookingStatus } from '../config/Statuses';

export type GetAllBookingsOptions = {
    pageNumber: number,
    pageSize: number,
    selectedStatusFilterOptions: string[],
}

export const getAllBookings = async ({ pageNumber, pageSize, selectedStatusFilterOptions }: GetAllBookingsOptions) => {
    //temporary fix, API currently returns no results when no statuses are provided
    const cleanStatuses = selectedStatusFilterOptions.length === 0 ? BookingStatuses : selectedStatusFilterOptions
    const freshStatusQuery = cleanStatuses.map(s => `&freshStatuses=${s}`).join('')
    const url = buildUrl(`api/admin/allbookings?pageNumber=${pageNumber}&pageSize=${pageSize}${freshStatusQuery}`);
    const response = await httpClient.getRequest<ApiPaginatedResponse<ApiBookingSummary>>(url);
    doErrorToastIfRequired(response);
    return unpackPaginatedResponse(response, parseApiBookingSummary);
}

export const getBookingById = async (bookingId: number) => {
    const url = buildUrl(`api/admin/allbookings/${bookingId}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiBookingDetails>>(url);
    doErrorToastIfRequired(response);

    // bookingId should be returned from Api but this is a workaround as it is not at the moment.
    if (!response.isError) {
        response.content.payload.bookingId = bookingId;
    }

    return unpackStandardResponse(response, parseApiBookingDetails);
}

export const createManualMbhBooking = async (createManualBookingDto: ApiCreateManualBookingDto) => {
    const url = buildUrl('api/admin/allBookings');
    const response = await httpClient.postRequest<ApiStandardResponse<any>>(url, createManualBookingDto);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, o => o);
}

export const getBookingStatusCount = async () => {
    const url = buildUrl(`api/admin/allbookings/statusCount`)
    const response = await httpClient.getRequest<ApiStandardResponse<ApiStatusCount[]>>(url);

    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, parseApiStatusCountGroups);
}

export const updateBookingStatus = async (bookingId: number, status: BookingStatus, confirmedDate: string | null) => {
    const url = buildUrl(`api/admin/allbookings/enquirystatus/${bookingId}`);
    var dto: ApiUpdateBookingStatusDto = {
        enquiryStatus: status,
        confirmedDate: confirmedDate || undefined,
    };
    const response = await httpClient.patchRequest<any>(url, dto);
    doErrorToastIfRequired(response);
    return response;
}

export const updateBookingRefundStatus = async (bookingId: number, isRefunded: boolean) => {
    const url = buildUrl(`api/admin/allbookings/refund/${bookingId}`);
    const dto: ApiRefundBookingDto = {
        isRefunded: isRefunded,
    };
    const response = await httpClient.patchRequest<any>(url, dto);
    doErrorToastIfRequired(response);
    return response;
}


export const updateBookingCars = async (bookingId: number, bookingCars: DetailedBookingCustomerCar[], overridePrice?: number) => {

    const dto: ApiUpdateBookingCustomerCarsDto = {
        bookingCustomerCars: bookingCars,
        overridePrice: (overridePrice && overridePrice > 0) ? overridePrice : undefined,
    }

    const url = buildUrl(`api/admin/allbookings/customercars/${bookingId}`);
    const response = await httpClient.patchRequest<any>(url, dto);

    doErrorToastIfRequired(response);
    return response;
}

export const updateBookingNotes = async (bookingId: number, notes: string) => {
    const url = buildUrl(`api/admin/allbookings/notes/${bookingId}`);

    const response = await httpClient.patchRequest<{}>(url, { notes });

    doErrorToastIfRequired(response);

    return response;
}