const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const prependZero = (number: number): string => {
    if(number < 10) {
      return `0${number}`;
    } else {
      return number.toString();
    }
  }

  const formatTo12hrClock = (number: number): number => {
    if(number > 12) {
      return (number - 12);
    } else {
      return number;
    }
  }

  export const formatDateStringDateOnly = (dateString: string): string => {
      try {
        const date = new Date(dateString);
        
        return formatDateDateOnly(date);
      }
      catch (error) {
          console.error('Error formatting date from string:', error);
          throw error;
      }
  }

  export const formatDateStringDateTime = (dateString: string): string => {
      try {
        const date = new Date(dateString);
        
        return formatDateDateTime(date);
      }
      catch (error) {
          console.error('Error formatting date time from string:', error);
          throw error;
      }
  }

  export const formatDateDateTime = (date: Date): string => {
    try {
        return `${formatDateDateOnly(date)} ${formatTimeOnly(date)}`;
      }
      catch (error) {
          console.error('Error formatting date time from date:', error);
          throw error;
      }
  }

  export const formatDateDateOnly = (date: Date): string => {
    try {
        const monthString = months[date.getMonth()];
        return `${date.getDate()}-${monthString.substring(0, 3)}-${date.getFullYear()}`;
      }
      catch (error) {
          console.error('Error formatting date from date:', error);
          throw error;
      }
  }

  export const formatTimeOnly = (date: Date): string => {
    try {
      const hour = formatTo12hrClock(date.getHours()),
            minute = prependZero(date.getMinutes()),
            hourForPeriod = prependZero(date.getHours()),
            period = parseInt(hourForPeriod) >= 12 ? 'pm' : 'am';

      return `${hour}:${minute}${period}`;
    } catch (error) {
      console.log('Error formatting time from date: ', error);
      throw error;
    }
  }

  export const toUTCISOString = (date: Date): string => {
    const year = date.getFullYear(),
          month = date.getMonth(),
          day = date.getDate();

    let d = new Date(Date.UTC(year, month, day));

    return d.toISOString();
  }