import { parseApiPackageInfo } from '../models/PackageInfo';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient';
import { ApiPackageInfo } from './models';
import { ApiStandardResponse, unpackStandardResponse } from './models/ApiStandardResponse';

export const getAllPackageInfo = async () => {
    const url = buildUrl(`api/package-information`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPackageInfo>>(url);
    
    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, parseApiPackageInfo);
}

export const getPackageInfoByPostcode = async (postcode: string) => {
    const url = buildUrl(`api/package-information/${postcode}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPackageInfo>>(url);
    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, parseApiPackageInfo);
}