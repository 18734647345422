import { HttpClientResponse } from '../common/httpClient';
import { ApiStandardResponse } from './ApiStandardResponse';

export interface ApiPaginatedPayload<T> {
    currentPage: number,
    from: number,
    items: T[],
    pageSize: number,
    to: number,
    totalCount: number,
    totalPages: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}

export interface ApiPaginatedResponse<T> extends ApiStandardResponse<ApiPaginatedPayload<T>> {
    
}

export function unpackPaginatedResponse<TApiModel, TResponseModel>(
    response: HttpClientResponse<ApiPaginatedResponse<TApiModel>>,
    modelBuilder: (apiModel: TApiModel) => TResponseModel,
): HttpClientResponse<ApiPaginatedPayload<TResponseModel>> {
    if (response.isError) {
        return response;
    }

    return {
        ...response,
        content: {
            ...response.content.payload,
            items: response.content.payload.items.map(modelBuilder),
        },
    };
};