import { IconButton, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiErrorLoaderWrapper } from '../../components/ApiLoaderErrorWrapper';
import { AppCard } from '../../components/AppCard';
import { AppInput } from '../../components/AppForm';
import { CustomerAddress } from '../../models/CustomerAddress';
import { actions, selectors } from '../../store';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { updateBookingNotes } from '../../api/bookings';
import AppIconButton from '../../components/AppIconButtton';
import { successToast } from '../../toast';

export interface BookingNotesCardProps {
    cardClassName?: string,
}

const useStyles = makeStyles(theme => ({
    notesFields: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        '& > *': {
            flexGrow: 1,
        },
        gridGap: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            '& > *': {
                flexGrow: 1,
            },
            gridGap: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            '& > *': {
                flexGrow: 1,
            },
            gridGap: theme.spacing(3),
        }
    },
}));

const getAddressComponents = (address: CustomerAddress) => {
    return [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.postcode,
        address.town
    ]
        .filter(c => !!c);
}

const BookingNotesCard: React.FC<BookingNotesCardProps> = ({
    cardClassName,
}) => {
    const {
        isLoading,
        httpError,
        booking,
    } = useSelector(selectors.booking.selectApiState);
    const bookingNotes = useSelector(selectors.booking.selectNotes);
    const dispatch = useDispatch();
    
    const [isEditingNotes, setIsEditingNotes] = useState(false);
    const [isSubmittingNotes, setIsSubmittingNotes] = useState(false);

    useEffect(() => {
        if (isEditingNotes) {
            notesInputRef.current.focus();
        }
    }, [isEditingNotes]);

    const classNames = useStyles();
    const notesInputRef = createRef<any>();

    const addressComponents = booking ? getAddressComponents(booking.address) : [];

    const handleEditNotesClick = async () => {
        if (!booking) return;

        if (isEditingNotes) {
            setIsSubmittingNotes(true);
            const response = await updateBookingNotes(booking.id, bookingNotes); 

            setIsSubmittingNotes(false);

            if (!response.isError) {
                setIsEditingNotes(false);
                successToast('Notes updated.')
            }
        } else {
            if (!notesInputRef.current) return;
            
            setIsEditingNotes(true);
        }
    }

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.booking.setBookingNotes(event.target.value));
    }

    return (
        <AppCard title="Notes" className={cardClassName}>
            <ApiErrorLoaderWrapper
                isLoading={isLoading}
                httpError={httpError}
            >
                {booking && (
                    <div className={classNames.notesFields}>
                        <AppInput readonly label="Booking Address" type="text" rows={addressComponents.length} value={addressComponents.join(',\n')} />
                        <AppInput readonly label="Customer's Additional Information" type="text" value={booking.customerAdditionalComments || ''} rows={4} />
                        <div style={{ display: 'flex' }}>
                            <TextField
                                inputRef={notesInputRef}
                                label="Notes"
                                variant="standard"
                                disabled={!isEditingNotes || isSubmittingNotes}
                                type="text"
                                value={bookingNotes}
                                multiline
                                rows={4}
                                autoFocus
                                onChange={handleNotesChange}
                                style={{
                                    flexGrow: 1,
                                }}
                            />
                            <AppIconButton
                                onClick={handleEditNotesClick}
                                showSpinner={isSubmittingNotes}
                                color={isEditingNotes ? 'primary' : undefined}
                            >
                                {isEditingNotes ? <SaveIcon /> : <EditIcon />}
                            </AppIconButton>
                        </div>
                    </div>
                )}
            </ApiErrorLoaderWrapper>
        </AppCard>
    );
}

export default BookingNotesCard;