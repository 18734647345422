import { colors, makeStyles, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Booking } from '../../models/Booking';
import { formatDurationStringFromMinutes } from '../../time';
import { StatusChip } from '../StatusChip';

const useStyles = makeStyles((theme) => ({

    carClubIdBack:{
        background: '#514ECB',
        borderRadius: '999REM',
        padding:'0 0.2REM',
        display: 'flex',
        maxWidth:'3REM',
        alignItems:'center'
    },
    starIcon:{
        width: '0.9REM',
        height: '0.9REM',
        imageRendering: 'auto'
    },
    carClubIdText:{
        color: 'white',
        textAlign: 'center',
        marginLeft:'0.2REM',
        fontSize:'0.8REM'
    }


}));

export interface BookingsTableRowProps {
    booking: Booking,
    onRowClick: (bookingId: number) => void,
}

const BookingsTableRow: React.FC<BookingsTableRowProps> = ({
    booking,
    onRowClick,
}) => {
    const priceToDisplay = (booking.overridePrice || booking.totalCost).toFixed(2);
    const decor = useStyles();

    return (

        <TableRow
            hover
            style={{ cursor: 'pointer' }}
            onClick={() => onRowClick(booking.id)}
        >

            {booking.customerCarClubPackageId
                ?<TableCell>
                    <div className={decor.carClubIdBack}>
                        <img
                            src="/assets/icons/star-icon-white.png"
                            alt="Star Icon"
                            className={decor.starIcon}
                        />
                        <span className={decor.carClubIdText}>{booking.id}</span>
                    </div>
                </TableCell>
                :<TableCell>{booking.id}</TableCell>
            }
            <TableCell>{booking.name}</TableCell>
            <TableCell>{booking.area}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>{booking.date}</TableCell>
            <TableCell>{booking.time === '' ? '-' : booking.time}</TableCell>
            <TableCell>{booking.status === 'Confirmed' ? formatDurationStringFromMinutes(booking.durationMinutes || 0) : '-'}</TableCell>
            <TableCell>
                <span style={{
                    fontStyle: booking.overridePrice ? 'italic' : undefined,
                    whiteSpace: 'nowrap'
                }}>
                    £{priceToDisplay}{booking.overridePrice ? ' !' : ''}
                </span>
            </TableCell>
            <TableCell>{booking.valeter}</TableCell>
            <TableCell><StatusChip status={booking.status} className={'white'} /></TableCell>
        </TableRow>
    );
}

export default BookingsTableRow;
