import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { createCustomer } from '../../api/customers';
import { paths } from '../../Routes';
import { CustomerDetailsFormComponent, CustomerDetailsFormValues, customerDetailsValidationFields } from '../CustomerDetailsForm';
import { CustomerAddressFormComponent, CustomerAddressFormValues, customerAddressValidationFields } from '../CustomerAddressForm';
import { AppButton } from '../AppButton';
import { AppCard } from '../AppCard';

export interface CustomerCreateFormProps {
    className?: string,
}

interface CustomerCreateFormValues extends CustomerDetailsFormValues, CustomerAddressFormValues {

}

const validationSchema: Yup.SchemaOf<CustomerCreateFormValues> = Yup.object().shape({
    ...customerDetailsValidationFields,
    ...customerAddressValidationFields,
})

const initialValues: CustomerCreateFormValues = {
    id: -1,
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    contactNumber: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postcode: '',
    town: '',
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minMax(0, 1fr))',
        gridGap: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1600px',
    },
    customerDetails: {
        gridColumn: '1 / span 2',
    },
    addressFields: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        '& > *': {
            flexGrow: 1,
        },
        gridGap: theme.spacing(2),
    },
    address: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '1 / span 1'
        }
    },
    buttons: {
        gridColumn: '1 / span 1',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const CustomerCreateForm: React.FC<CustomerCreateFormProps> = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                var result = await createCustomer({
                    ...values,
                    dateOfBirth: values.dateOfBirth,
                });
                if (!result.isError) {
                    history.push(paths.customerView(result.content.customerId));
                }
            }}
            validationSchema={validationSchema}
        >
            {formikProps => {
                const { isSubmitting } = formikProps;
                return (
                    <Form className={classes.root}>
                        <CustomerDetailsFormComponent
                            {...formikProps as any}
                            className={classes.customerDetails}
                            hideButtons
                            allowEditEmail
                        />
                        <AppCard title="Address" className={classes.address}>
                            <div className={classes.addressFields}>
                                <CustomerAddressFormComponent
                                    {...formikProps as any}
                                />
                            </div>
                        </AppCard>
                        <div className={classes.buttons}>
                            <AppButton showSpinner={isSubmitting} type="submit" size="large">Create</AppButton>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CustomerCreateForm;