import { makeStyles, Modal, Paper, Typography } from "@material-ui/core";

export interface AppModalProps {
    isOpen: boolean,
    onClose: () => void,
    title: string,
}

const useStyles = makeStyles(theme => ({
    root: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        margin: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    content: {
        boxShadow: theme.shadows[5],
        margin: 'auto',
        outline: 'none',
        padding: theme.spacing(2, 4, 3),
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        maxWidth: '95%',
        maxHeight: '90%',
        overflowY: 'auto',
    },
}));

const AppModal: React.FC<AppModalProps> = ({
    isOpen,
    onClose,
    children,
    title,
}) => {
    const classNames = useStyles();

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className={classNames.root}
        >
            <Paper className={classNames.content}>
                <Typography
                    align="center"
                    className={classNames.title}
                    color="primary"
                    variant="h4"
                    component="h1"
                    children={title}
                />
                {children}
            </Paper>
        </Modal>
    );
}

export default AppModal;