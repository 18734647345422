import Chip from '@material-ui/core/Chip'
import statusColour, { BookingStatus } from '../../config/Statuses';

export interface StatusChipProps {
    status: BookingStatus,
    className?: string,
    onClick?: () => void,
}

const StatusChip: React.FC<StatusChipProps> = ({
    status,
    className,
    onClick,
}) => {
    return (
        <Chip
            className={className}
            label={status}
            style={{ backgroundColor: statusColour(status), color: 'white'}}
            onClick={onClick}
        />
    )
}

export default StatusChip;