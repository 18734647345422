import React, { useState } from 'react';

import { AppCard } from '../AppCard';
import { HttpClientFailureResponse } from '../../api';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import { useCustomerId } from '../../Routes';
import CustomerCarsView from '././CustomerCarsView';
import { CustomerCar } from '../../models/CustomerCar';
import { CustomerCarCreateModal } from '../CustomerCarCreateModal';
import { successToast } from '../../toast';
import { actions } from '../../store';
import { deleteCustomerCar } from '../../api/cars';
import { useDispatch } from 'react-redux';

export interface CustomerCarsViewProps {
    cars: CustomerCar[],
    className: string,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
}

const CustomerCarsViewContainer: React.FC<CustomerCarsViewProps> = ({
    cars,
    className,
    isLoading,
    httpError,
}) => {
    const dispatch = useDispatch();
    const customerId = useCustomerId();
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteCustomerCar = async (carId: number) => {
        setIsDeleting(true);
        var response = await deleteCustomerCar(customerId, carId);

        if (!response.isError) {
            dispatch(actions.customers.deleteCarFromLoadedCustomer(carId));
            successToast('Car deleted.')
        }

        setIsDeleting(false);
    }

    const handleDeleteClick = (carId: number) => {
        const confirmed = window.confirm('This action is irreversible, are you sure you want to delete this car?');
        
        if (confirmed) {
            handleDeleteCustomerCar(carId);
        }
    }

    if (isLoading || httpError) {
        return (
            <AppCard
                title="Cars" 
                className={className}
            >
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    return (
        <>
            <CustomerCarCreateModal
                isOpen={addModalIsOpen}
                onClose={() => setAddModalIsOpen(false)}
            />
            <CustomerCarsView
                onAdd={() => setAddModalIsOpen(true)}
                className={className}
                cars={cars}
                onDelete={handleDeleteClick}
                isDeleting={isDeleting}
            />
        </>
    );
}

export default CustomerCarsViewContainer;