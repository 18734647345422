import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { HttpClientFailureResponse } from '../../api';
import { updateCustomerDetails } from '../../api/customers';
import { successToast } from '../../toast';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import { AppCard } from '../AppCard';
import CustomerDetailsForm, { CustomerDetailsFormValues } from './CustomerDetailsForm';

export interface CustomerDetailsProps {
    className?: string,
    initialValues?: CustomerDetailsFormValues,
    readonly?: boolean,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
    allowEditEmail?: boolean,
    isCarClub: boolean
}


export const customerDetailsValidationFields = {
    id: Yup.number().required(),
    title: Yup.string()
        .required('Required.')
        .max(10),
    firstName: Yup.string().required('Required.'),
    lastName: Yup.string().required('Required.'),
    contactNumber: Yup.string().required('Required.'),
    email: Yup.string()
        .required('Required.')
        .email('Please enter a valid email address.'),
    dateOfBirth: Yup.string().nullable().default(null),
}

const validationSchema: Yup.SchemaOf<CustomerDetailsFormValues> = Yup.object().shape(
    customerDetailsValidationFields,
)

const defaultInitialValues: CustomerDetailsFormValues = {
    id: -1,
    title: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    dateOfBirth: null,
}

const CustomerDetailsFormContainer: React.FC<CustomerDetailsProps> = ({
    className,
    initialValues = defaultInitialValues,
    readonly,
    isLoading,
    httpError,
    allowEditEmail = false,
    isCarClub
}) => {
    const [isEditing, setIsEditing] = useState(false);

    if (isLoading || httpError) {
        return (
            <AppCard title="Customer" className={className}>
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    const emailCustomer = () => {

        const mailto_link = 'mailto:' + initialValues.email;
        window.open(mailto_link, 'emailWindow');
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                var response = await updateCustomerDetails({
                    ...values,
                    dateOfBirth: values.dateOfBirth,
                });
                if (response.isError) return;
                successToast('Customer saved.');
                setIsEditing(false);
            }}
            validationSchema={validationSchema}
        >
            {formikProps => (
                <Form className={className}>
                    <CustomerDetailsForm
                        {...formikProps}
                        allowEditEmail={allowEditEmail}
                        readonly={!isEditing || readonly}
                        onEditClick={() => setIsEditing(true)}
                        onCancelClick={() => setIsEditing(false)}
                        onEmailClick={() => emailCustomer()}
                        isCarClub={isCarClub}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default CustomerDetailsFormContainer;
