import { CustomerCar } from '../../models/CustomerCar';
import { AppList } from '../AppList';
import { AppCard } from '../AppCard';
import React from 'react';
import { AppButton } from '../AppButton';
import DeleteIcon from "@material-ui/icons/Delete";

export interface CustomerCarsViewProps {
    className: string,
    cars: CustomerCar[],
    onAdd: () => void,
    onDelete: (carId: number) => void,
    isDeleting: boolean,
}

const CustomerCarsView: React.FC<CustomerCarsViewProps> = ({
    className,
    cars,
    onAdd,
    onDelete,
    isDeleting,
}) => {
    return (
        <>
            <AppCard
                title="Cars"
                className={className}
                buttons={
                    <AppButton
                        size="small"
                        variant="outlined"
                        onClick={onAdd}
                        children="Add Car"
                    />
                }
            >
                <AppList
                    items={cars}
                    emptyMessage="No cars found"
                    renderItemContent={car => (
                        <p>{car.registrationNumber}, {car.makeAndModel}</p>
                    )}
                    onActionClick={onDelete}
                    isActionEnabled={!isDeleting}
                    actionIcon={<DeleteIcon />}
                />
            </AppCard>
        </>
    );
}

export default CustomerCarsView;
