import React from 'react';

import config from '../../config';
import { AppCard } from '../AppCard';
import { HttpClientFailureResponse } from '../../api';
import { CustomerDetailBooking } from '../../models/Customer';
import { ApiErrorLoaderWrapper } from '../ApiLoaderErrorWrapper';
import CustomerBookingsView from '././CustomerBookingsView';
import { logInAsCustomer } from '../../api/customers';

export interface CustomerCarsViewProps {
    bookings: CustomerDetailBooking[],
    className: string,
    isLoading: boolean,
    httpError?: HttpClientFailureResponse,
    customerId: number
}

const CustomerBookingsViewContainer: React.FC<CustomerCarsViewProps> = ({
    bookings,
    className,
    isLoading,
    httpError,
    customerId
}) => {

    const handleLogInAsCustomer = async () => {
        if(customerId) {
            const response = await logInAsCustomer(customerId);
            if(!response.isError) {
                const url = `${config.bookingClientBaseUrl}/logInAs?token=${response.content.token}&displayName=${response.content.displayName}&email=${response.content.email}&customerId=${response.content.customerId}`;

                const newTab = window.open(url, '_blank');
                newTab?.focus();
            }
        }
    }

    if (isLoading || httpError) {
        return (
            <AppCard
                title="Bookings" 
                className={className}
            >
                <ApiErrorLoaderWrapper
                    isLoading={isLoading}
                    httpError={httpError}
                />
            </AppCard>
        )
    }

    return (
        <>
            <CustomerBookingsView
                className={className}
                bookings={bookings}
                onCreateBooking={() => handleLogInAsCustomer()}
            />
        </>
    );
}

export default CustomerBookingsViewContainer;