import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';

export interface ComboBoxQueryResult<T> {
    label: string,
    value: string,
    item: T,
}

const useStyles = makeStyles(() => ({
    comboBox: {
        position: 'relative',
        backgroundColor: '#f6f6f6',
        cursor: 'text'
    }
}));

interface AppComboBoxProps<T> {
    doSearch: (query: string) => Promise<ComboBoxQueryResult<T>[]>,
    onSelect: (selected: T|null) => void,
    placeholder: string,
}

function AppComboBox<T>({
    doSearch,
    onSelect,
    placeholder,
}: AppComboBoxProps<T>) {
    const classes = useStyles();
    const handleLoadOptions = useCallback(
        debounce((inputValue: string, callback) => {
            onSelect(null);
            doSearch(inputValue).then(options => callback(options))
        }, 300),
        []
    );

    const handleChange = (option: any) => {
        onSelect(option.item);
    }
    
    return (
        <div className={classes.comboBox}>
            <SearchIcon color="primary" style={{ position: 'absolute', zIndex: 200, top: '12px', left: '15px' }} />
            <AsyncSelect
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                styles={{
                    control: () => ({
                        border: 'none',
                        zIndex: 1000
                    }),
                    input: () => ({
                        border: 'none',
                        borderRadius: '4px',
                        display: 'flex',
                        cursor: 'text',
                        fontSize: '18.2px',
                        padding: '8px 8px 8px 0px',
                        paddingLeft: 'calc(1em + 32px)',
                    }),
                    menu: () => ({
                        backgroundColor: '#f6f6f6',
                        border: '1px solid #AAA',
                        borderRadius: '0',
                        cursor: 'pointer',
                        position: 'absolute',
                        left: 0,
                        width: '100%',
                        zIndex: 9999,
                    }),
                    loadingIndicator: () => ({
                        display: 'none'
                    }),
                    menuPortal: provided => ({
                        ...provided,
                        zIndex: 9999
                    }),
                    placeholder: () => ({
                        color: '#AAA',
                        fontSize: '18.2px',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        padding: '8px 8px 8px 0px',
                        paddingLeft: 'calc(1em + 32px)',
                    }),
                    singleValue: () => ({
                        paddingLeft: 'calc(1em + 32px)',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        whiteSpace: 'nowrap',
                    }),
                    option: (() => ({
                        borderRadius: '0px',
                        cursor: 'pointer',
                        padding: '0.75em 1.25em',
                        borderBottom: '1px solid #ababab',
                    })) as any
                }}
                loadOptions={handleLoadOptions}
                onChange={handleChange}
                placeholder={placeholder}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                menuPlacement="auto"
                noOptionsMessage={({inputValue}) => !inputValue ? "Enter search term" : "No results found"} 
            />
        </div>
    )
}

export default AppComboBox;