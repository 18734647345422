import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { Booking } from '../../models/Booking';
import { PaginationState } from '../../store/common/paginationState';
import BookingsTableRow from './BookingsTableRow';

export interface CustomersTableProps {
    bookings: Booking[],
    onRowClick: (customerId: number) => void,
    pagination: PaginationState,
    setPageNumber: (pageNumber: number) => void,
    setPageSize: (pageSize: number) => void,
    pageSizeOptions: number[],
}

const BookingsTable: React.FC<CustomersTableProps> = ({
    bookings,
    onRowClick,
    pagination,
    setPageNumber,
    setPageSize,
    pageSizeOptions,
}) => {

    const handleChangePageNumber = (_: any, pageNumber: number) => {
        setPageNumber(pageNumber);
    }

    const handleChangePageSize = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var pageSize = parseInt(event.target.value);
        setPageSize(pageSize);
    }

    return (
        <Paper>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Booking ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Area</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Timeslot</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Valeter</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookings.map((booking) => (
                            <BookingsTableRow
                                key={booking.id}
                                booking={booking}
                                onRowClick={onRowClick}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={pageSizeOptions}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.currentPage}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    // native: true,
                }}
                onChangePage={handleChangePageNumber}
                onChangeRowsPerPage={handleChangePageSize}
            />
        </Paper>
    );
}

export default BookingsTable;