import { buildUrl } from "./common/buildUrl";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import { ApiStandardResponse, unpackStandardResponse } from "./models/ApiStandardResponse";
import * as httpClient from './common/httpClient'

export const checkPostcode = async (postcode: string, allow404: boolean = false) => {
    const url = buildUrl(`api/postcode/postcodecoverage`);
    const payload = { postcode };
    const allowedStatuses = allow404 ? [404] : [];
    const response = await httpClient.postRequest<ApiStandardResponse<string>>(url, payload, allowedStatuses);
    doErrorToastIfRequired(response);
    if (response.isError) return response;

    return unpackStandardResponse(response, m => m);
}