import { FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { Formik, FormikErrors } from 'formik';
import React from 'react';
import { BookingStatus, BookingStatuses } from '../../config/Statuses';
import { AppButton } from '../AppButton';
import { AppFormikDateTimePicker } from '../AppForm';

export interface SetBookingModalFormContainerProps {
    currentStatus: BookingStatus,
    onSubmit: (newStatus: BookingStatus, confirmedDate: string | null) => Promise<any>,
    initialConfirmedDate: Date,
}

const useStyles = makeStyles(theme => ({
    form: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
}));

interface SetBookingStatusFormValues {
    newStatus: BookingStatus | null,
    confirmedDate: Date | null,
}

const validateSetBookingFormValues = ({ newStatus, confirmedDate }: SetBookingStatusFormValues): FormikErrors<SetBookingStatusFormValues> | void => {
    if (!newStatus) {
        return {
            newStatus: 'You must select a status.'
        }
    }
    if (newStatus as BookingStatus === 'Confirmed' && !confirmedDate) {
        return {
            confirmedDate: 'A confirmed date must be provided when status is being set to confirmed.',
        }
    }
}

const SetBookingStatusFormContainer: React.FC<SetBookingModalFormContainerProps> = ({
    currentStatus,
    onSubmit,
    initialConfirmedDate,
}) => {
    const classNames = useStyles();
    const availableStatuses: BookingStatus[] = currentStatus === 'Confirmed' ?
        ['Enquiry', 'Cancelled', 'Complete'] :
        BookingStatuses
            .filter(s => s !== currentStatus && s !== 'Urgent Action Required' && s !== 'Not Responding' && s !== 'Complete');

    const initialValues: SetBookingStatusFormValues = {
        newStatus: null,
        confirmedDate: initialConfirmedDate,
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validateSetBookingFormValues}
            onSubmit={async ({ newStatus, confirmedDate }) => {
                const dateToSubmit = (newStatus === 'Confirmed' && confirmedDate) ? confirmedDate.toISOString() : null
                await onSubmit(newStatus as BookingStatus, dateToSubmit);
            }}
        >
            {formikProps => {
                const statusFieldProps = formikProps.getFieldProps('newStatus');
                const statusFieldMeta = formikProps.getFieldMeta('newStatus');
                let selectedNewStatus: BookingStatus | null = null;

                if (formikProps.values.newStatus !== null) selectedNewStatus = formikProps.values.newStatus;

                return (
                    <div className={classNames.form}>
                        <FormControl component="fieldset" {...statusFieldProps} fullWidth disabled={formikProps.isSubmitting}>
                            <FormLabel component="legend">New Status</FormLabel>
                            <RadioGroup name="newStatus">
                                {availableStatuses.map(status => (
                                    <FormControlLabel key={status} value={status} control={<Radio />} label={status} />
                                ))}
                            </RadioGroup>
                            {statusFieldMeta.touched && statusFieldMeta.error && (
                                <FormHelperText error={!!statusFieldMeta.error}>{statusFieldMeta.error}</FormHelperText>
                            )}
                        </FormControl>
                        {selectedNewStatus === 'Confirmed' &&
                            <>
                                <AppFormikDateTimePicker
                                    label="Confirmed Date"
                                    formikName="confirmedDate"
                                    fullWidth={false}
                                    disabled={formikProps.isSubmitting}
                                />
                                <br />
                            </>
                        }
                        <AppButton showSpinner={formikProps.isSubmitting} onClick={() => formikProps.submitForm()}>Save</AppButton>
                    </div>
                )
            }}
        </Formik>
    );
}

export default SetBookingStatusFormContainer;