import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpClientFailureResponse } from '../api';
import { getBookingById, updateBookingCars } from '../api/bookings';
import { BookingDetails, DetailedBookingCustomerCar } from '../models/Booking';
import { createHttpClientThunk } from './common/createHttpClientThunk';
import { selectors as packageInfoSelectors } from './packageInfo';

export const sliceName = 'booking';

const getByIdAsyncThunk = createHttpClientThunk(
    `${sliceName}/getById`,
    getBookingById,
);


type SliceState = {
    booking?: BookingDetails,
    deletedCarBookingsByCarId: {
        [key: number]: DetailedBookingCustomerCar,
    },

    isLoading: boolean,
    isSubmittingUpdate: boolean,
    httpError?: HttpClientFailureResponse,
    showValidationErrors: boolean,
}

const initialState: SliceState = {
    isLoading: false,
    isSubmittingUpdate: false,
    deletedCarBookingsByCarId: {},
    showValidationErrors: false,
}

type SetCarMainPackagePayload = { carId: number, packageId: number, packageName: string };
const setCarMainPackageReducer = (state: SliceState, { payload: { carId, packageId, packageName } }: PayloadAction<SetCarMainPackagePayload>) => {
    const carPackage = state.booking?.carPackages.find(cp => cp.customerCarId === carId);
    if (!carPackage) return;
    carPackage.packageGroupId = packageId;
    carPackage.packageGroupName = packageName;
    carPackage.optionalExtras = [];
}

type RemoveOptionalExtraReducerPayload = { carId: number, packageItemId: number };
const removeOptionalExtraReducer = (state: SliceState, { payload: { carId, packageItemId } }: PayloadAction<RemoveOptionalExtraReducerPayload>) => {
    const carPackage = state.booking?.carPackages.find(cp => cp.customerCarId === carId);
    if (!carPackage) return;
    
    carPackage.optionalExtras = carPackage.optionalExtras.filter(oe => oe.packageItemId !== packageItemId);
}

type AddOptionalExtraReducerPayload = { carId: number, packageItemId: number, packageItemName: string };
const addOptionalExtraReducer = (state: SliceState, { payload: { carId, packageItemId, packageItemName } }: PayloadAction<AddOptionalExtraReducerPayload>) => {
    const carPackage = state.booking?.carPackages.find(cp => cp.customerCarId === carId);
    if (!carPackage) return;
    
    carPackage.optionalExtras.push({
        packageItemId,
        packageItemName,
    })
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addCarToBooking: (state, { payload: newCarId }: PayloadAction<number>) => {
            if (!state.booking) return;
            
            const deletedBooking = state.deletedCarBookingsByCarId[newCarId];
            delete state.deletedCarBookingsByCarId[newCarId];
            
            if (deletedBooking) {
                state.booking.carPackages.push(deletedBooking);
                return;
            }

            const car = state.booking.customer.cars.find(car => car.id === newCarId);
            if (!car) return;

            state.booking.carPackages.push({
                bookingCustomerCarId: 0,
                carCategory: car.category,
                customerCarId: newCarId,
                packageGroupId: -1,
                packageGroupName: '',
                registrationNumber: car.registrationNumber,
                makeAndModel: car.makeAndModel,
                duration: '',
                optionalExtras: [],
            })
        },
        removeCarFromBooking: (state, { payload: carId }: PayloadAction<number>) => {
            if (!state.booking) return;
            const deletedBooking = state.booking.carPackages.find(carPackage => carPackage.customerCarId === carId);
            deletedBooking && (state.deletedCarBookingsByCarId[carId] = deletedBooking);
            state.booking.carPackages = state.booking.carPackages.filter(carPackage => carPackage.customerCarId !== carId);
        },
        setCarMainPackage: setCarMainPackageReducer,
        removeOptionalExtra: removeOptionalExtraReducer,
        addOptionalExtra: addOptionalExtraReducer,
        setOverridePrice: (state, { payload }: PayloadAction<number>) => {
            if (!state.booking) return;
            state.booking.overridePrice = payload;
        },
        setIsSubmittingUpdate: (state, { payload }: PayloadAction<boolean>) => {
            state.isSubmittingUpdate = payload;
        },
        setShowValidationErrors: (state, { payload }: PayloadAction<boolean>) => {
            state.showValidationErrors = payload;
        },
        setNotes: (state, { payload }: PayloadAction<string>) => {
            if (!state.booking) return;

            state.booking.notes = payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getByIdAsyncThunk.pending, state => {
            state.isLoading = true;
            state.httpError = undefined;
        });
        builder.addCase(getByIdAsyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.httpError = payload;
        });
        builder.addCase(getByIdAsyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.booking = payload;
        });
    }
});

export const { reducer } = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];

export const actions = {
    getById: getByIdAsyncThunk,
    addCarToBooking: (carId: number) => slice.actions.addCarToBooking(carId),
    removeCarFromBooking: (carId: number) => slice.actions.removeCarFromBooking(carId),
    setCarMainPackage: (carId: number, packageId: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const mainPackage = packageInfoSelectors.selectPackageById(getState(), packageId);
        dispatch(
            slice.actions.setCarMainPackage({ carId, packageId, packageName: mainPackage ? mainPackage.name : '' })
        );
    },
    removeOptionalExtra: (carId: number, packageItemId: number) => slice.actions.removeOptionalExtra({ carId, packageItemId }),
    addOptionalExtra: (carId: number, packageItemId: number) => (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const optionalExtra = packageInfoSelectors.selectOptionalExtraById(getState(), packageItemId);
        dispatch(
            slice.actions.addOptionalExtra({ carId, packageItemId, packageItemName: optionalExtra ? optionalExtra.name : '' })
        );
    },
    setOverridePrice: (newPrice: number) => slice.actions.setOverridePrice(newPrice),
    submitUpdate: (onSuccess: () => void) => async (dispatch: (action: any) => void, getState: () => RootReducerState) => {
        const { booking } = selectSliceState(getState());
        if (!booking) return;
        dispatch(slice.actions.setIsSubmittingUpdate(true));
        dispatch(slice.actions.setShowValidationErrors(true));

        const result = await updateBookingCars(booking.id, booking.carPackages, booking.overridePrice);

        dispatch(slice.actions.setIsSubmittingUpdate(false));

        if (!result.isError) {
            dispatch(getByIdAsyncThunk(booking.id));
            onSuccess();
        }
    },
    setShowValidationErrors: (newValue: boolean) => slice.actions.setShowValidationErrors(newValue),
    setBookingNotes: (newNotes: string) => slice.actions.setNotes(newNotes),
};

const selectTotalPriceForCar = (
    state: SliceState,
    carId: number,
    allPackagePrices: ReturnType<typeof packageInfoSelectors.selectPackageGroupPrices>,
    allOptionalExtraPrices: ReturnType<typeof packageInfoSelectors.selectOptionalExtraPrices>): number => {
    if (!state.booking) return 0;

    const carBooking = state.booking.carPackages
        .find(carPackage => carPackage.customerCarId === carId);
    if (!carBooking || carBooking.packageGroupId === -1) return 0;

    const packagePrices = allPackagePrices[carBooking.packageGroupId];
    let result =  packagePrices ? packagePrices[carBooking.carCategory] : 0;

    carBooking.optionalExtras.forEach(optionalExtra => {
        result +=  allOptionalExtraPrices[optionalExtra.packageItemId]
    })

    return result;
}

const makeSelectTotalPriceForCar = (carId: number) => createSelector(
    selectSliceState,
    packageInfoSelectors.selectPackageGroupPrices,
    packageInfoSelectors.selectOptionalExtraPrices,
    (state, allPackagePrices, allOptionalExtraPrices) => (
        selectTotalPriceForCar(state, carId, allPackagePrices, allOptionalExtraPrices)
    )
)

export const selectors = {
    selectApiState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isLoading,
            httpError: state.httpError,
            booking: state.booking,
        })
    ),
    selectUnbookedCars: createSelector(
        selectSliceState,
        state => {
            const booking = state.booking;
            if (!booking) return [];

            const bookedCarIds = booking.carPackages
                .map(carPackage => carPackage.customerCarId);

            const unbookedCars = booking.customer.cars
                .filter(car => !bookedCarIds.includes(car.id));

            return unbookedCars;
        }
    ),
    makeSelectTotalPriceForCar,
    selectTotalPrice: createSelector(
        selectSliceState,
        packageInfoSelectors.selectPackageGroupPrices,
        packageInfoSelectors.selectOptionalExtraPrices,
        (state, packageGroupPrices, optionalExtraPrices) => {
            if (!state.booking) return 0;

            let result = 0;
            state.booking.carPackages.forEach(carPackage => {
                result += selectTotalPriceForCar(state, carPackage.customerCarId, packageGroupPrices, optionalExtraPrices);
            })

            return result;
        }
    ),
    selectOverridePrice: createSelector(
        selectSliceState,
        state => state.booking?.overridePrice,
    ),
    selectIsSubmittingUpdate: createSelector(
        selectSliceState,
        state => state.isSubmittingUpdate,
    ),
    selectShowValidationErrors: createSelector(
        selectSliceState,
        state => state.showValidationErrors,
    ),
    selectNotes: createSelector(
        selectSliceState,
        state => state.booking ? state.booking.notes : '',
    )
}