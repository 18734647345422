import React from 'react';
import { AppModal } from '../AppModal';
import EditCarPackagesContainer from './EditCarPackagesContainer';

export type EditCarPackagesModalProps = {
    isOpen: boolean,
    onClose: () => void,
}

const EditCarPackagesModal: React.FC<EditCarPackagesModalProps> = ({
    isOpen,
    onClose,
}) => {
    return (
        <AppModal
            isOpen={isOpen}
            onClose={onClose}
            title="Edit Car Packages"
        >
            <EditCarPackagesContainer onClose={onClose} />
        </AppModal>
    )
}

export default EditCarPackagesModal;
