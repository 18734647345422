import { ApiCustomerAddress } from "../api/models/ApiCustomerAddress";

export interface CustomerAddressCore {
    addressLine1: string,
    addressLine2?: string,
    addressLine3?: string,
    postcode: string,
    town: string,
    country?: number,
}

export interface CustomerAddress extends CustomerAddressCore {
    id: number,
}

export const parseApiCustomerAddress = ({
    addressId,
    addressLine1,
    addressLine2,
    addressLine3,
    postcode,
    town,
    country,
}: ApiCustomerAddress): CustomerAddress => ({
    id: addressId,
    addressLine1,
    addressLine2,
    addressLine3,
    postcode,
    town,
    country,
});