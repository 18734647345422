import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppLink } from '../components/AppLink';
import { AppSearchBox } from '../components/AppSearchBox';

import { CustomersTable } from '../components/CustomersTable';
import { paths } from '../Routes';
import { actions, selectors } from '../store';
import { useIsMobileMediaQuery } from '../useIsMobileMediaQuery';

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(3),
        '& > h1': {
            flexGrow: 1,
            lineHeight: 1,
        },
        '& > a': {
            marginRight: theme.spacing(1),
        },
    },
    search: {
        flexGrow: 1,
        marginRight: theme.spacing(1)
    }
}));

const CustomersListPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const isMobile = useIsMobileMediaQuery();
    const dispatch = useDispatch();
    const searchTerm = useSelector(selectors.customers.selectSearchTerm);
    
    const handleSearchChange = (value: string) => {
        dispatch(actions.customers.setSearchTerm(value));
    }

    const handleSubmitSearch = () => {
        dispatch(actions.customers.doSearch());
    }

    const onHeaderClick = (headerSort: string) => {
        dispatch(actions.customers.setSortBy(headerSort));
    }

    return (
        <>
            <div className={classes.header}>
                <AppSearchBox
                    className={classes.search}
                    placeholder={isMobile ? 'Search...' : 'Search Customers by First or Last Name'}
                    contractedWidth="155px"
                    onSubmit={handleSubmitSearch}
                    onChange={handleSearchChange}
                    value={searchTerm}
                />
                <AppLink
                    styleAsButton
                    children="+ New"
                    to={paths.customerCreate}
                />
            </div>
            <CustomersTable 
            onRowClick={customerId => history.push(paths.customerView(customerId))} 
            onHeaderClick={onHeaderClick}
            />
        </>
    );
}

export default CustomersListPage;