import { Typography } from '@material-ui/core';
import React from 'react';

import { HttpClientFailureResponse } from '../../api';
import AppLoader from '../AppLoader';

interface ApiErrorLoaderWrapperProps {
    isLoading: boolean,
    httpError?: HttpClientFailureResponse
}

const ApiErrorLoaderWrapper: React.FC<ApiErrorLoaderWrapperProps> = ({
    isLoading,
    httpError,
    children,
}) => {
    if (isLoading) {
        return <AppLoader />
    }

    if (httpError) {
        return (
            <>
                <Typography align="center" color="error">error, please try again</Typography>
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

export default ApiErrorLoaderWrapper;