import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCustomerId } from "../../Routes";
import { successToast } from "../../toast";
import { AppButton } from "../AppButton";
import { AppModal } from "../AppModal";
import { AppFormButtons, AppSelect } from "../AppForm";
import { AppSelectOption } from "../AppForm/AppSelect";
import AppCounterInput from "../AppForm/AppCounterInput";
import { actions } from "../../store";
import {
  cancelCarClubPackage,
  modifyCarClubPackageCleans,
  pauseCarClubPackage,
  resumeCarClubPackage,
} from "../../api/carClubPackages";
import { boolean } from "yup";
import { ApiStandardResponse } from "../../api/models/ApiStandardResponse";
import { HttpClientResponse } from "../../api";

export interface EditCustomerCarClubPackageModalProps {
  isOpen: boolean;
  onClose: () => void;
  carClubPackageId: number;
  carClubStatus: string;
  carClubCleans: number;
}

const EditCustomerCarClubPackageModal: React.FC<EditCustomerCarClubPackageModalProps> =
  ({ isOpen, onClose, carClubPackageId, carClubStatus, carClubCleans }) => {
    const dispatch = useDispatch();
    const customerId = useCustomerId();

    const statusOptions: AppSelectOption[] = [
      {
        value: 0,
        label: "ACTIVE",
      },
      {
        value: 1,
        label: "PAUSED",
      },
    ];

    const [selectedStatusOption, setSelectedStatusOption] = useState<number>(
      statusOptions[0].value
    );
    const [cleanCount, setCleanCount] = useState<number>(0);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelling, setIsCancelling] = useState<boolean>(false);

    useEffect(() => {
      const statusValue =
        statusOptions.find(
          (option) => option.label.toLowerCase() === carClubStatus.toLowerCase()
        )?.value ?? 0;
      setSelectedStatusOption(statusValue);
      setCleanCount(carClubCleans);
    }, [isOpen, carClubPackageId, carClubStatus, carClubCleans]);

    useEffect(() => {
      updateIsModified();
    }, [selectedStatusOption, cleanCount]);

    const handleSubmitClick = async () => {
      let modifiedCleansResponse:
        | HttpClientResponse<ApiStandardResponse<void>>
        | undefined = undefined;
      let modifiedStatusResponse:
        | HttpClientResponse<ApiStandardResponse<void>>
        | undefined = undefined;
      let isSuccessful = true;
      setIsLoading(true);
      if (
        statusOptions[selectedStatusOption].label.toLowerCase() !==
        carClubStatus.toLowerCase()
      ) {
        switch (selectedStatusOption) {
          case 0:
            modifiedStatusResponse = await resumeCarClubPackage(
              carClubPackageId
            );
            break;
          case 1:
            modifiedStatusResponse = await pauseCarClubPackage(
              carClubPackageId
            );
            break;
        }
      }

      if (cleanCount !== carClubCleans) {
        modifiedCleansResponse = await modifyCarClubPackageCleans(
          carClubPackageId,
          { cleansRemaining: cleanCount }
        );
      }
      if (modifiedStatusResponse !== undefined) {
        isSuccessful = isSuccessful && !modifiedStatusResponse.isError;
      }
      if (modifiedCleansResponse !== undefined) {
        isSuccessful = isSuccessful && !modifiedCleansResponse.isError;
      }
      if (isSuccessful) {
        await dispatch(actions.carClubPackages.getByCustomerId(customerId));

        successToast("Subscription updated.");
        onClose();
      }

      setIsLoading(false);
    };

    const handleCancelClick = async () => {
        setIsCancelling(true);
      const cancelPackageResponse = await cancelCarClubPackage(carClubPackageId);
      
      
      if (!cancelPackageResponse.isError) {
        await dispatch(actions.carClubPackages.getByCustomerId(customerId));

        successToast("Subscription removed.");
        onClose();
      }

      setIsCancelling(false);
    };

    const updateIsModified = () => {
      const statusModified =
        statusOptions[selectedStatusOption].label.toLowerCase() !==
        carClubStatus.toLowerCase();
      const cleanCountModified = carClubCleans !== cleanCount;

      setIsModified(statusModified || cleanCountModified);
    };

    const handleStatusChange = (value: number) => {
      setSelectedStatusOption(value);
    };

    const cleanCounterChanged = (value: number) => {
      setCleanCount(value);
    };

    return (
      <AppModal
        isOpen={isOpen}
        onClose={onClose}
        title="Edit Car Club Subscription"
      >
        <AppSelect
          label="Car Club Status"
          options={statusOptions}
          value={selectedStatusOption}
          onChange={handleStatusChange}
        />

        <AppCounterInput
          label="Car Club Cleans"
          value={cleanCount}
          minValue={0}
          onChange={cleanCounterChanged}
          style={{ margin: "1.5rem 0 0.5rem 0" }}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AppFormButtons horizontalPosition="start">
            <AppButton
              theme="error"
              onClick={handleCancelClick}
              showSpinner={isCancelling}
              style={{ marginRight: "15rem" }}
            >
              Remove Subscription
            </AppButton>
          </AppFormButtons>
          <AppFormButtons>
            <AppButton style={{ marginRight: "10px" }} onClick={onClose}>
              Cancel
            </AppButton>
            <AppButton
              onClick={handleSubmitClick}
              disabled={!isModified}
              showSpinner={isLoading}
            >
              Save
            </AppButton>
          </AppFormButtons>
        </div>
      </AppModal>
    );
  };

export default EditCustomerCarClubPackageModal;
