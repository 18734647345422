import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import { CustomerDetailsForm } from '../components/CustomerDetailsForm';
import { useCustomerId } from '../Routes';
import { CustomerAddressesView } from '../components/CustomerAddressesView';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../store';
import { CustomerCarsView } from '../components/CustomerCarsView';
import { CustomerBookingsView } from '../components/CustomerBookingsView';
import { CustomerPreferredValetersView } from "../components/CustomerPreferredValetersView";
import { CustomerCarClubPackagesView } from '../components/CustomerCarClubPackagesView';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'start',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minMax(0, 1fr))',
        gridGap: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
    },
    customerDetails: {
        gridColumn: '1 / span 2',
        '& > .MuiCircularProgress-root': {
            display: 'block',
            margin: 'auto',
        }
    },
    address: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '1 / span 1'
        }
    },
    cars: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '2 / span 1'
        }
    },
    bookings: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '1 / span 1'
        }
    },
    preferredValeters: {
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('sm')]: {
            gridColumn: '2 / span 1'
        }
    }
}));

const CustomerViewPage: React.FC = () => {
    const classes = useStyles();
    const customerId = useCustomerId();
    const dispatch = useDispatch();

    const customerSelect = useSelector(selectors.customers.selectGetByIdState);
    const customerCarClubPackagesSelect = useSelector(selectors.carClubPackages.selectGetByCustomerIdState);

    useEffect(() => {
        dispatch(actions.customers.getById(customerId));
        dispatch(actions.carClubPackages.getByCustomerId(customerId));
    }, [dispatch, customerId])

    return (
        <div className={classes.root}>
            <CustomerDetailsForm
                initialValues={customerSelect.customer}
                className={classes.customerDetails}
                isLoading={customerSelect.isLoading}
                httpError={customerSelect.httpError}
                isCarClub={customerSelect.customer?.isCarClub ?? false}
            />
            <CustomerAddressesView
                addresses={customerSelect.customer ? customerSelect.customer.addresses : []}
                className={classes.address}
                isLoading={customerSelect.isLoading}
                httpError={customerSelect.httpError}
            />
            <CustomerCarsView
                cars={customerSelect.customer ? customerSelect.customer.cars : []}
                className={classes.cars}
                isLoading={customerSelect.isLoading}
                httpError={customerSelect.httpError}
            />
            <CustomerCarClubPackagesView
                packages={customerCarClubPackagesSelect.items ?? []}
                cars={customerSelect.customer?.cars ?? []}
                isCarClub={customerSelect.customer?.isCarClub ?? false}
                className={classes.bookings}
                httpError={customerCarClubPackagesSelect.httpError}
                isLoading={customerSelect.isLoading || customerCarClubPackagesSelect.isLoading}/>
            <CustomerBookingsView
                bookings={customerSelect.customer && customerSelect.customer.bookings ? customerSelect.customer.bookings : []}
                className={classes.bookings}
                isLoading={customerSelect.isLoading}
                httpError={customerSelect.httpError}
                customerId={customerId}
            />

            <CustomerPreferredValetersView
                valeters={customerSelect.customer ? customerSelect.customer.preferredValeters : []}
                bookings={customerSelect.customer && customerSelect.customer.bookings ? customerSelect.customer.bookings : []}
                className={classes.preferredValeters}
                isLoading={customerSelect.isLoading}
                httpError={customerSelect.httpError}
            />
        </div>
    )
}

export default CustomerViewPage;
