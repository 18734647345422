import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DetailedBookingCustomerCar } from '../../models/Booking';
import CarPackagesEditTableRow from './CarPackagesEditTableRow';

export type CarPackagesEditTableProps = {
  bookingCars: DetailedBookingCustomerCar[],
  onRemoveCar: (carId: number) => void,
  onClickRowEdit: (carId: number) => void,
  openRowCarId: number | null,
}

const CarPackagesEditTable: React.FC<CarPackagesEditTableProps> = ({
  bookingCars,
  onRemoveCar,
  onClickRowEdit,
  openRowCarId,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Car</TableCell>
            <TableCell align="right">Main Package</TableCell>
            <TableCell align="right">Optional Extras</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {bookingCars.map(bookingCar => (
            <CarPackagesEditTableRow
              key={bookingCar.customerCarId}
              bookingCar={bookingCar}
              onDelete={onRemoveCar}
              editorIsOpen={openRowCarId === bookingCar.customerCarId}
              onClickEdit={() => onClickRowEdit(bookingCar.customerCarId)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CarPackagesEditTable;