import MissingConfigError from './MissingConfigError';

const authApiBaseUrl = process.env.REACT_APP_AUTH_API_BASE_URL;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const bookingClientBaseUrl = process.env.REACT_APP_BOOKING_CLIENT_BASE_URL;

if (!authApiBaseUrl) throw new MissingConfigError('REACT_APP_AUTH_API_BASE_URL');
if (!apiBaseUrl) throw new MissingConfigError('REACT_APP_API_BASE_URL');
if (!bookingClientBaseUrl) throw new MissingConfigError('REACT_APP_BOOKING_CLIENT_BASE_URL');

const config = {
    authApiBaseUrl,
    apiBaseUrl,
    bookingClientBaseUrl,
    pageSizeOptions: [10, 25, 50, 100],
};

export default config;