import { makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { updateBookingRefundStatus, updateBookingStatus } from '../../api/bookings';
import { AppInput, AppSelect } from '../../components/AppForm';
import { AppSelectOption } from '../../components/AppForm/AppSelect';
import AppIconButton from '../../components/AppIconButtton';
import SetBookingStatusModal from '../../components/SetBookingStatusModal';
import { StatusChip } from '../../components/StatusChip';
import { BookingStatus } from '../../config/Statuses';
import { formatDateStringDateTime } from '../../dateFormatters';
import { BookingDetails } from '../../models/Booking';
import { formatDurationStringFromMinutes } from '../../time';
import { successToast } from '../../toast';

export type BookingOverviewCardContentProps = {
    forceFetchBooking: () => void,
    booking: BookingDetails,
}

const useStyles = makeStyles(theme => ({
    bookingFields: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        '& > *': {
            flexGrow: 1,
        },
        gridGap: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            '& > *': {
                flexGrow: 1,
            },
            gridGap: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            '& > *': {
                flexGrow: 1,
            },
            gridGap: theme.spacing(3),
        }
    },
    statusChip: {
        alignSelf: 'center',
        width: 'fit-content',
    },
    statusEditButton: {
        marginLeft: theme.spacing(1),
    },
    paymentStatusEditButton: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    paymentStatus: {
        display: 'flex'
    }
}));

const BookingOverviewCardContent: React.FC<BookingOverviewCardContentProps> = ({
    booking,
    forceFetchBooking
}) => {
    const [changeStatusModalIsOpen, setChangeStatusModalIsOpen] = useState(false);
    const classNames = useStyles();
    const [refundEditable, setRefundEditable] = useState(false)

    const handleCloseChangeStatusModal = () => {
        setChangeStatusModalIsOpen(false);
    }

    const allowChangingStatus = booking.status !== 'Cancelled' && booking.status !== 'Complete';

    const handleSubmitUpdateStatus = async (newStatus: BookingStatus, confirmedDate: string | null) => {
        var response = await updateBookingStatus(booking.id, newStatus, confirmedDate);

        if (!response.isError) {
            successToast("Booking status successfully updated.")
            handleCloseChangeStatusModal();
            forceFetchBooking();
        }
    };

    const handleSubmitUpdatePaymentStatus = async () => {
        const response = await updateBookingRefundStatus(booking.id, true);
        if (!response.isError) {
            successToast("Payment status successfully updated.")
            forceFetchBooking();
        }
    };

    const priceToDisplay = (booking.overridePrice || booking.totalCost).toFixed(2);

    let paymentStatus = booking.isPrepaid ? 0 : 1;

    if (booking.isRefunded) {
        paymentStatus = 2;
    }

    const paymentStatusOptions: AppSelectOption[] = [
        {
            value: 0,
            label: 'Prepaid',
            disabled: true
        },
        {
            value: 1,
            label: 'Pay on Completion',
            disabled: true
        },
        {
            value: 2,
            label: 'Refunded',
            disabled: booking.isRefunded
        },
    ]

    return (
        <div className={classNames.bookingFields}>
            <div>
                <StatusChip
                    className={classNames.statusChip}
                    status={booking.status}
                />
                {allowChangingStatus && (
                    <AppIconButton
                        className={classNames.statusEditButton}
                        aria-label="edit"
                        onClick={() => setChangeStatusModalIsOpen(true)}
                    >
                        <EditIcon />
                    </AppIconButton>
                )}
            </div>
            <SetBookingStatusModal
                isOpen={changeStatusModalIsOpen}
                onClose={handleCloseChangeStatusModal}
                currentStatus={booking.status}
                onSubmit={handleSubmitUpdateStatus}
                initialConfirmedDate={booking.requestedDate ? new Date(booking.requestedDate) : undefined}
            />
            <AppInput readonly label="Booking ID" type="text" value={booking.referenceNumber} />
            <AppInput readonly label="Requested Date" type="text" value={booking.requestedDate ? formatDateStringDateTime(booking.requestedDate) : ''} />
            <AppInput readonly label="Confirmed Date" type="text" value={booking.confirmedDate ? formatDateStringDateTime(booking.confirmedDate) : ''} />
            <AppInput readonly label="Timeslot" type="text" value={booking.timeslot} />
            <AppInput readonly label="Location" type="text" value={booking.address.town} />
            <AppInput readonly label="Duration" type="text" value={booking.status === 'Confirmed' ? formatDurationStringFromMinutes(booking.durationMinutes || 0) : '-'} />
            <AppInput readonly label="Total Price" type="text" startAdornment="£" value={`${priceToDisplay}${booking.overridePrice ? ' !' : ''}`} italic={!!booking.overridePrice} />
            <div className={classNames.paymentStatus}>
                <AppSelect disabled={booking.isRefunded || !refundEditable} label="Payment status" options={paymentStatusOptions} value={paymentStatus} onChange={handleSubmitUpdatePaymentStatus} />
                {!booking.isRefunded && (
                    <AppIconButton
                        className={classNames.paymentStatusEditButton}
                        aria-label="edit"
                        onClick={() => setRefundEditable(true)}
                    >
                        <EditIcon />
                    </AppIconButton>
                )}
            </div>
            <AppInput readonly label="Stripe Payment Intent" type="text" value={booking.isPrepaid ? booking.stripePaymentIntentId : '-'} />
        </div>
    )
}

export default BookingOverviewCardContent;
