import { CircularProgress, IconButton, IconButtonProps, makeStyles } from '@material-ui/core';

export interface AppButtonProps extends Omit<IconButtonProps, 'children'> {
    showSpinner?: boolean,
}

const useStyles = makeStyles({
    root: {
        position: 'relative',
        whiteSpace: 'nowrap',
        alignSelf: 'flex-start',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    }
});

const AppIconButton: React.FC<AppButtonProps> = ({
    disabled,
    showSpinner,
    size = 'small',
    ...restOfProps
}) => {
    const classes = useStyles();
    return (
        <span className={classes.root}>
            <IconButton
                {...restOfProps}
                disabled={disabled || showSpinner}
                size={size}
            />
            {showSpinner && (
                <CircularProgress size={24} className={classes.progress} />
            )}
        </span>
    );
}

export default AppIconButton;