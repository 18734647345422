import { ApiBookingDetails, ApiBookingSummary, ApiDetailedBookingCustomerCar, ApiStatusCount, ApiStatusCountName } from '../api/models';
import { BookingStatus, StatusGroupName } from '../config/Statuses';
import { formatDateStringDateOnly, formatDateStringDateTime } from '../dateFormatters';
import { Customer, parseApiCustomer } from './Customer';
import { CustomerAddress, parseApiCustomerAddress } from './CustomerAddress';
import { CarCategory } from './CustomerCar';

export interface BookingCustomerCar {
    bookingCustomerCarId: number,
    customerCarId: number,
    duration: string,
    makeAndModel: string,
    packageGroupId: number,
    packageGroupName: string,
    registrationNumber: string,
    optionalExtras: string[],
}

interface StatusGroup {
    groupName: StatusGroupName,
    statuses: ApiStatusCount[]
}

export interface DetailedBookingCustomerCar {
    bookingCustomerCarId: number,
    carCategory: CarCategory,
    customerCarId: number,
    duration: string,
    makeAndModel: string,
    packageGroupId: number,
    packageGroupName: string,
    registrationNumber: string,
    optionalExtras: {
        packageItemId: number,
        packageItemName: string,
    }[],
}

export interface BookingCore {
    area: string,
    bookingCustomerCarPackages: BookingCustomerCar[],
    date: string,
    name: string,
    status: BookingStatus,
    time: string,
    valeter: string,
    totalCost: number
    overridePrice?: number,
    customerCarClubPackageId?: number
}

export interface Booking extends BookingCore {
    id: number,
    durationMinutes: number,
}

export interface BookingDetails {
    id: number,
    customer: Customer,
    address: CustomerAddress,
    requestedDate: string,
    confirmedDate: string|null,
    totalCost: number,
    status: BookingStatus,
    valeterName: string,
    durationMinutes: number,
    timeslot: string,
    customerAdditionalComments: string,
    notes: string,
    carPackages: DetailedBookingCustomerCar[],
    referenceNumber: string,
    mbhAppointmentTypeIds: number[],
    overridePrice?: number,
    isPrepaid: boolean,
    isRefunded: boolean,
    stripePaymentIntentId: string,
    resourceId: number
    customerCarClubPackageId?: number
}

export interface StatusCount{
    status: ApiStatusCountName,
    count: number
}

export const parseApiBookingSummary = ({
    area,
    bookingCustomerCarPackages,
    bookingHubDurationMinutes,
    bookingId,
    requestedDate,
    confirmedDate,
    name,
    status,
    time,
    valeter,
    totalCost,
    overridePrice,
    customerCarClubPackageId
}: ApiBookingSummary): Booking => ({
    area,
    bookingCustomerCarPackages,
    id: bookingId,
    durationMinutes: parseInt(bookingHubDurationMinutes),
    date: confirmedDate ? formatDateStringDateTime(confirmedDate) : // If confirmed then we have exact date time.
        time ? formatDateStringDateOnly(requestedDate) : // Case where morning / afternoon was selected so time in requested date is meaningless.
        formatDateStringDateTime(requestedDate), // Case where MBH timeslot was selected.
    name,
    status,
    time,
    valeter,
    totalCost,
    overridePrice,
    customerCarClubPackageId
});

const parseApiDetailedBookingCar = (apiModel: ApiDetailedBookingCustomerCar): DetailedBookingCustomerCar => ({
    ...apiModel,
    optionalExtras: apiModel.detailedOptionalExtras,
    carCategory: apiModel.category,
})

export const parseApiBookingDetails = ({
    bookingId,
    customer,
    bookingAddress,
    requestedDate,
    confirmedDate,
    totalCost,
    overridePrice,
    enquiryStatus,
    resourceName,
    bookingReferenceNumber,
    bookingHubDurationMinutes,
    timeOfDay,
    additionalComments,
    notes,
    bookingCustomerCarPackages,
    mbhAppointmentTypeIds,
    isPrepaid,
    isRefunded,
    stripePaymentIntentId,
    resourceId,
    customerCarClubPackageId
}: ApiBookingDetails): BookingDetails => ({
    id: bookingId,
    customer: parseApiCustomer(customer),
    address: parseApiCustomerAddress(bookingAddress),
    requestedDate,
    confirmedDate,
    totalCost,
    overridePrice,
    status: enquiryStatus,
    valeterName: resourceName,
    referenceNumber: bookingReferenceNumber,
    durationMinutes: parseInt(bookingHubDurationMinutes),
    timeslot: timeOfDay,
    customerAdditionalComments: additionalComments,
    notes,
    carPackages: bookingCustomerCarPackages.map(parseApiDetailedBookingCar),
    mbhAppointmentTypeIds,
    isPrepaid,
    isRefunded,
    stripePaymentIntentId,
    resourceId,
    customerCarClubPackageId
});

export const parseApiStatusCount = ({
    status,
    count
}: ApiStatusCount): StatusCount=> ({
    status,
    count,
});

export const parseApiStatusCountGroups = (apiStatusCounts: ApiStatusCount[]): StatusGroup[] => {
    const result: StatusGroup[] = apiStatusCounts.map(apiStatusCount => ({
        groupName: apiStatusCount.status,
        statuses: [apiStatusCount],
    }));
    return result;
}
