import { ApiCreateCustomerDto } from '../api/models';
import { CustomerCore } from './Customer';
import { CustomerAddressCore } from './CustomerAddress';

export interface CreateCustomerDto extends CustomerCore, CustomerAddressCore {
}

export const convertToApiCreateCustomerDto = ({
    title,
    firstName,
    lastName,
    dateOfBirth,
    contactNumber,
    email,
    addressLine1,
    addressLine2,
    addressLine3,
    postcode,
    town,
}: CreateCustomerDto): ApiCreateCustomerDto => ({
    title,
    firstName,
    lastName,
    dateOfBirth,
    contactNumber,
    email,
    address: {
        addressLine1,
        addressLine2,
        addressLine3,
        postcode,
        town,
    }
})